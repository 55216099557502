import {
  Box,
  NumberInput,
  ToggleButton,
  ToggleButtonGroup,
} from "@hightouchio/ui";
import { Controller, useFormContext } from "react-hook-form";
import { isNumber } from "lodash";

import { Term } from "src/components/term";
import { isPresent } from "src/types/utils";
import { valueIsEmpty } from "src/components/resource-alert-triggers/utils";
import { ConditionSummary, Critical, Value, Warning } from "./utils";
import { MonitorConditionTypes } from "@hightouch/lib/resource-monitoring/types";

const copy = (isPercent: boolean) =>
  isPercent
    ? "The rejected row rate for a sync run is defined as [number of sync operations that are rejected during the run] divided by [number of sync operations that are attempted during the run]."
    : `A row is considered "rejected" when it cannot be synced to the destination during a sync run. Rejections can happen for many reasons, including destination API errors, invalid/corrupt data, or failed lookups.`;

export const SyncRejectedRowsField = ({ name }) => {
  const { setValue, getValues } = useFormContext();

  const rejectedRowCountPath = `${name}.rejectedRowCount`;
  const pctRejectedRowsPath = `${name}.pctRejectedRows`;

  const countValue = getValues(rejectedRowCountPath);
  const pctValue = getValues(pctRejectedRowsPath);

  const isPercent = Boolean(isPresent(pctValue) && isNumber(pctValue));
  const fieldName = isPercent ? pctRejectedRowsPath : rejectedRowCountPath;

  return (
    <Controller
      name={fieldName}
      render={({ field: { onChange }, fieldState: { error } }) => {
        return (
          <Box
            display="inline-block"
            sx={{
              "div[role=radiogroup]": {
                label: { width: "16px", height: "24px", px: 3 },
              },
            }}
          >
            <Box
              isInvalid={Boolean(error)}
              as={NumberInput}
              aria-label={`${isPercent ? "percent" : "number"} of rejected rows`}
              display="inline-block"
              size="sm"
              width="50px"
              value={isPercent ? pctValue : countValue}
              onChange={onChange}
            />{" "}
            <Box
              as={ToggleButtonGroup}
              display="inline-block"
              value={isPercent ? "percent" : "number"}
              onChange={(value: any) => {
                if (value === "percent") {
                  setValue(name, { pctRejectedRows: 1 }, { shouldDirty: true });
                } else {
                  setValue(
                    name,
                    { rejectedRowCount: 1 },
                    { shouldDirty: true },
                  );
                }
              }}
            >
              <ToggleButton label="#" value="number" />
              <ToggleButton label="%" value="percent" />
            </Box>{" "}
            {isPercent ? "or higher " : "or more "}
            <Term message={copy(isPercent)}>
              {isPercent ? "rejected row rate" : "rejected rows"}
            </Term>{" "}
            during a sync run
          </Box>
        );
      }}
    />
  );
};

const Summary = ({ value }) => {
  const countValue = value.rejectedRowCount;
  const pctValue = value.pctRejectedRows;

  const isPercent = Boolean(isPresent(pctValue) && isNumber(pctValue));

  if (countValue === 1)
    return <>alert if there are any rejected rows during a sync run.</>;

  return isPercent ? (
    <>
      alert if rejected row rate is at or above <Value>{pctValue}%</Value>{" "}
      during a sync run.
    </>
  ) : (
    <>
      alert if <Value>{countValue}</Value> or more rejected rows during a sync
      run.
    </>
  );
};

export const SyncRejectedRowsSummary: ConditionSummary<{
  pctRejectedRows: number;
  rejectedRowCount: number;
}> = ({ condition, prefix = true }) => {
  return (
    <>
      {!valueIsEmpty(
        MonitorConditionTypes.SyncRejectedRows,
        condition.error_value,
      ) && (
        <Critical prefix={prefix}>
          <Summary value={condition.error_value} />
        </Critical>
      )}
      {!valueIsEmpty(
        MonitorConditionTypes.SyncRejectedRows,
        condition.warning_value,
      ) && (
        <Warning prefix={prefix}>
          <Summary value={condition.warning_value} />
        </Warning>
      )}
    </>
  );
};
