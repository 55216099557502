import { FC, useCallback } from "react";

import { Row, Text, Column, Badge } from "@hightouchio/ui";

import { MonitoredResourceType } from "@hightouch/lib/resource-monitoring/types";

import { useNavigate, useOutletContext } from "src/router";
import {
  EventSyncRunFragment,
  useEventWarehouseSyncRunsQuery,
} from "src/graphql";
import { BasicPagination, Table, useTableConfig } from "src/ui/table";
import { commaNumber } from "src/utils/numbers";
import { formatDatetime, formatDuration } from "src/utils/time";
import { openUrl } from "src/utils/urls";
import { SyncHealthAlertsForSync } from "src/pages/syncs/sync/components/sync-health-alerts";
import { SyncRunStatus } from "src/utils/syncs";
import { useUser } from "src/contexts/user-context";
import { OutletContext } from ".";
import { ContentHeader } from "src/components/content-header";

const PAGE_SIZE = 10;

export const WarehouseSyncRuns: FC = () => {
  const { sync } = useOutletContext<OutletContext>();
  const navigate = useNavigate();
  const { workspace } = useUser();
  const appEventMonitoringEnabled = workspace?.alerting_v2_enabled;

  const onRowClick = useCallback(
    ({ id }, event) =>
      openUrl(`/events/syncs/${sync.id}/runs/${id}`, navigate, event),
    [navigate],
  );

  const { offset, limit, page, setPage } = useTableConfig({ limit: PAGE_SIZE });

  const { data, isLoading, error } = useEventWarehouseSyncRunsQuery(
    {
      input: {
        syncId: sync.id,
        limit: limit + 1, // +1 so that we can see if there's another page
        offset,
      },
    },
    {
      select: (data) => ({
        runs: data.getEventWarehouseSyncRuns.event_warehouse_sync_runs,
      }),
      refetchInterval: 5000,
      keepPreviousData: true,
    },
  );
  const runs = data?.runs;

  return (
    <Column gap={4}>
      <ContentHeader variant="table" title="Runs" />
      {appEventMonitoringEnabled && (
        <Row px={6}>
          <SyncHealthAlertsForSync
            syncId={sync.id}
            destinationId={sync.event_warehouse_destination.id}
            resourceType={MonitoredResourceType.EventSync}
            onClick={() => {
              navigate(`/events/syncs/${sync.id}/alerting`);
            }}
          />
        </Row>
      )}
      <Table
        data={runs}
        columns={[
          {
            name: "Status",
            cell: (run) => {
              return <EventSyncStatusBadge run={run} />;
            },
          },
          {
            name: "Started",
            cell: (run) => (
              <Column gap={1}>
                {run.started_at && (
                  <Text fontWeight="medium">
                    {formatDatetime(run.started_at)}
                  </Text>
                )}
                {run.started_at && run.finished_at && (
                  <Text color="text.secondary" size="sm">
                    Duration:{" "}
                    {formatDuration(
                      { start: run.started_at, end: run.finished_at },
                      {
                        units: "short",
                      },
                    )}
                  </Text>
                )}
              </Column>
            ),
          },
          {
            name: "Events",
            cell: (run) => (
              <Text fontWeight="medium">
                {commaNumber(run.num_planned_events ?? 0)}
              </Text>
            ),
          },
          {
            name: "Failures",
            cell: (run) => (
              <Text fontWeight="medium">
                {commaNumber(run.num_failed_events ?? 0)}
              </Text>
            ),
          },
        ]}
        placeholder={placeholder}
        onRowClick={onRowClick}
        loading={isLoading}
        error={Boolean(error)}
      />
      <Row justify="flex-end" width="100%" px={4}>
        <BasicPagination
          disableNextPage={data?.runs?.length !== PAGE_SIZE + 1}
          page={page}
          setPage={setPage}
        />
      </Row>
    </Column>
  );
};

const placeholder = {
  title: "No runs yet",
  body: "Runs will appear here once you start syncing data.",
  error: "Something went wrong loading your runs.",
};

export const EventSyncStatusBadge = ({
  run,
}: {
  run: EventSyncRunFragment | undefined;
}) => {
  if (!run) return <Badge>Pending</Badge>;
  if (!run.finished_at) return <Badge variant="info">Running</Badge>;
  if (run.error) return <Badge variant="error">Failed</Badge>;
  if (run.num_failed_events) return <Badge variant="warning">Warning</Badge>;
  return <Badge variant="success">Success</Badge>;
};

export const getEventSyncStatusFromFragment = (
  run: EventSyncRunFragment | undefined,
): SyncRunStatus => {
  if (!run || !run.started_at) return SyncRunStatus.PENDING;
  if (run.started_at && !run.finished_at) return SyncRunStatus.ACTIVE;
  if (run.error) return SyncRunStatus.FAILED;
  if (run.num_failed_events) return SyncRunStatus.WARNING;
  return SyncRunStatus.SUCCESS;
};
