import {
  ConditionFriendlyNames,
  EventSourceVolumeInterval,
  MonitorConditionTypes,
} from "@hightouch/lib/resource-monitoring/types";
import { FC } from "react";
import * as yup from "yup";

import {
  SlowSyncsField,
  SlowSyncsSummary,
} from "./monitor-condition-forms/slow-syncs";
import {
  SyncSequentialFailuresSummary,
  SyncSequentialFailuresField,
} from "./monitor-condition-forms/sequential-sync-failures";
import {
  UnscheduledSyncSummary,
  UnscheduledSyncsField,
} from "./monitor-condition-forms/unscheduled-syncs";
import {
  SyncRejectedRowsSummary,
  SyncRejectedRowsField,
} from "./monitor-condition-forms/sync-rejected-rows";
import {
  SyncThroughputField,
  SyncThroughputSummary,
} from "./monitor-condition-forms/sync-throughput";
import {
  ModelSizeField,
  ModelSizeSummary,
} from "./monitor-condition-forms/model-size";
import { ConditionSummary } from "./monitor-condition-forms/utils";
import {
  EventSourceVolumeField,
  EventSourceVolumeSummary,
} from "./monitor-condition-forms/event-source-volume";

type MonitorDefinition = {
  title: string;
  summary: ConditionSummary<any>;
  field: FC<{ name: string }>;
  defaultValue: () => any;
  canDisable: boolean;
  schema: yup.Schema<any>;
};

const conditionSchema = (valueSchema: yup.ObjectSchema | yup.NumberSchema) =>
  yup
    .object()
    .shape({
      enabled: yup.boolean(),
      error_value: valueSchema.nullable().optional(),
      warning_value: valueSchema.nullable().optional(),
    })
    .required();

export const MonitorDefinitions: Record<
  MonitorConditionTypes,
  MonitorDefinition
> = {
  [MonitorConditionTypes.SyncSequentialFailures]: {
    title: ConditionFriendlyNames[MonitorConditionTypes.SyncSequentialFailures],
    field: SyncSequentialFailuresField,
    summary: SyncSequentialFailuresSummary,
    defaultValue: () => 1,
    canDisable: false,
    schema: conditionSchema(yup.number().min(1).max(100)),
  },
  [MonitorConditionTypes.SyncRejectedRows]: {
    title: ConditionFriendlyNames[MonitorConditionTypes.SyncRejectedRows],
    field: SyncRejectedRowsField,
    summary: SyncRejectedRowsSummary,
    defaultValue: () => ({
      pctRejectedRows: null,
      rejectedRowCount: 1,
    }),
    canDisable: false,
    schema: conditionSchema(
      yup.object().shape({
        pctRejectedRows: yup.number().min(1).max(100).nullable().optional(),
        rejectedRowCount: yup.number().min(1).nullable().optional(),
      }),
    ),
  },
  [MonitorConditionTypes.SyncRejectedRowsV2]: {
    title: ConditionFriendlyNames[MonitorConditionTypes.SyncRejectedRowsV2],
    field: SyncRejectedRowsField,
    summary: SyncRejectedRowsSummary,
    defaultValue: () => ({
      method: "count",
      value: 1,
    }),
    canDisable: true,
    schema: conditionSchema(
      yup.object().shape({
        method: yup.string().oneOf(["percentage", "count"]),
        value: yup.number().min(1).max(100),
      }),
    ),
  },
  [MonitorConditionTypes.SyncThroughput]: {
    title: ConditionFriendlyNames[MonitorConditionTypes.SyncThroughput],
    field: SyncThroughputField,
    summary: SyncThroughputSummary,
    defaultValue: () => ({
      interval: "7 days",
      count: 0,
      sign: "lt",
    }),
    canDisable: true,
    schema: conditionSchema(
      yup.object().shape({
        count: yup.number().min(0).max(1e8),
        sign: yup.string().oneOf(["gt", "lt"]),
        interval: yup
          .string()
          .oneOf(["7 days", "2 days", "1 day", "4 hours", "1 hour"]),
      }),
    ),
  },
  [MonitorConditionTypes.ModelSize]: {
    title: ConditionFriendlyNames[MonitorConditionTypes.ModelSize],
    field: ModelSizeField,
    summary: ModelSizeSummary,
    defaultValue: () => [
      {
        sign: "lt",
        count: 0,
      },
    ],
    canDisable: true,
    schema: conditionSchema(
      yup.array(
        yup.object().shape({
          sign: yup.string().oneOf(["gt", "lt"]).required(),
          count: yup.number().min(0).max(1e8),
        }),
        // We need to use typebox yup is such a pain to fix
      ) as unknown as yup.ObjectSchema,
    ),
  },
  [MonitorConditionTypes.SyncDuration]: {
    title: ConditionFriendlyNames[MonitorConditionTypes.SyncDuration],
    field: SlowSyncsField,
    summary: SlowSyncsSummary,
    defaultValue: () => 60,
    canDisable: true,
    schema: conditionSchema(yup.number().min(10).max(1440)),
  },
  // TODO: we want to remove this monitor type, but it will also require some un-wiring in the backend,
  // and some db cleanup to remove usage of these alerts
  [MonitorConditionTypes.SyncMissedSchedules]: {
    title: ConditionFriendlyNames[MonitorConditionTypes.SyncMissedSchedules],
    field: UnscheduledSyncsField,
    summary: UnscheduledSyncSummary,
    defaultValue: () => 60,
    canDisable: true,
    schema: conditionSchema(yup.number().min(10).max(1440)),
  },
  [MonitorConditionTypes.EventSourceVolume]: {
    title: ConditionFriendlyNames[MonitorConditionTypes.EventSourceVolume],
    field: EventSourceVolumeField,
    summary: EventSourceVolumeSummary,
    defaultValue: () => ({
      sign: "lt",
      interval: "7 days",
      count: 1,
    }),
    canDisable: true,
    schema: conditionSchema(
      yup.object().shape({
        sign: yup.string().oneOf(["gt", "lt", "gtlt"]).required(),
        interval: yup.string().oneOf(EventSourceVolumeInterval).required(),
        count: yup
          .number()
          .min(0)
          .max(1e8)
          .when("sign", {
            is: "gtlt",
            then: (schema) => schema.notRequired(),
            otherwise: (schema) => schema.required(),
          }),
        min: yup
          .number()
          .min(0)
          .max(1e8)
          .when("sign", {
            is: "gtlt",
            then: (schema) => schema.required(),
            otherwise: (schema) => schema.notRequired(),
          }),
        max: yup
          .number()
          .min(0)
          .max(1e8)
          .moreThan(yup.ref("min"), "x events must be greater than min")
          .when("sign", {
            is: "gtlt",
            then: (schema) => schema.required(),
            otherwise: (schema) => schema.notRequired(),
          }),
      }),
    ),
  },
} as const;
