import { FC, ReactNode } from "react";

import { Column, Row, Text } from "@hightouchio/ui";

type DescriptionRowProps = {
  label?: string;
  children: ReactNode;
  icon?: FC;
  backgroundColor?: string;
  borderColor?: string;
};

export const DescriptionRow: FC<DescriptionRowProps> = ({
  label,
  children,
  icon: Icon,
  backgroundColor = "base.background",
  borderColor,
}) => {
  return (
    <Column gap={2} overflowX="hidden">
      {label && <Text>{label}</Text>}
      <Row
        align="center"
        height={9}
        gap={2}
        px={2}
        py={1.5}
        bg={backgroundColor}
        borderRadius="md"
        overflow="hidden"
        border="1px"
        borderColor={borderColor || "transparent"}
      >
        {Icon && (
          <Row align="center" color="text.secondary" fontSize="20px">
            <Icon />
          </Row>
        )}
        {children}
      </Row>
    </Column>
  );
};
