import { FC, useMemo } from "react";

import { Box } from "@hightouchio/ui";
import { format as formatDate } from "date-fns";
import noop from "lodash/noop";
import {
  CartesianGrid,
  Line,
  ResponsiveContainer,
  Tooltip as RechartsTooltip,
  XAxis,
  YAxis,
  Legend as RechartsLegend,
  BarChart,
  Bar,
  AreaChart,
  Area,
  ComposedChart,
} from "recharts";

import { GraphScale } from "./constants";
import { Tooltip } from "./tooltip";
import { GraphSeries } from "./types";
import { getDataPointConfidenceBounds, valueFormatter } from "./utils";
import { DEFAULT_CHART_HEIGHT } from "src/components/charts/chart";
import { Legend } from "src/components/analytics/shared/legend";
import { CategoricalChartProps } from "recharts/types/chart/generateCategoricalChart";
import { BreakdownTable } from "src/components/analytics/breakdowns/breakdown-table";
import { GraphType, GroupByColumn } from "src/pages/analytics/types";
import { HeatmapTable } from "src/pages/analytics/heatmap-table";

export type SupportedGraphType =
  | GraphType.Area
  | GraphType.Bar
  | GraphType.Column
  | GraphType.Line
  | GraphType.HeatMap;

const sharedStyles = {
  stroke: "var(--chakra-colors-text-secondary)",
  fontFamily: "Inter",
  fontWeight: 600,
  letterSpacing: "0.03em",
};

const tickStyles = {
  fontSize: "12px",
  color: "var(--chakra-colors-text-secondary)",
  fontWeight: 400,
};

// A note about all the `renderXAxis` and `renderYAxis` functions here:
// Ideally we would factor these out into proper React components, however
// Recharts has internal state sharing that breaks custom components: https://github.com/recharts/recharts/issues/2788
// In Recharts v3 this will work, but until we upgrade we need to use this function pattern.
// Also see: https://github.com/recharts/recharts/wiki/3.0-migration-guide#custom-components
const renderXAxis = (props?: { allowDuplicatedCategory: boolean }) => (
  <XAxis
    axisLine={{ stroke: "#E5E9ED" }}
    dataKey="calculatedAt"
    allowDuplicatedCategory={props?.allowDuplicatedCategory ?? false}
    minTickGap={50}
    tickFormatter={(timestamp: number | "auto") => {
      if (timestamp === "auto") return timestamp;
      return formatDate(timestamp, "LLL d");
    }}
    tick={tickStyles}
    tickLine={false}
    {...sharedStyles}
  />
);

const renderYAxis = ({ scale }: { scale: GraphScale }) => (
  <YAxis
    axisLine={false}
    tickFormatter={(value) => valueFormatter(value, scale)}
    tick={tickStyles}
    tickLine={false}
    {...sharedStyles}
  />
);

const renderLegend = ({
  graphSeries,
  onHoverLine,
}: {
  graphSeries: GraphSeries[];
  onHoverLine: (key: string | undefined) => void;
}) => (
  <RechartsLegend
    verticalAlign="bottom"
    content={() => <Legend series={graphSeries} onHover={onHoverLine} />}
  />
);

// Note: prepend an extra string to avoid any potential conflicts with other keys (however unlikely)
const metricKey = (key: string) => `__metricValue__${key}`;
const boundsKey = (key: string) => `__bounds__${key}`;

export type AnalyticsGraphProps = {
  graphSeries: GraphSeries[];
  hoveredLine?: string;
  onHoverLine?: (key: string | undefined) => void;
  height?: string;
  scale?: GraphScale;
  type?: SupportedGraphType;
  groupByColumns?: GroupByColumn[];
  showConfidenceBounds?: boolean;
};

export const AnalyticsGraph: FC<AnalyticsGraphProps> = ({
  height = DEFAULT_CHART_HEIGHT,
  graphSeries,
  hoveredLine,
  scale = GraphScale.Linear,
  onHoverLine = noop,
  type = GraphType.Line,
  groupByColumns,
  // Will only show confidence bounds if `true` and the metric has them, and the chart type supports it
  showConfidenceBounds = false,
}) => {
  // For the stacked area charts, Recharts requires that we group the series data by the X-axis value (calculatedAt)
  // This isn't required for the simple line chart case, but use the same data structure for both anyways
  // eg.
  // {
  //   calculatedAt: Date,
  //   __metricValue__series1: number,
  //   __metricValue__series2: number,
  // }
  const chartData = useMemo(() => {
    const data = graphSeries.reduce<{
      [key: string]: {
        calculatedAt: number;
        [key: string]: number | [number, number];
      };
    }>((acc, series) => {
      series.data.forEach((point) => {
        const prev = acc[point.calculatedAt] ?? {
          calculatedAt: point.calculatedAt,
        };

        prev[metricKey(series.key)] = point.metricValue;

        if (showConfidenceBounds && point.metadata) {
          const confidenceBounds = getDataPointConfidenceBounds(point.metadata);
          if (confidenceBounds) {
            prev[boundsKey(series.key)] = confidenceBounds;
          }
        }

        acc[point.calculatedAt] = prev;
      });

      return acc;
    }, {});

    return Object.values(data);
  }, [graphSeries]);

  const chartProps: CategoricalChartProps = {
    data: chartData,
    // Note: try to use padding on parent element instead of changing margin props
    // These values just help the chart look more evenly spaced
    margin: {
      top: 30,
      left: -12,
    },
    onMouseLeave: () => onHoverLine(undefined),
  };

  return (
    <Box width="100%" height={height}>
      <AnalyticsChart
        type={type}
        chartProps={chartProps}
        graphSeries={graphSeries}
        hoveredLine={hoveredLine}
        onHoverLine={onHoverLine}
        scale={scale}
        groupByColumns={groupByColumns}
        showConfidenceBounds={showConfidenceBounds}
      />
    </Box>
  );
};

type AnalyticsChartProps = {
  chartProps: CategoricalChartProps;
  graphSeries: GraphSeries[];
  hoveredLine: string | undefined;
  onHoverLine: (key: string | undefined) => void;
  scale: GraphScale;
  groupByColumns: GroupByColumn[] | undefined;
  showConfidenceBounds: boolean;
};

const AnalyticsChart = ({
  type,
  ...props
}: AnalyticsChartProps & { type: SupportedGraphType }) => {
  switch (type) {
    case GraphType.Line:
      return <AnalyticsLineChart {...props} />;
    case GraphType.Area:
      return <AnalyticsAreaChart {...props} />;
    case GraphType.Column:
      return <AnalyticsColumnChart {...props} />;
    case GraphType.Bar:
      return (
        <BreakdownTable
          enableSearch={false}
          data={props.graphSeries}
          groupByColumns={props.groupByColumns}
        />
      );
    case GraphType.HeatMap:
      return <HeatmapTable data={props.graphSeries} />;
    default:
      return null;
  }
};

// Note: until we upgrade to Recharts v3, each component needs a <ResponsiveContainer> wrapper
// See: https://github.com/recharts/recharts/issues/2788#issuecomment-2675576335
const AnalyticsLineChart = ({
  chartProps,
  graphSeries,
  hoveredLine,
  onHoverLine,
  scale,
  showConfidenceBounds,
}: AnalyticsChartProps) => (
  <ResponsiveContainer>
    <ComposedChart {...chartProps}>
      <CartesianGrid vertical={false} stroke="#E5E9ED" />
      {renderXAxis()}
      {renderYAxis({ scale })}
      <RechartsTooltip
        content={
          <Tooltip graphSeries={graphSeries} hoveredLine={hoveredLine} />
        }
        cursor={{ strokeWidth: 2, stroke: "#252D36" }}
      />
      <>
        {/* Note: render confidence bounds first so that they are below all the lines */}
        {showConfidenceBounds &&
          graphSeries.map(({ key, description, color }) => (
            <Area
              key={boundsKey(key)}
              dataKey={boundsKey(key)}
              isAnimationActive={false}
              name={description}
              dot={false}
              activeDot={false}
              stroke="none"
              fill={color}
              connectNulls
              onMouseEnter={() => onHoverLine(key)}
              opacity={hoveredLine ? (hoveredLine === key ? 0.3 : 0.1) : 0.3}
            />
          ))}
        {graphSeries.map(({ key, description, color }) => (
          <Line
            key={metricKey(key)}
            dataKey={metricKey(key)}
            isAnimationActive={false}
            name={description}
            dot={false}
            activeDot={{
              r: 6,
              onMouseEnter: () => onHoverLine(key),
            }}
            strokeWidth={2}
            stroke={color}
            onMouseEnter={() => onHoverLine(key)}
            opacity={hoveredLine ? (hoveredLine === key ? 1 : 0.2) : 1}
          />
        ))}
      </>
      {renderLegend({ graphSeries, onHoverLine })}
    </ComposedChart>
  </ResponsiveContainer>
);

const AnalyticsAreaChart = ({
  chartProps,
  graphSeries,
  hoveredLine,
  onHoverLine,
  scale,
}: AnalyticsChartProps) => (
  <ResponsiveContainer>
    <AreaChart {...chartProps}>
      <CartesianGrid vertical={false} stroke="#E5E9ED" />
      {renderXAxis()}
      {renderYAxis({ scale })}
      <RechartsTooltip
        content={
          <Tooltip graphSeries={graphSeries} hoveredLine={hoveredLine} />
        }
        cursor={{ strokeWidth: 2, stroke: "#252D36" }}
      />
      {graphSeries.map(({ key, description, color }) => (
        <Area
          key={key}
          dataKey={metricKey(key)}
          isAnimationActive={false}
          name={description}
          stackId="stack1"
          dot={false}
          activeDot={{
            r: 6,
            onMouseEnter: () => onHoverLine(key),
          }}
          stroke={color}
          fill={color}
          onMouseEnter={() => onHoverLine(key)}
          opacity={hoveredLine ? (hoveredLine === key ? 1 : 0.2) : 1}
        />
      ))}
      {renderLegend({ graphSeries, onHoverLine })}
    </AreaChart>
  </ResponsiveContainer>
);

const AnalyticsColumnChart = ({
  chartProps,
  graphSeries,
  hoveredLine,
  onHoverLine,
  scale,
}: AnalyticsChartProps) => (
  <ResponsiveContainer>
    <BarChart {...chartProps}>
      <CartesianGrid vertical={false} stroke="#E5E9ED" />
      {/* Duplicated category is required for stack bar chart tooltips to work */}
      {renderXAxis({ allowDuplicatedCategory: true })}
      {renderYAxis({ scale })}
      <RechartsTooltip
        content={
          <Tooltip graphSeries={graphSeries} hoveredLine={hoveredLine} />
        }
        cursor={{ fill: "#E5E9ED", fillOpacity: 0.6 }}
      />
      {graphSeries.map(({ key, description, color }) => (
        <Bar
          key={key}
          dataKey={metricKey(key)}
          isAnimationActive={false}
          name={description}
          stackId="stack1"
          radius={4}
          style={{ transform: "translateY(-1px)" }}
          strokeWidth={1}
          stroke="white"
          fill={color}
          onMouseEnter={() => onHoverLine(key)}
          opacity={hoveredLine ? (hoveredLine === key ? 1 : 0.2) : 1}
        />
      ))}
      {renderLegend({ graphSeries, onHoverLine })}
    </BarChart>
  </ResponsiveContainer>
);
