import * as Sentry from "@sentry/react";
import { groupBy } from "lodash";

import { GraphSeries } from "src/components/analytics/cross-audience-graph/types";
import { DecisionEngineFlowChartsQuery } from "src/graphql";
import { SyntheticColumnValuesGetter } from "src/pages/analytics/types";
import {
  AndOrCondition,
  isSyntheticColumn,
  PropertyCondition,
} from "src/types/visual";
import { BreakdownType, chartSchema, FilterColumnType } from "./types";
import { getDataPointConfidenceBounds } from "src/components/analytics/cross-audience-graph/utils";

export const MAX_CHARTS = 200;
export const CHARTS_PER_PAGE = 10;

/**
 * Validates chart data against the chart schema.
 * Returns only the charts that pass validation.
 */
export const validateCharts = (
  charts:
    | DecisionEngineFlowChartsQuery["decision_engine_flow_charts"]
    | undefined,
): DecisionEngineFlowChartsQuery["decision_engine_flow_charts"] => {
  const validCharts = charts ?? [];
  return validCharts.filter((chart) => {
    try {
      chartSchema.validateSync(chart);
      return true;
    } catch (error) {
      Sentry.captureException(
        `AID Insights chart ${chart.id} schema validation failed: ${error?.message}`,
      );
      return false;
    }
  });
};

/**
 * Assume that we are filtering by the opposite dimension of what we are breaking down by since
 * the `filterBy` column only stores the raw column name
 * (ex: breaking down by channels -> filter by a user feature like "state" or "gender")
 */
export const getFilterColumnType = (
  breakdownType: BreakdownType,
): FilterColumnType => {
  return breakdownType === BreakdownType.UserFeature
    ? "decision_engine_interaction_action_features"
    : "decision_engine_interaction_user_features";
};

// ================= Correlations breakdowns helpers below =================

export const getFilterValueLabel = ({
  filterBy,
  filterValue,
  filterColumnType,
  valuesGetter,
}: {
  filterBy: string;
  filterValue: string;
  filterColumnType: FilterColumnType;
  valuesGetter: SyntheticColumnValuesGetter;
}): string | undefined => {
  const filterColumnValues = valuesGetter({
    name: filterBy,
    type: filterColumnType,
  });

  return filterColumnValues.find((value) => value.value === filterValue)?.label;
};

/**
 * Check if the group (our metric definition) is the filtered group for our correlations
 * The metric's filter should match the chart's filter value
 */
const isSubsegmentGroup = ({
  metricFilterConditions,
  filterBy,
  filterValue,
}: {
  metricFilterConditions: AndOrCondition<PropertyCondition>[];
  filterBy: string;
  filterValue: string;
}) => {
  const metricConditions =
    metricFilterConditions?.flatMap((condition) => {
      if (condition.type === "property") {
        return [condition];
      }

      return condition.conditions ?? [];
    }) ?? [];

  const subsegmentGroupCondition = metricConditions.find((condition) => {
    if (condition.type !== "property") return false;

    const column = condition.property;
    return (
      isSyntheticColumn(column) &&
      column.name === filterBy &&
      condition.value === filterValue
    );
  });

  return Boolean(subsegmentGroupCondition);
};

const getMetricName = ({
  isSubsegment,
  filterColumnType,
  filterBy,
  filterValue,
}: {
  isSubsegment: boolean;
  filterColumnType: FilterColumnType;
  filterBy: string;
  filterValue: string;
}) => {
  if (isSubsegment) {
    return filterValue ?? "Cohort";
  }

  if (filterColumnType === "decision_engine_interaction_user_features") {
    return "All users";
  }

  return `All ${filterBy}`;
};

export const BASELINE_BAR_COLOR = "gray.300";
export const DEFAULT_BAR_COLOR = "gray.500";
export const OUTPERFORMING_BAR_COLOR = "grass.500";
export const UNDERPERFORMING_BAR_COLOR = "danger.500";

// Specify variable name since this color is used to fill in an svg
const ERROR_BAR_COLOR_MAP = {
  [OUTPERFORMING_BAR_COLOR]: "var(--chakra-colors-grass-700)",
  [UNDERPERFORMING_BAR_COLOR]: "var(--chakra-colors-danger-700)",
  [DEFAULT_BAR_COLOR]: "var(--chakra-colors-gray-700)",
};

/**
 * Determines the color of the subsegment series based on its rate relative to the baseline group
 *
 * A subsegment is *outperforming* the total population if the total population's rate is
 * *lower* than the subsegment's lower confidence bound.
 *
 * A subsegment is *underperforming* the total population if the total population's rate is
 * *higher* than the subsegment's upper confidence bound.
 */
export const getSubsegmentSeriesColor = ({
  series,
  groupSeries,
}: {
  series: GraphSeries;
  groupSeries: GraphSeries[];
}) => {
  // Series is expected to use `all` frequency so should always be one data point
  const subsegmentRate = series.data[0]?.metricValue;
  const subsegmentMetadata = series.data[0]?.metadata;
  const [subsegmentLowerBound, subsegmentUpperBound] = subsegmentMetadata
    ? (getDataPointConfidenceBounds(subsegmentMetadata) ?? [])
    : [undefined, undefined];

  // Grab the baseline group
  const baselineGroup = groupSeries.find((s) => s !== series);
  const baselineGroupRate = baselineGroup?.data[0]?.metricValue;

  if (
    subsegmentRate == undefined ||
    baselineGroupRate == undefined ||
    subsegmentLowerBound == undefined ||
    subsegmentUpperBound == undefined
  ) {
    return DEFAULT_BAR_COLOR;
  }

  // If segment group is outperforming baseline
  if (baselineGroupRate < subsegmentLowerBound) {
    return OUTPERFORMING_BAR_COLOR;
  }

  // If segment group is underperforming baseline
  if (baselineGroupRate > subsegmentUpperBound) {
    return UNDERPERFORMING_BAR_COLOR;
  }

  return DEFAULT_BAR_COLOR;
};

/**
 * Transforms our graph series further for additional correlations context
 * Each series represents a group, which we expect two per breakdown value
 * (e.g. "All users that received Message A" and "California users that received Message A")
 * We want to group these series by the breakdown value so we can compare
 * the segmented groups to the total population group
 */
export const transformGraphForCorrelationsBreakdown = ({
  series,
  filterProps,
}: {
  series: GraphSeries[];
  filterProps: {
    filterBy: string;
    filterValue: string;
    filterValueLabel: string | undefined;
    filterColumnType: FilterColumnType;
  };
}): GraphSeries[] => {
  const { filterBy, filterValue, filterValueLabel, filterColumnType } =
    filterProps;

  const groupedSeriesByBreakdown = groupBy(series, (graphSeries) => {
    // Mainly dealing with one grouping value in practice
    return (
      graphSeries.grouping?.map((group) => group.value).join(" | ") || "--"
    );
  });

  const correlationGraphSeries: GraphSeries[] = [];
  // Transform the series for each breakdown value (mainly dealing with 2 groups)
  for (const [_groupKey, groupSeries] of Object.entries(
    groupedSeriesByBreakdown,
  )) {
    for (const series of groupSeries) {
      const isSubsegment = isSubsegmentGroup({
        metricFilterConditions: series.metricFilterConditions ?? [],
        filterBy,
        filterValue,
      });

      const metricName = getMetricName({
        isSubsegment,
        filterColumnType,
        filterBy,
        // Use the label to display if available, otherwise use the raw filter value
        filterValue: filterValueLabel ?? filterValue,
      });

      const seriesColor = isSubsegment
        ? getSubsegmentSeriesColor({
            series,
            groupSeries,
          })
        : BASELINE_BAR_COLOR;

      // If the series is a subsegment, we want to color the error bar based on the
      // performance color. If not the subsegment, we don't want to show the error bar at all.
      if (isSubsegment) {
        const dataPoint = series.data[0];
        if (dataPoint && dataPoint.errorBounds) {
          dataPoint.errorBounds.color = ERROR_BAR_COLOR_MAP[seriesColor];
        }
      } else {
        const dataPoint = series.data[0];
        if (dataPoint && dataPoint.errorBounds) {
          dataPoint.errorBounds = undefined;
        }
      }

      correlationGraphSeries.push({
        ...series,
        metricName,
        color: seriesColor,
      });
    }
  }

  return correlationGraphSeries;
};
