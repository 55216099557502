import { capitalize } from "lodash";

import { Badge, Row, SectionHeading } from "@hightouchio/ui";
import { TAG_FEATURE_PREFIX } from "src/types/visual";
import { FilterColumnType } from "../types";

export const ChartTitle = ({
  breakdownByDimension,
  filterProps,
}: {
  breakdownByDimension: string;
  filterProps?: {
    filterBy: string;
    filterValue: string;
    filterColumnType: FilterColumnType;
  };
}) => {
  const { filterBy, filterValue, filterColumnType } = filterProps ?? {};
  const filteringByUserFeatures =
    filterColumnType === "decision_engine_interaction_user_features";

  return (
    <SectionHeading>
      <Row gap="2">
        Conversion rate by
        <BreakdownByBadge breakdownByDimension={breakdownByDimension} />
        {filterBy && filterValue && filterColumnType && (
          <>
            for users {filteringByUserFeatures ? "where " : "who received "}
            <Badge variant="subtle">
              {filterBy} = {filterValue}
            </Badge>
          </>
        )}
      </Row>
    </SectionHeading>
  );
};

const BreakdownByBadge = ({
  breakdownByDimension,
}: {
  breakdownByDimension: string | null;
}) => {
  if (!breakdownByDimension) return null;

  const dimension = isTagFeature(breakdownByDimension)
    ? capitalize(breakdownByDimension.replace(TAG_FEATURE_PREFIX, "")) +
      " (AI Decisioning message tag)"
    : capitalize(breakdownByDimension.replace(/_/g, " "));

  return <Badge variant="subtle">{dimension}</Badge>;
};

const isTagFeature = (dimension: string) => {
  return dimension.startsWith(TAG_FEATURE_PREFIX);
};
