import { Box, ChakraTooltip, Row, Text } from "@hightouchio/ui";
import { FC, useLayoutEffect, useRef, useState } from "react";
import { ErrorBar } from "src/components/analytics/breakdowns/error-bar";
import { GraphTooltip } from "src/components/analytics/graph-tooltip";
import { NumericFontStyles } from "src/components/audiences/constants";

type ErrorBarProps = {
  lowerBound: number;
  upperBound: number;
  value: number; // numbered value of the bar, not the string
  color?: string;
};

type AnalyticsBarProps = {
  color: string;
  metricName: string;
  value: string;
  tooltipValue: string;
  width: number;
  tooltipText?: string;
  errorBar?: ErrorBarProps;
};

const MIN_BAR_WIDTH = 1;

export const AnalyticsBar: FC<AnalyticsBarProps> = ({
  color,
  metricName,
  value,
  width,
  tooltipText,
  tooltipValue,
  errorBar,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [containerWidth, setContainerWidth] = useState(0);

  useLayoutEffect(() => {
    setContainerWidth(containerRef.current?.offsetWidth || 0);
  }, [containerRef.current?.offsetWidth]);

  return (
    <Box ref={containerRef} position="relative" width="100%">
      <Row align="center" gap={1}>
        <ChakraTooltip
          bg="text.primary"
          p={3}
          placement="left"
          label={
            <GraphTooltip
              color={color}
              title={metricName}
              subtitles={[tooltipText]}
              value={tooltipValue ? [{ value: tooltipValue }] : []}
            />
          }
        >
          <Box
            bg={color}
            borderRadius="sm"
            height="16px"
            width={`${Math.max(width, MIN_BAR_WIDTH)}%`}
            minWidth={width === 0 ? 0 : "1px"}
          />
        </ChakraTooltip>
        {!errorBar && (
          <Box as={Text} fontWeight="medium" sx={NumericFontStyles}>
            {value}
          </Box>
        )}
      </Row>

      {errorBar && (
        <AnalyticsErrorBar
          errorBar={errorBar}
          barWidth={width}
          containerWidth={containerWidth}
        />
      )}
    </Box>
  );
};

const DefaultErrorBarColor = "#000000";

/**
 * Error bar to represent the confidence interval of the main value's bar.
 * This bar is positioned relative to the main bar given the error bar's bounds.
 */
const AnalyticsErrorBar = ({
  errorBar,
  barWidth,
  containerWidth,
}: {
  errorBar: ErrorBarProps;
  barWidth: number;
  containerWidth: number;
}) => {
  // If the value is 0, there's no need to show the error bar (want to avoid dividing by 0)
  if (errorBar.value === 0) {
    return null;
  }

  // Find the position of the lower and upper bounds of the error bar
  const lowerBoundPosition = (errorBar.lowerBound / errorBar.value) * barWidth;
  const upperBoundPosition = (errorBar.upperBound / errorBar.value) * barWidth;

  // Calculate the width of the error bar in pixels from the bounds' positions
  const errorBarPixelWidth =
    containerWidth * ((upperBoundPosition - lowerBoundPosition) / 100);

  const errorBarColor = errorBar.color ?? DefaultErrorBarColor;

  return (
    <Box
      position="absolute"
      top="4px"
      left={`${lowerBoundPosition}%`}
      width={`${upperBoundPosition - lowerBoundPosition}%`}
      height="8px"
    >
      <ErrorBar width={errorBarPixelWidth} color={errorBarColor} />
    </Box>
  );
};
