import { useState } from "react";

import { Text, ToggleButton, ToggleButtonGroup } from "@hightouchio/ui";

import { Table } from "src/ui/table";
import { TextWithTooltip } from "src/components/text-with-tooltip";
import { EventDrawer } from "./components/drawer";
import { FailedEvent, useFailedEventsQuery } from "src/graphql";
import { Navigate, useNavigate, useOutletContext } from "src/router";
import { OutletContext } from "..";
import { formatDateOrDatetimeFromEpoch } from "src/utils/time";
import { ContentHeader } from "src/components/content-header";

export const ForwardingSyncFailuresRetrying = () => {
  const { sync, type } = useOutletContext<OutletContext>();
  const [selectedEvent, setSelectedEvent] = useState<FailedEvent>();
  const navigate = useNavigate();

  const { data: events, isLoading } = useFailedEventsQuery(
    {
      input: {
        syncId: sync.id,
        type: "RETRY",
      },
    },
    {
      select: (data) => data.getFailedEvents.events,
      enabled: type === "forwarding",
    },
  );

  if (type !== "forwarding") {
    return <Navigate to={`/events/syncs/${sync.id}/`} />;
  }

  return (
    <>
      <ContentHeader
        mb={4}
        variant="table"
        title="Retrying recent failures"
        description="Failed events are automatically retried for 8 hours."
        actions={
          <ToggleButtonGroup
            value="retrying"
            onChange={(value) => {
              navigate(`/events/syncs/${sync.id}/errors/${value}`);
            }}
          >
            <ToggleButton label="Retrying" value="retrying" />
            <ToggleButton label="Archived" value="archived" />
          </ToggleButtonGroup>
        }
      />
      <Table
        loading={isLoading}
        columns={[
          {
            name: "Event type",
            max: "15%",
            cell: ({ payload }) => (
              <TextWithTooltip fontWeight="medium">
                {payload.type}
              </TextWithTooltip>
            ),
          },
          {
            name: "Event name",
            max: "20%",
            cell: ({ payload }) => (
              <TextWithTooltip fontWeight="medium">
                {payload.event ?? "--"}
              </TextWithTooltip>
            ),
          },
          {
            name: "Received at",
            max: "20%",
            cell: ({ firstAttemptAt }) => (
              <Text fontWeight="medium">
                {formatDateOrDatetimeFromEpoch(firstAttemptAt)}
              </Text>
            ),
          },
          {
            name: "Attempts",
            max: "10%",
            cell: ({ totalAttempts }) => (
              <Text fontWeight="medium">{totalAttempts}</Text>
            ),
          },
          {
            name: "Error",
            cell: ({ errorMsg }) => (
              <TextWithTooltip fontWeight="medium">
                {errorMsg || ""}
              </TextWithTooltip>
            ),
          },
        ]}
        data={events}
        onRowClick={(event) => setSelectedEvent(event)}
        placeholder={{
          title: "No recent failures",
          body: "Failed events are automatically retried for 8 hours.",
        }}
      />
      <EventDrawer
        title="Recent failure"
        event={selectedEvent}
        onClose={() => {
          setSelectedEvent(undefined);
        }}
      />
    </>
  );
};
