import { Box, Spinner } from "@hightouchio/ui";
import { Card } from "src/components/card";
import { useDecisionEngineDestinationResourceQuery } from "src/graphql";

type InAppProps = {
  resourceId: string;
  destinationId: string;
};

export const InApp = ({ resourceId, destinationId }: InAppProps) => {
  const { data, isLoading } = useDecisionEngineDestinationResourceQuery(
    {
      destinationId,
      resourceId: String(resourceId),
      type: "inapp-template",
    },
    { select: (data) => data.getDecisionEngineDestinationResource },
  );

  if (isLoading) {
    return (
      <Card>
        <Spinner m="auto" />
      </Card>
    );
  }

  if (data?.__typename === "DecisionEngineDestinationInAppResource") {
    const html = data.html ?? undefined;

    return (
      <Card w="100%" h="100%" overflow="auto">
        <Box as="iframe" srcDoc={html} w="100%" h="100%" />
      </Card>
    );
  }

  return null;
};
