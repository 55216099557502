import { FC, useMemo, useState } from "react";

import {
  Box,
  BoxProps,
  ChakraAccordion,
  ChakraAccordionButton,
  ChakraAccordionItem,
  ChakraAccordionPanel,
  ChevronRightIcon,
  Column,
  EmptyState,
  Badge,
  Row,
  SectionHeading,
  SkeletonBox,
  StatsItemTitle,
  Text,
  ArrowRightIcon,
  Tooltip,
  Skeleton,
} from "@hightouchio/ui";
import { times } from "lodash";
import { subDays } from "date-fns";
import pluralize from "pluralize";
import {
  MonitoredResourceType,
  MonitorStatus,
} from "@hightouch/lib/resource-monitoring/types";

import { RouterLink } from "src/router";
import syncPlaceholder from "src/assets/placeholders/sync.svg";
import { IntegrationIcon } from "src/components/integrations/integration-icon";
import {
  RenderedMonitorConditionStatus,
  useSyncHealthTableQuery,
  useSyncsMonitorStatusesQuery,
} from "src/graphql";
import { commaNumber } from "src/utils/numbers";
import { Card } from "src/components/card";
import { SyncHealthStatus } from "src/utils/syncs";
import { getSyncHealthStatus } from "src/components/syncs/sync-alerts-badge";
import { useAlertsForSyncs } from "src/hooks/use-alerts-for-syncs";
import { LinkWithTooltip } from "src/components/link-with-tooltip";

type DestinationsByType = Record<string, Array<Destination>>;

type StatusTransitions = {
  [SyncHealthStatus.Healthy]: { count: number; syncIds: string[] };
  [SyncHealthStatus.Warning]: { count: number; syncIds: string[] };
  [SyncHealthStatus.Unhealthy]: { count: number; syncIds: string[] };
};

type Destination = {
  id: string;
  name: string;
  type: string;
  friendlyType: string;
  icon: string;
  [SyncHealthStatus.Healthy]: number;
  [SyncHealthStatus.Warning]: number;
  [SyncHealthStatus.Unhealthy]: number;
  [SyncHealthStatus.Disabled]: number;
  // We currently treat pending as healthy
  [SyncHealthStatus.Pending]: number;
  syncIds: string[];
  // Transitions within lookback window
  transitions: StatusTransitions;
};

const RECENT_ALERTS_LOOKBACK = 3; // days

const gridTemplateColumns =
  "minmax(300px, 1fr) minmax(140px, 180px) minmax(140px, 180px) minmax(140px, 180px) minmax(140px, 180px)";

export const SyncHealthTable: FC = () => {
  const syncHealthTableQuery = useSyncHealthTableQuery();

  const [since] = useState(
    subDays(new Date(), RECENT_ALERTS_LOOKBACK).toISOString(),
  );

  const allSyncIds = useMemo(() => {
    if (!syncHealthTableQuery.data) return [];
    return syncHealthTableQuery.data.destinations
      .flatMap((destination) => destination.syncs.map((sync) => sync.id))
      .map(String);
  }, [syncHealthTableQuery.data]);

  const syncMonitorStatusesQuery = useSyncsMonitorStatusesQuery(
    {
      syncIds: allSyncIds,
      resourceType: MonitoredResourceType.Sync,
      since,
    },
    {
      enabled: Boolean(allSyncIds.length),
    },
  );

  const alertsQuery = useAlertsForSyncs(
    allSyncIds,
    MonitoredResourceType.Sync,
    0,
  );

  // We don't need the health field from useAlertsForSyncs
  const alerts = useMemo(() => {
    if (!alertsQuery.data) return {};
    return Object.entries(alertsQuery.data).reduce(
      (acc, [syncId, { alerts }]) => {
        acc[syncId] = alerts;
        return acc;
      },
      {} as Record<string, Array<RenderedMonitorConditionStatus>>,
    );
  }, [alertsQuery.data]);

  const destinationsByType = useMemo(() => {
    if (!syncHealthTableQuery.data || !alerts) return {};
    // Extract the monitor transitions array from the query data
    const monitorTransitions =
      syncMonitorStatusesQuery.data?.resource_monitor_statuses || [];
    return transformData(syncHealthTableQuery.data, alerts, monitorTransitions);
  }, [syncHealthTableQuery.data, alerts, syncMonitorStatusesQuery.data]);

  const isLoading =
    syncHealthTableQuery.isLoading ||
    syncMonitorStatusesQuery.isLoading ||
    alertsQuery.isLoading;

  if (isLoading) {
    return (
      <Card>
        <Header />
        <Skeleton isLoading>
          {times(10, (idx) => (
            <SyncHealthSkeleton key={idx} />
          ))}
        </Skeleton>
      </Card>
    );
  }

  if (!syncHealthTableQuery.data) {
    return (
      <Card sx={{ "& > div": { p: 0, pt: 4, border: "none" } }}>
        <SectionHeading mb={4}>Sync status by destination</SectionHeading>
        <EmptyState
          title="No active syncs"
          message="A sync defines how and when your data will be sent to a destination. This table only shows syncs that are enabled."
          imageUrl={syncPlaceholder}
          m={4}
        />
      </Card>
    );
  }

  return (
    <Card overflow="hidden">
      <Header />
      <Column overflow="auto">
        {Object.entries(destinationsByType).map(([, destinations], index) => (
          <RootRow key={index} destinations={destinations} />
        ))}
      </Column>
    </Card>
  );
};

const Header = () => {
  return (
    <>
      <SectionHeading mb={4}>Sync status by destination</SectionHeading>
      <Box display="grid" gridTemplateColumns={gridTemplateColumns}>
        <Cell height="48px">
          <StatsItemTitle>Destination</StatsItemTitle>
        </Cell>
        <Cell justifyContent="center" height="48px">
          <StatsItemTitle>No alerts</StatsItemTitle>
        </Cell>
        <Cell justifyContent="center" height="48px">
          <StatsItemTitle>Warning</StatsItemTitle>
        </Cell>
        <Cell justifyContent="center" height="48px">
          <StatsItemTitle>Critical</StatsItemTitle>
        </Cell>
        <Cell justifyContent="center" height="48px">
          <StatsItemTitle>Inactive</StatsItemTitle>
        </Cell>
      </Box>
    </>
  );
};

const RootRow: FC<{
  destinations: Array<Destination>;
}> = ({ destinations }) => {
  const [expansionHover, setExpansionHover] = useState<boolean>(false);
  const [expanded, setExpanded] = useState<boolean>(false);

  // Single entry case - render as a simple row
  if (destinations.length === 1) {
    const destination = destinations[0];
    if (!destination) return null;
    return <DestinationRow destination={destination} />;
  }

  const destinationIds = destinations.map((destination) => destination.id);
  const firstDestination = destinations[0];

  const healthyTotal = destinations.reduce(
    (acc, destination) => acc + destination[SyncHealthStatus.Healthy],
    0,
  );
  const pendingTotal = destinations.reduce(
    (acc, destination) => acc + destination[SyncHealthStatus.Pending],
    0,
  );
  const warningTotal = destinations.reduce(
    (acc, destination) => acc + destination[SyncHealthStatus.Warning],
    0,
  );
  const unhealthyTotal = destinations.reduce(
    (acc, destination) => acc + destination[SyncHealthStatus.Unhealthy],
    0,
  );
  const disabledTotal = destinations.reduce(
    (acc, destination) => acc + destination[SyncHealthStatus.Disabled],
    0,
  );

  // Sum transitions across all destinations and collect all transition syncIds
  const combinedTransitions: StatusTransitions = {
    [SyncHealthStatus.Healthy]: {
      count: 0,
      syncIds: [],
    },
    [SyncHealthStatus.Warning]: {
      count: 0,
      syncIds: [],
    },
    [SyncHealthStatus.Unhealthy]: {
      count: 0,
      syncIds: [],
    },
  };

  // Combine all transition sync IDs across destinations
  destinations.forEach((dest) => {
    [
      SyncHealthStatus.Healthy,
      SyncHealthStatus.Warning,
      SyncHealthStatus.Unhealthy,
    ].forEach((status) => {
      dest.transitions[status].syncIds.forEach((syncId) => {
        if (!combinedTransitions[status].syncIds.includes(syncId)) {
          combinedTransitions[status].syncIds.push(syncId);
          combinedTransitions[status].count++;
        }
      });
    });
  });

  return (
    <ChakraAccordion
      allowToggle
      index={expanded ? [0] : []}
      onChange={(e) => setExpanded(e === 0)}
    >
      <ChakraAccordionItem border="none">
        <Box
          display="grid"
          gridTemplateColumns={gridTemplateColumns}
          className="group"
        >
          <Cell
            cursor="pointer"
            alignItems="center"
            transition="background-color 150ms ease"
            _groupHover={{ bg: "gray.100" }}
            gap={2}
            onClick={() => setExpanded(!expanded)}
            onMouseEnter={() => setExpansionHover(true)}
            onMouseLeave={() => setExpansionHover(false)}
          >
            {firstDestination && (
              <>
                <IntegrationIcon
                  name={firstDestination.name}
                  src={firstDestination.icon}
                />
                <Text fontWeight="medium" isTruncated>
                  {firstDestination.friendlyType}
                </Text>
                <Badge>{destinations.length}</Badge>

                <ChakraAccordionButton
                  aria-label={`${expanded ? "Collapse" : "Expand"} syncs`}
                  outline="none !important"
                  p={1}
                  borderRadius="sm"
                  width="fit-content"
                  onClick={(e) => e.stopPropagation()}
                >
                  <Box
                    as={ChevronRightIcon}
                    opacity={expanded || expansionHover ? 1 : 0}
                    fontSize="18px"
                    transform={expanded ? "rotate(90deg)" : ""}
                    transition="all 150ms ease"
                  />
                </ChakraAccordionButton>
              </>
            )}
          </Cell>

          <SyncsCountCell
            count={healthyTotal}
            transitions={combinedTransitions[SyncHealthStatus.Healthy]}
            status={SyncHealthStatus.Healthy}
            destinationIds={destinationIds}
          />
          <SyncsCountCell
            count={warningTotal}
            transitions={combinedTransitions[SyncHealthStatus.Warning]}
            status={SyncHealthStatus.Warning}
            destinationIds={destinationIds}
          />
          <SyncsCountCell
            count={unhealthyTotal}
            transitions={combinedTransitions[SyncHealthStatus.Unhealthy]}
            status={SyncHealthStatus.Unhealthy}
            destinationIds={destinationIds}
          />
          <SyncsCountCell
            count={disabledTotal + pendingTotal}
            status={SyncHealthStatus.Disabled}
            destinationIds={destinationIds}
          />
        </Box>

        <ChakraAccordionPanel p={0}>
          {destinations.map((destination) => (
            <DestinationRow
              nested
              key={destination.id}
              destination={destination}
            />
          ))}
        </ChakraAccordionPanel>
      </ChakraAccordionItem>
    </ChakraAccordion>
  );
};

const DestinationRow: FC<{
  destination: Destination;
  nested?: boolean;
}> = ({ destination, nested = false }) => {
  return (
    <Box
      position="relative"
      display="grid"
      gridTemplateColumns={gridTemplateColumns}
      className="group"
    >
      <Cell
        transition="background-color 150ms ease"
        _groupHover={{ bg: "gray.100" }}
        pl={nested ? 12 : undefined}
        gap={2}
        alignItems="center"
      >
        <IntegrationIcon
          src={destination.icon}
          name={destination.friendlyType}
        />
        <LinkWithTooltip
          href={`/syncs?destination=${destination.id}`}
          fontWeight="medium"
          color="inherit"
        >
          {nested ? destination.name : destination.friendlyType}
        </LinkWithTooltip>
      </Cell>

      <SyncsCountCell
        count={destination[SyncHealthStatus.Healthy]}
        transitions={destination.transitions[SyncHealthStatus.Healthy]}
        status={SyncHealthStatus.Healthy}
        destinationIds={[destination.id]}
      />
      <SyncsCountCell
        count={destination[SyncHealthStatus.Warning]}
        transitions={destination.transitions[SyncHealthStatus.Warning]}
        status={SyncHealthStatus.Warning}
        destinationIds={[destination.id]}
      />
      <SyncsCountCell
        count={destination[SyncHealthStatus.Unhealthy]}
        transitions={destination.transitions[SyncHealthStatus.Unhealthy]}
        status={SyncHealthStatus.Unhealthy}
        destinationIds={[destination.id]}
      />
      <SyncsCountCell
        count={
          destination[SyncHealthStatus.Disabled] +
          destination[SyncHealthStatus.Pending]
        }
        status={SyncHealthStatus.Disabled}
        destinationIds={[destination.id]}
      />
    </Box>
  );
};

const SyncsCountCell: FC<{
  count: number;
  transitions?: { count: number; syncIds: string[] };
  status: SyncHealthStatus;
  destinationIds?: string[];
}> = ({ count, transitions, status, destinationIds }) => {
  const [hovered, setHovered] = useState(false);
  const [badgeHovered, setBadgeHovered] = useState(false);
  const { bgColor, hoverBgColor, textColor } = getCellStyleConfig(
    status,
    !count,
  );

  const formattedSyncs = commaNumber(count);

  const url = destinationIds?.length
    ? getSyncsUrl(destinationIds, status)
    : undefined;

  const isLink = url && count > 0;

  return (
    <Cell
      as={isLink ? RouterLink : "div"}
      // @ts-expect-error - as is not typed correctly
      to={url}
      bg={bgColor}
      pos="relative"
      transition="all 150ms ease"
      _groupHover={{ bg: hoverBgColor }}
      alignItems="center"
      fontWeight="medium"
      color={textColor}
      cursor={isLink ? "pointer" : "default"}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      justifyContent="space-between"
      gap={2}
    >
      <Row align="center" gap={1}>
        {formattedSyncs}
        {hovered && !badgeHovered && isLink && (
          <Box as={ArrowRightIcon} fontSize="lg" />
        )}
      </Row>
      {status !== SyncHealthStatus.Healthy && (
        <Box
          onMouseEnter={() => setBadgeHovered(true)}
          onMouseLeave={() => setBadgeHovered(false)}
        >
          <StatusTransitionBadge transition={transitions} status={status} />
        </Box>
      )}
    </Cell>
  );
};

const StatusTransitionBadge: FC<{
  transition?: { count: number; syncIds: string[] };
  status: SyncHealthStatus;
}> = ({ transition, status }) => {
  if (!transition || !transition.count) return null;

  const message = `Click to view ${pluralize("sync", transition.count)} with recent ${status === SyncHealthStatus.Unhealthy ? "critical" : "warning"} alerts (last 72 hours).`;
  const variant =
    status === SyncHealthStatus.Warning
      ? "warning"
      : status === SyncHealthStatus.Unhealthy
        ? "error"
        : "success";
  return (
    <Tooltip message={message}>
      <Box
        as={RouterLink}
        to={getTransitionSyncsUrl(transition.syncIds)}
        _hover={{
          span: {
            bg:
              variant === "warning"
                ? "warning.400"
                : variant === "error"
                  ? "danger.400"
                  : "success.400",
          },
        }}
      >
        <Badge size="sm" variant={variant}>
          {transition.count} new
        </Badge>
      </Box>
    </Tooltip>
  );
};

export const Cell: FC<BoxProps> = ({ children, ...props }) => {
  return (
    <Row
      px={6}
      height="56px"
      alignItems="center"
      borderBottom="1px"
      borderColor="base.border"
      flexShrink={0}
      {...props}
    >
      {children}
    </Row>
  );
};

const SyncHealthSkeleton = () => {
  return (
    <Box display="grid" gridTemplateColumns={gridTemplateColumns}>
      <Cell>
        <Row alignItems="center" gap={2}>
          <SkeletonBox borderRadius="full" width="24px" height="24px" />
          <SkeletonBox width="200px" height="16px" />
        </Row>
      </Cell>
      <Cell justifyContent="center" alignItems="center">
        <SkeletonBox width="50px" height="16px" />
      </Cell>
      <Cell justifyContent="center" alignItems="center">
        <SkeletonBox width="50px" height="16px" />
      </Cell>
      <Cell justifyContent="center" alignItems="center">
        <SkeletonBox width="50px" height="16px" />
      </Cell>
      <Cell justifyContent="center" alignItems="center">
        <SkeletonBox width="50px" height="16px" />
      </Cell>
    </Box>
  );
};

const getStatusTransitions = (
  syncIds: string[],
  monitors: Array<{
    resource_id: string;
    new_status: string;
  }>,
): StatusTransitions => {
  const result: StatusTransitions = {
    [SyncHealthStatus.Healthy]: { count: 0, syncIds: [] },
    [SyncHealthStatus.Warning]: { count: 0, syncIds: [] },
    [SyncHealthStatus.Unhealthy]: { count: 0, syncIds: [] },
  };

  // For each syncId, find the most recent status
  syncIds.forEach((syncId) => {
    // Get all monitors for this syncId
    const syncMonitor = monitors.find(
      (monitor) => monitor.resource_id === String(syncId),
    );

    if (!syncMonitor) return;

    const mostRecentStatus = syncMonitor.new_status as MonitorStatus;

    switch (mostRecentStatus) {
      case MonitorStatus.Unhealthy:
        if (!result[SyncHealthStatus.Unhealthy].syncIds.includes(syncId)) {
          result[SyncHealthStatus.Unhealthy].count++;
          result[SyncHealthStatus.Unhealthy].syncIds.push(syncId);
        }
        break;
      case MonitorStatus.Warning:
        if (!result[SyncHealthStatus.Warning].syncIds.includes(syncId)) {
          result[SyncHealthStatus.Warning].count++;
          result[SyncHealthStatus.Warning].syncIds.push(syncId);
        }
        break;
      case MonitorStatus.Healthy:
        if (!result[SyncHealthStatus.Healthy].syncIds.includes(syncId)) {
          result[SyncHealthStatus.Healthy].count++;
          result[SyncHealthStatus.Healthy].syncIds.push(syncId);
        }
        break;
      default:
        break;
    }
  });

  return result;
};

// Group by destination type and then destination id
const transformData = (
  data: any,
  alerts: Record<string, Array<RenderedMonitorConditionStatus>>,
  monitors: Array<{
    resource_id: string;
    new_status: string;
  }> = [],
): DestinationsByType => {
  if (!data?.destinations?.length) return {};

  const destinationsByType: DestinationsByType = {};

  for (const destination of data.destinations) {
    const destinationType = destination.type;
    const destinationId = destination.id;
    const destinationName = destination.name || "Unknown";
    const destinationIcon = destination.definition?.icon || "";
    const destinationFriendlyType =
      destination.definition?.name || destinationType;

    // Skip if destination doesn't have any syncs
    if (!destination.syncs?.length) continue;

    // Initialize the destination type bucket if it doesn't exist
    if (!destinationsByType[destinationType]) {
      destinationsByType[destinationType] = [] as Destination[];
    }

    const counts = {
      [SyncHealthStatus.Healthy]: 0,
      [SyncHealthStatus.Warning]: 0,
      [SyncHealthStatus.Unhealthy]: 0,
      [SyncHealthStatus.Disabled]: 0,
      [SyncHealthStatus.Pending]: 0,
    };

    for (const sync of destination.syncs) {
      const syncAlerts = alerts[sync.id];
      if (!syncAlerts) continue;
      const status = getSyncHealthStatus(sync, syncAlerts);
      counts[status]++;
    }

    // Collect all sync IDs
    const syncIds = destination.syncs.map((sync: any) => sync.id);

    // Calculate status transitions
    const transitions = getStatusTransitions(syncIds, monitors);

    // Create the resource operations item
    destinationsByType[destinationType].push({
      id: destinationId,
      name: destinationName,
      type: destinationType,
      friendlyType: destinationFriendlyType,
      icon: destinationIcon,
      syncIds,
      ...counts,
      transitions,
    });
  }

  return destinationsByType;
};

// Get URL for syncs with specific destination and status
const getSyncsUrl = (
  destinationIds: string[],
  status: SyncHealthStatus,
): string => {
  if (status === SyncHealthStatus.Disabled) {
    return `/syncs?destination=${destinationIds.join(",")}&syncStatus=disabled`;
  }

  return `/syncs?destination=${destinationIds.join(",")}&alerts=${status}`;
};

const getTransitionSyncsUrl = (syncIds: string[]): string => {
  return `/syncs?id=${syncIds.join(",")}`;
};

const getCellStyleConfig = (status: SyncHealthStatus, noSyncs: boolean) => {
  if (noSyncs) {
    return {
      bgColor: "white",
      hoverBgColor: "gray.200",
      textColor: "text.tertiary",
    };
  }

  const colors = {
    [SyncHealthStatus.Healthy]: "grass",
    [SyncHealthStatus.Warning]: "warning",
    [SyncHealthStatus.Unhealthy]: "danger",
    [SyncHealthStatus.Disabled]: "gray",
  };

  return {
    bgColor: `${colors[status]}.100`,
    hoverBgColor: `${colors[status]}.200`,
    textColor: `text.primary`,
  };
};
