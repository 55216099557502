import { Text, Column, Row } from "@hightouchio/ui";
import { RouterLink } from "src/router";
import { useUser } from "src/contexts/user-context";
import { HightouchLogo } from "src/ui/icons";
import { Logo as MobileLogo } from "src/ui/brand";
import { useFlags } from "launchdarkly-react-client-sdk";
import { HTImage } from "src/components/image";

export const Logo = () => {
  const { user } = useUser();
  const { organizationLogoCobrandingHidden } = useFlags();

  return (
    <>
      <Column
        minH={16}
        borderBottom="1px solid"
        borderColor="transparent"
        justify="center"
        display={["none", "none", "none", "flex"]}
        width="100%"
        px={4}
      >
        <RouterLink to="/" aria-label="Home">
          {user?.organization?.logo_url ? (
            <Column align="flex-start" gap={2} py={4}>
              <HTImage
                src={user.organization.logo_url}
                alt={user.organization.name}
                maxWidth="150px"
                maxHeight="80px"
                objectFit="contain"
              />
              {!organizationLogoCobrandingHidden && (
                <Row align="center" gap={2}>
                  <Text color="white" size="sm" fontWeight="medium">
                    Powered by
                  </Text>
                  <HightouchLogo height="100%" width="72px" />
                </Row>
              )}
            </Column>
          ) : (
            <HightouchLogo width={112} height="100%" />
          )}
        </RouterLink>
      </Column>
      <Column
        height={16}
        borderBottom="1px solid"
        borderColor="transparent"
        display={["flex", "flex", "flex", "none"]}
      >
        <Row
          flex={1}
          as={RouterLink}
          aria-label="Home"
          to="/"
          justify="center"
          align="center"
        >
          <MobileLogo size="22px" theme="light" />
        </Row>
      </Column>
    </>
  );
};
