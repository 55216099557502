import { Alert } from "@hightouchio/ui";
import {
  addWeeks,
  format,
  isWithinInterval,
  parseISO,
  subWeeks,
} from "date-fns";
import { FC } from "react";
import { useUser } from "src/contexts/user-context";

export const DaylightSavingsBanner: FC = () => {
  const { region } = useUser();
  // note: currently excluded regions: "aws-ap-south-1"

  const DST_CONFIG = [
    {
      // US
      changeDates: [
        "2025-03-09",
        "2025-11-02",
        "2026-03-08",
        "2026-11-01",
        "2027-03-14",
        "2027-11-07",
        "2028-03-12",
        "2028-11-05",
        "2029-03-11",
        "2029-11-04",
        "2030-03-10",
        "2030-11-03",
      ],
      regions: ["aws-us-east-1", "gcp-us-east4", "us-east-1", "azure-eastus"],
    },
    {
      // EU
      changeDates: [
        "2025-03-30",
        "2025-10-26",
        "2026-03-29",
        "2026-10-25",
        "2027-03-28",
        "2027-10-31",
        "2028-03-26",
        "2028-10-29",
        "2029-03-25",
        "2029-10-28",
        "2030-03-31",
        "2030-10-27",
      ],
      regions: ["aws-eu-west-1", "gcp-europe-west1"],
    },
    {
      // AUS
      changeDates: [
        "2025-04-06",
        "2025-10-05",
        "2026-04-05",
        "2026-10-04",
        "2027-04-04",
        "2027-10-03",
        "2026-04-05",
        "2028-04-02",
        "2028-10-01",
        "2029-04-01",
        "2029-10-07",
        "2030-04-07",
        "2030-10-06",
      ],
      regions: [],
    },
  ];

  const DST_CHANGE_DATE = DST_CONFIG.find((config) =>
    config.regions.includes(region),
  )?.changeDates.find((date) => {
    const start = subWeeks(date, 1);
    const end = addWeeks(date, 1);
    return isWithinInterval(new Date(), { start, end });
  });

  if (!DST_CHANGE_DATE) {
    return null;
  }

  return (
    <Alert
      variant="banner"
      type="subtle"
      justify="center"
      title="Hightouch does not observe daylight savings time."
      message={`Some sync schedules may shift relative to your local time starting on ${format(parseISO(DST_CHANGE_DATE), "EEEE, MMMM	d")}.`}
    />
  );
};
