import { FC, useEffect, useMemo, useState } from "react";

import {
  Badge,
  Box,
  ChevronLeftIcon,
  ChevronRightIcon,
  Column,
  IconButton,
  Row,
  SearchInput,
  Text,
  Tooltip,
} from "@hightouchio/ui";
import orderBy from "lodash/orderBy";
import { format } from "numerable";
import { isPresent } from "ts-extras";

import searchPlaceholder from "src/assets/placeholders/search.svg";
import {
  GraphSeries,
  GroupColumn,
} from "src/components/analytics/cross-audience-graph/types";
import { getSeriesDescription } from "src/components/analytics/cross-audience-graph/utils";
import {
  getModelIdFromColumn,
  getPropertyNameFromColumn,
} from "src/components/explore/visual/utils";
import { TextWithTooltip } from "src/components/text-with-tooltip";
import { useAnalyticsContext } from "src/pages/analytics/state";
import { GroupByColumn } from "src/pages/analytics/types";
import {
  getNumberOfUniqueValues,
  isGroupByColumnRelatedToParent,
  shouldUsePercentFormat,
} from "src/pages/analytics/utils";
import { Pagination, Table, TableColumn, useTableConfig } from "src/ui/table";
import { accurateCommaNumber } from "src/utils/numbers";

import { AnalyticsBar } from "./analytics-bar";
import {
  audienceNameKey,
  BreakdownColumn,
  getColumnKeys,
  getGroupingColumnPath,
  getGroupingKey,
  getMaxBreakdownValue,
  getMaxDecimalPlaces,
  getTdStyles,
  GroupByColumnPath,
  metricNameKey,
  move,
  MoveableBreakdownColumn,
  splitNameKey,
  valueKey,
} from "./utils";

export type BreakdownTableProps = {
  data: GraphSeries[];
  showHeaders?: boolean;
  groupByColumns?: GroupByColumn[];
  isLoading?: boolean;
  enableSearch?: boolean;
  // Optionally specify the columns and their order to display (excluding value column which is always last)
  columnsOrder?: MoveableBreakdownColumn[];
  // The table's value column will not have a header unless a valueColumnName is provided
  valueColumnName?: string;
  rowsLimit?: number;
  // Show error bars on the bar if this is enabled and the DataPoint has errorBounds data
  showErrorBars?: boolean;
};

export const BreakdownTable: FC<BreakdownTableProps> = ({
  data,
  groupByColumns = [],
  isLoading = false,
  showHeaders = true,
  enableSearch = true,
  columnsOrder,
  valueColumnName,
  rowsLimit,
  showErrorBars = false,
}) => {
  const hasSplits = data.some(({ splitName }) => Boolean(splitName));
  const numberOfUniqueAudiences = getNumberOfUniqueValues(data, "audienceName");

  const { parent } = useAnalyticsContext();
  const { page, limit, offset, setPage } = useTableConfig({
    limit: rowsLimit,
  });
  const [search, setSearch] = useState("");

  const groupByColumnPaths: GroupByColumnPath[] = useMemo(
    () =>
      (data?.[0]?.grouping || groupByColumns)
        .map((_, groupingIndex) => getGroupingColumnPath(groupingIndex))
        .filter(isPresent),
    [data, groupByColumns],
  );

  const [columnKeys, setColumnKeys] = useState<MoveableBreakdownColumn[]>(
    getColumnKeys(
      groupByColumnPaths,
      hasSplits,
      numberOfUniqueAudiences,
      columnsOrder,
    ),
  );

  useEffect(() => {
    setColumnKeys(
      getColumnKeys(
        groupByColumnPaths,
        hasSplits,
        numberOfUniqueAudiences,
        columnsOrder,
      ),
    );
  }, [groupByColumnPaths, hasSplits, numberOfUniqueAudiences, columnsOrder]);

  const filteredData = useMemo(() => {
    const result = data.filter(
      ({ metricName, splitName, description, grouping, data }) => {
        const groupMetadata =
          grouping?.flatMap(({ column, value }) => [
            getPropertyNameFromColumn(column)?.toLowerCase() ?? "",
            value?.toLowerCase() ?? "--",
          ]) ?? [];
        const lowerCasedSearch = search.toLowerCase().trim();

        const formattedMetricValue =
          data?.[0]?.metricValue !== undefined
            ? accurateCommaNumber(data?.[0]?.metricValue)
            : undefined;

        return (
          metricName.toLowerCase().trim().includes(lowerCasedSearch) ||
          description.toLowerCase().trim().includes(lowerCasedSearch) ||
          splitName?.toLowerCase().includes(lowerCasedSearch) ||
          groupMetadata.filter((value) => value.includes(lowerCasedSearch))
            .length > 0 ||
          (formattedMetricValue &&
            (formattedMetricValue.includes(lowerCasedSearch) ||
              formattedMetricValue.replace(",", "").includes(lowerCasedSearch)))
        );
      },
    );

    const filterKeys = (columnKeys as string[])
      .slice(0, columnKeys.length - 1)
      .concat(["data.0.metricValue"]);
    const directionArray = Array(filterKeys.length - 1)
      .fill("asc")
      // Last column is a number (value) so it'll be sorted in descending order
      .concat("desc");

    return orderBy(result, filterKeys, directionArray);
  }, [columnKeys, data, search]);

  const pageData = filteredData.slice(offset, offset + limit);

  const maxValue = useMemo(
    () => getMaxBreakdownValue(data, showErrorBars),
    [data, showErrorBars],
  );
  const maxDecimalPlaces = useMemo(() => getMaxDecimalPlaces(data), [data]);
  const groupings = useMemo(
    () =>
      filteredData.flatMap(({ grouping }) =>
        grouping?.map(({ column, value }) => ({
          [getGroupingKey(column, parent)]: value,
        })),
      ),
    [filteredData],
  );

  const columnsByKey = useMemo(() => {
    const map: Partial<Record<BreakdownColumn | typeof valueKey, TableColumn>> =
      {
        [metricNameKey]: {
          headerSx: { pl: "0 !important" },
          header: () => (
            <Row align="center" gap={2}>
              <Text color="text.secondary">Metric </Text>
              <Box>
                <Badge>{getNumberOfUniqueValues(data, metricNameKey)}</Badge>
              </Box>
              {columnKeys.length > 1 && (
                <Box>
                  <Tooltip message="Move column to the left" openSpeed="slow">
                    <IconButton
                      aria-label="Move metric name column to the left."
                      icon={ChevronLeftIcon}
                      isDisabled={columnKeys[0] === metricNameKey}
                      size="sm"
                      onClick={() =>
                        setColumnKeys(move(columnKeys, metricNameKey, "left"))
                      }
                    />
                  </Tooltip>
                  <Tooltip message="Move column to the right" openSpeed="slow">
                    <IconButton
                      aria-label="Move metric name column to the right."
                      icon={ChevronRightIcon}
                      isDisabled={
                        columnKeys[columnKeys.length - 1] === metricNameKey
                      }
                      size="sm"
                      onClick={() =>
                        setColumnKeys(move(columnKeys, metricNameKey, "right"))
                      }
                    />
                  </Tooltip>
                </Box>
              )}
            </Row>
          ),
          min: "240px",
          max: "240px",
          cellSx: {
            display: "contents",
          },
          cell: ({ metricName }, index) => {
            const isFirstOfGroup =
              pageData?.[index - 1]?.metricName !== metricName;
            const isLastOfGroup =
              pageData?.[index + 1]?.metricName !== metricName;
            const isLastColumn =
              columnKeys.indexOf("metricName") === columnKeys.length - 1;

            return (
              <Box
                sx={getTdStyles({
                  isFirstOfGroup,
                  isLastColumn,
                  isLastOfGroup,
                })}
              >
                <TextWithTooltip
                  color="text.secondary"
                  message={metricName}
                  size="sm"
                >
                  {metricName}
                </TextWithTooltip>
              </Box>
            );
          },
        },
        [splitNameKey]: {
          headerSx: { pl: "0 !important" },
          header: () => (
            <Row align="center" gap={2}>
              <Text color="text.secondary">Split</Text>{" "}
              <Box>
                <Badge>
                  {getNumberOfUniqueValues(data, splitNameKey, [undefined])}
                </Badge>
              </Box>
              <Box>
                <Tooltip message="Move column to the left" openSpeed="slow">
                  <IconButton
                    aria-label="Move split name column to the left."
                    icon={ChevronLeftIcon}
                    isDisabled={columnKeys[0] === splitNameKey}
                    size="sm"
                    onClick={() =>
                      setColumnKeys(move(columnKeys, splitNameKey, "left"))
                    }
                  />
                </Tooltip>
                <Tooltip message="Move column to the right" openSpeed="slow">
                  <IconButton
                    aria-label="Move split name column to the right."
                    icon={ChevronRightIcon}
                    isDisabled={
                      columnKeys[columnKeys.length - 1] === splitNameKey
                    }
                    size="sm"
                    onClick={() =>
                      setColumnKeys(move(columnKeys, splitNameKey, "right"))
                    }
                  />
                </Tooltip>
              </Box>
            </Row>
          ),
          min: "168px",
          max: "168px",
          cellSx: {
            display: "contents",
          },
          cell: ({ splitName }: GraphSeries, index) => {
            const isFirstOfGroup =
              pageData?.[index - 1]?.splitName !== splitName;
            const isLastOfGroup =
              pageData?.[index + 1]?.splitName !== splitName;
            const isLastColumn =
              columnKeys.indexOf("splitName") === columnKeys.length - 1;

            return (
              <Box
                sx={getTdStyles({
                  isFirstOfGroup,
                  isLastColumn,
                  isLastOfGroup,
                })}
              >
                <TextWithTooltip
                  color="text.secondary"
                  message={splitName ?? "--"}
                  size="sm"
                >
                  {splitName ?? "--"}
                </TextWithTooltip>
              </Box>
            );
          },
        },
        [valueKey]: {
          cellSx: {
            border: "none",
          },
          header: valueColumnName
            ? () => (
                <Row align="center">
                  <Text color="text.secondary">{valueColumnName}</Text>
                </Row>
              )
            : undefined,
          min: "160px",
          cell: ({
            color = "electric.500",
            audienceName,
            splitName,
            grouping,
            metricName,
            aggregation,
            data,
            normalization,
          }: GraphSeries) => {
            const metricValue = data?.[0]?.metricValue;
            const errorBounds = data?.[0]?.errorBounds;
            const errorBar =
              showErrorBars && errorBounds
                ? {
                    ...errorBounds,
                    value: metricValue ?? 0,
                  }
                : undefined;

            if (maxValue === undefined || metricValue === undefined) {
              return <Text color="text.secondary">Not available</Text>;
            }

            const maxAllowedDecimalPlaces = metricValue < 1 ? 4 : 2;
            const value = shouldUsePercentFormat(aggregation, normalization)
              ? `${(metricValue * 100).toFixed(2)}%`
              : // XXX: temp solution to make sure the bar width are accurate by making
                // all the values have the same decimal places to avoid issues with different
                // label lengths
                format(
                  metricValue,
                  "0,0." +
                    (maxDecimalPlaces
                      ? "0".repeat(
                          Math.min(maxDecimalPlaces, maxAllowedDecimalPlaces),
                        )
                      : "X"), // X format will remove decimal point as necessary
                );

            const fillWidth = 100 * (metricValue / maxValue);
            const fullName = getSeriesDescription({
              audienceName:
                numberOfUniqueAudiences > 1 ? audienceName : undefined,
              splitName,
              groupByColumns: grouping,
            });

            return (
              <AnalyticsBar
                metricName={metricName}
                value={value}
                tooltipValue={value}
                tooltipText={fullName}
                color={color}
                width={fillWidth}
                errorBar={errorBar}
              />
            );
          },
        },
      };

    if (numberOfUniqueAudiences > 1) {
      map[audienceNameKey] = {
        headerSx: { pl: "0 !important" },
        disabled: () => true,
        header: () => (
          <Row align="center" gap={2}>
            <Text color="text.secondary">Audience</Text>
            <Box>
              <Badge>{numberOfUniqueAudiences}</Badge>
            </Box>
            <Box>
              <Tooltip message="Move column to the left" openSpeed="slow">
                <IconButton
                  aria-label="Move audience name column to the left."
                  icon={ChevronLeftIcon}
                  isDisabled={columnKeys[0] === audienceNameKey}
                  size="sm"
                  onClick={() =>
                    setColumnKeys(move(columnKeys, audienceNameKey, "left"))
                  }
                />
              </Tooltip>
              <Tooltip message="Move column to the right" openSpeed="slow">
                <IconButton
                  aria-label="Move audience name column to the right."
                  icon={ChevronRightIcon}
                  isDisabled={
                    columnKeys[columnKeys.length - 1] === audienceNameKey
                  }
                  size="sm"
                  onClick={() =>
                    setColumnKeys(move(columnKeys, audienceNameKey, "right"))
                  }
                />
              </Tooltip>
            </Box>
          </Row>
        ),
        min: "240px",
        max: "240px",
        cellSx: {
          display: "contents",
        },
        cell: ({ audienceName }, index) => {
          const isFirstOfGroup =
            pageData?.[index - 1]?.audienceName !== audienceName;
          const isLastOfGroup =
            pageData?.[index + 1]?.audienceName !== audienceName;
          const isLastColumn =
            columnKeys.indexOf("audienceName") === columnKeys.length - 1;

          return (
            <Box
              sx={getTdStyles({ isFirstOfGroup, isLastColumn, isLastOfGroup })}
            >
              <TextWithTooltip
                color="text.primary"
                message={audienceName}
                size="sm"
              >
                {audienceName}
              </TextWithTooltip>
            </Box>
          );
        },
      };
    }

    // Add grouping columns
    (data?.[0]?.grouping || groupByColumns).forEach(
      (column: GroupByColumn | GroupColumn, groupingIndex) => {
        const groupByColumn = (
          "column" in column ? column.column : column
        ) as GroupByColumn;

        const columnName = getPropertyNameFromColumn(groupByColumn);
        const modelId = getModelIdFromColumn(groupByColumn);
        const groupingPath = getGroupingColumnPath(groupingIndex);

        if (columnName) {
          map[groupingPath] = {
            headerSx: { pl: "0 !important" },
            header: () => {
              return (
                <Row align="center" gap={2}>
                  <Text color="text.secondary">
                    {("alias" in column && column.alias) || columnName}
                  </Text>
                  <Box>
                    <Badge>
                      {getNumberOfUniqueValues(
                        groupings,
                        getGroupingKey(groupByColumn, parent),
                        [undefined],
                      )}
                    </Badge>
                  </Box>
                  {columnKeys.length > 1 && (
                    <Box>
                      <Tooltip
                        message="Move column to the left"
                        openSpeed="slow"
                      >
                        <IconButton
                          aria-label="Move audience name column to the left."
                          icon={ChevronLeftIcon}
                          isDisabled={columnKeys[0] === groupingPath}
                          size="sm"
                          onClick={() =>
                            setColumnKeys(
                              move(columnKeys, groupingPath, "left"),
                            )
                          }
                        />
                      </Tooltip>
                      <Tooltip
                        message="Move column to the right"
                        openSpeed="slow"
                      >
                        <IconButton
                          aria-label="Move audience name column to the right."
                          icon={ChevronRightIcon}
                          isDisabled={
                            columnKeys[columnKeys.length - 1] === groupingPath
                          }
                          size="sm"
                          onClick={() =>
                            setColumnKeys(
                              move(columnKeys, groupingPath, "right"),
                            )
                          }
                        />
                      </Tooltip>
                    </Box>
                  )}
                </Row>
              );
            },
            min: "240px",
            cellSx: {
              display: "contents",
            },
            cell: ({ grouping }: GraphSeries, index) => {
              const value = grouping?.find(({ column: groupingColumn }) => {
                const groupingColumnName =
                  getPropertyNameFromColumn(groupingColumn);
                const groupingColumnModelId =
                  getModelIdFromColumn(groupingColumn);

                // We want to group event columns of the same name but we don't
                // want to group it with the parent model columns so make sure
                // to check the groupByColumn modelId when the groupBy is related
                // the parent model
                if (isGroupByColumnRelatedToParent(parent, groupByColumn)) {
                  return (
                    groupingColumnName === columnName &&
                    groupingColumnModelId === modelId
                  );
                }

                return (
                  groupingColumnName === columnName &&
                  groupingColumnModelId !== parent?.id?.toString()
                );
              });

              if (!value) {
                return (
                  <Text color="text.secondary" size="sm">
                    --
                  </Text>
                );
              }

              const previousRowValue = pageData?.[index - 1]?.grouping?.find(
                ({ column: groupingColumn }) =>
                  getPropertyNameFromColumn(groupingColumn) === columnName,
              );
              const nextRowValue = pageData?.[index + 1]?.grouping?.find(
                ({ column: groupingColumn }) =>
                  getPropertyNameFromColumn(groupingColumn) === columnName,
              );
              const isFirstOfGroup = previousRowValue?.value !== value.value;
              const isLastOfGroup = nextRowValue?.value !== value.value;
              const isLastColumn =
                columnKeys.indexOf(groupingPath) === columnKeys.length - 1;

              return (
                <Box
                  sx={getTdStyles({
                    isFirstOfGroup,
                    isLastColumn,
                    isLastOfGroup,
                  })}
                >
                  <TextWithTooltip
                    color="text.primary"
                    size="sm"
                    message={value.value ?? "--"}
                  >
                    {value.value ?? "--"}
                  </TextWithTooltip>
                </Box>
              );
            },
          };
        }
      },
    );

    return map;
  }, [data, groupByColumns, groupings, page]);

  const tableColumns = useMemo(() => {
    // Always append the value column at the end
    return [
      ...columnKeys.map(
        (key) => columnsByKey[key as keyof typeof columnsByKey],
      ),
      columnsByKey[valueKey],
    ].filter(isPresent);
  }, [columnKeys, columnsByKey]);

  return (
    <Column flex={1} minHeight={0} gap={4}>
      {enableSearch && data.length > 0 && (
        <SearchInput
          placeholder="Search series..."
          value={search}
          onChange={(event) => setSearch(event.target.value)}
        />
      )}

      <Column height="100%" overflow="auto" flex={1} gap={2}>
        <Table
          showHeaders={showHeaders}
          defaultMin="min-content"
          columns={tableColumns}
          data={pageData}
          loading={isLoading}
          rowHeight="40px"
          placeholder={{
            image: searchPlaceholder,
            title: "No breakdowns found",
            error:
              "Breakdowns failed to load. Please check your configuration and try again.",
          }}
        />

        <Box py={4}>
          <Pagination
            page={page}
            setPage={setPage}
            rowsPerPage={limit}
            count={filteredData.length}
          />
        </Box>
      </Column>
    </Column>
  );
};
