import { Box, Column, Row, Skeleton, Text } from "@hightouchio/ui";
import { useFlags } from "launchdarkly-react-client-sdk";
import noop from "lodash/noop";
import partition from "lodash/partition";

import { IntegrationIcon } from "src/components/integrations/integration-icon";
import { TextWithTooltip } from "src/components/text-with-tooltip";
import {
  JourneySyncsQuery,
  SyncTileDestinationsQuery,
  useJourneySyncsQuery,
  useSyncTileDestinationsQuery,
} from "src/graphql";
import { JourneyTileCombobox } from "src/pages/journeys/components/journey-tile-combobox";
import { VISIBLE_SYNC_COUNT } from "src/pages/journeys/constants";
import { useGraphContext } from "src/pages/journeys/graph/use-graph-context";
import {
  isConfiguredSyncConfig,
  isPlaceholderSyncConfig,
  JourneyNodeDetails,
} from "src/pages/journeys/types";
import { useValidatedSyncs } from "src/pages/journeys/utils/use-validated-syncs";
import { SyncConfig } from "src/types/journeys";
import { getSyncStatusColor, SyncRunStatus } from "src/utils/syncs";

import { DescriptionRow } from "./description-row";
import { EmptyStateDescription } from "./empty-state-description";

const STATIC_ARRAY = [];

type SyncNodeDescriptionProps = JourneyNodeDetails<SyncConfig>;
type ValidatedSync = JourneySyncsQuery["syncs"][number];

export const SyncNodeDescription = ({
  sync_configs,
}: SyncNodeDescriptionProps) => {
  const { enableDestinationPlaceholders } = useFlags();
  const { isEditMode } = useGraphContext();

  // Split syncs into configured and placeholder
  const [configuredSyncs, placeholderDestinationSyncs] = partition(
    sync_configs,
    isConfiguredSyncConfig,
  );

  const selectedPlaceholderDestinationIds = new Set(
    placeholderDestinationSyncs.map(
      ({ placeholder_destination_id }) => placeholder_destination_id,
    ),
  );

  // Fetch destinations and sync data
  const destinationsQuery = useSyncTileDestinationsQuery(undefined, {
    select: (data) => data.destinations,
  });

  const destinationOptions: SyncTileDestinationsQuery["destinations"] =
    destinationsQuery.data ?? [];

  const journeySyncsQuery = useJourneySyncsQuery(
    {
      ids: configuredSyncs.map(({ destination_instance_id }) =>
        destination_instance_id.toString(),
      ),
    },
    {
      enabled: Boolean(configuredSyncs.length > 0),
      select: (data) =>
        data.syncs.filter(
          (sync) => sync.status !== null && sync.destination !== null,
        ),
    },
  );

  const { syncs: validatedSyncs, loading: validationLoading } =
    useValidatedSyncs<ValidatedSync>(journeySyncsQuery.data ?? []);

  // Loading and empty states
  const syncInformationLoading =
    journeySyncsQuery.isLoading || validationLoading;
  const noSyncConfigs =
    !syncInformationLoading && (!sync_configs || sync_configs.length === 0);

  const emptyInput = (
    <Box onClick={(event) => event.stopPropagation()}>
      <JourneyTileCombobox
        isDisabled={!isEditMode}
        renderInputInControl={false}
        placeholder="Choose a destination"
        options={destinationOptions.filter(
          // Only show destinations that are not already selected as placeholder destinations
          (option) => !selectedPlaceholderDestinationIds.has(option.id),
        )}
        optionValue={(option) => option.id}
        optionLabel={(option) => option.name ?? "Private destination"}
        value={undefined}
        optionAccessory={(option) => ({
          type: "image",
          url: option.definition.icon,
        })}
        onChange={noop}
      />
    </Box>
  );

  if (noSyncConfigs) {
    return enableDestinationPlaceholders ? (
      emptyInput
    ) : (
      <EmptyStateDescription />
    );
  }

  if (configuredSyncs.length > 0 && syncInformationLoading) {
    return (
      <SyncRowLoadingSkeleton
        count={Math.min(sync_configs?.length ?? 0, VISIBLE_SYNC_COUNT)}
      />
    );
  }

  const configuredSyncData = validatedSyncs ?? STATIC_ARRAY;
  const syncInformation = [
    ...placeholderDestinationSyncs,
    ...configuredSyncData,
  ];
  const visibleSyncs = syncInformation.slice(
    0,
    isEditMode ? VISIBLE_SYNC_COUNT - 1 : VISIBLE_SYNC_COUNT,
  );
  const invisibleSyncNumber = syncInformation.length - visibleSyncs.length;

  return (
    <Column gap={2}>
      {visibleSyncs.map((config, index) => {
        if (isPlaceholderSyncConfig(config)) {
          if (!enableDestinationPlaceholders) return null;

          return (
            <Box
              key={config.placeholder_destination_id}
              onClick={(event) => event.stopPropagation()}
            >
              <JourneyTileCombobox
                isClearable={isEditMode}
                isDisabled={!isEditMode}
                renderInputInControl={false}
                placeholder="Choose a destination"
                options={destinationOptions.filter(
                  // Only show destinations that are not already selected as placeholder destinations
                  (option) =>
                    !selectedPlaceholderDestinationIds.has(option.id) ||
                    option.id === config.placeholder_destination_id,
                )}
                value={config.placeholder_destination_id}
                optionValue={(option) => option.id}
                optionLabel={(option) => option.name ?? "Private destination"}
                optionAccessory={(option) => ({
                  type: "image",
                  url: option.definition.icon,
                })}
                onChange={noop}
              />
            </Box>
          );
        }

        const { status, destination, isSyncMisconfigured } = config;
        if (status === null || destination === null) return null;

        return (
          <ConfiguredSyncRow
            key={`${destination?.id}-${index}`}
            destination={destination}
            status={status as SyncRunStatus}
            isSyncMisconfigured={isSyncMisconfigured}
          />
        );
      })}

      {enableDestinationPlaceholders && visibleSyncs.length === 0 && emptyInput}

      {invisibleSyncNumber > 0 && <Text>+ {invisibleSyncNumber} more</Text>}
    </Column>
  );
};

const ConfiguredSyncRow = ({
  destination,
  status,
  isSyncMisconfigured,
}: {
  destination: SyncTileDestinationsQuery["destinations"][0];
  status: SyncRunStatus;
  isSyncMisconfigured: boolean;
}) => {
  const statusColor = getSyncStatusColor(status);

  return (
    <DescriptionRow
      borderColor={isSyncMisconfigured ? "danger.600" : undefined}
    >
      <Row
        align="center"
        justify="center"
        w="24px"
        h="24px"
        borderRadius="50%"
        flexShrink={0}
        bg="white"
        border="1px"
        borderColor={statusColor}
      >
        <IntegrationIcon
          name={destination.definition.name}
          src={destination.definition.icon}
          size={4}
        />
      </Row>
      <TextWithTooltip message={destination?.definition.name}>
        {destination?.name ??
          destination?.definition?.name ??
          "Private destination"}
      </TextWithTooltip>
    </DescriptionRow>
  );
};

const SyncRowLoadingSkeleton = ({ count }: { count: number }) => (
  <Skeleton isLoading>
    <Column gap={2}>
      {Array(count)
        .fill(0)
        .map((_, index) => (
          <Row
            key={index}
            as="span"
            height="30px"
            align="center"
            px={2}
            py={1.5}
            bg="base.background"
            borderRadius="md"
            overflowX="hidden"
          />
        ))}
    </Column>
  </Skeleton>
);
