import {
  useCreateResourceNotificationChannelMutation,
  useCreateWorkspaceNotificationChannelMutation,
  useDeleteResourceNotificationChannelsByResourceMutation,
} from "src/graphql";
import { useRecipients } from "src/pages/alerting/utils";
import { MonitoredResourceType } from "@hightouch/lib/resource-monitoring/types";
import { ChannelData } from "./create-recipient-modal";
import { Recipients } from "./recipients";
import { Channel } from "src/components/notification-channels/channel-definitions";

export const ResourceRecipients = ({
  resourceId,
  resourceType,
  parentResourceId,
}: {
  resourceId: string;
  resourceType: MonitoredResourceType;
  parentResourceId?: string;
}) => {
  const { getRecipientsForParentResourceIds, getRecipientsForResourceIds } =
    useRecipients();

  const { mutateAsync: createResourceNotificationChannel } =
    useCreateResourceNotificationChannelMutation();
  const { mutateAsync: deleteResourceNotificationChannel } =
    useDeleteResourceNotificationChannelsByResourceMutation();

  const { mutateAsync: createWorkspaceNotificationChannel } =
    useCreateWorkspaceNotificationChannelMutation();

  const parentRecipients = parentResourceId
    ? getRecipientsForParentResourceIds([parentResourceId])
    : [];

  const onCreateRecipient = async ({ config, channelType }: ChannelData) => {
    await createWorkspaceNotificationChannel({
      object: {
        channel_type: channelType,
        config,
        resource_notification_channels: {
          data: [
            {
              resource_id: resourceId,
              resource_type: resourceType,
            },
          ],
        },
      },
    });
  };

  const onAdd = async (channel: Channel) => {
    await createResourceNotificationChannel({
      resourceId,
      resourceType,
      channelId: channel.id,
    });
  };

  const onRemove = async (channel: Channel) => {
    await deleteResourceNotificationChannel({
      channelId: channel.id,
      resourceIds: [resourceId],
    });
  };

  const resourceRecipients = getRecipientsForResourceIds([resourceId]);

  const channels = [
    ...parentRecipients.map((r) => ({
      ...r.channel,
      disableRemove: true,
    })),
    ...resourceRecipients.map((r) => r.channel),
  ];

  return (
    <Recipients
      channels={channels}
      onAdd={onAdd}
      onRemove={onRemove}
      onCreate={onCreateRecipient}
    />
  );
};
