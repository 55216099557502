import { DecisionEngineChannelType } from "@hightouch/lib/customer-data/decision-engine/types";
import { Row, Box } from "@hightouchio/ui";
import { FC } from "react";

import { ChannelOptionsMap } from "src/pages/decision-engines/utils";

const ChannelColor: Record<DecisionEngineChannelType, string> = {
  email: "teal",
  sms: "forest",
  push: "grass",
  inapp: "electric",
  raw: "gray",
};

export const ChannelIcon: FC<
  Readonly<{
    type: DecisionEngineChannelType;
    variant?: "subtle";
    size?: number;
  }>
> = ({ type, variant, size = 3 }) => {
  const Icon = ChannelOptionsMap[type].icon;

  if (variant === "subtle") {
    return <Box as={Icon} boxSize={size} color="text.secondary" />;
  }

  return (
    <Row
      align="center"
      justify="center"
      borderRadius="md"
      bg={`${ChannelColor[type]}.200`}
      w={size + 2}
      h={size + 2}
    >
      <Box as={Icon} boxSize={size} color={`${ChannelColor[type]}.600`} />
    </Row>
  );
};
