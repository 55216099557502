import { useMemo, useState } from "react";

import { Column } from "@hightouchio/ui";
import { useQueryClient } from "react-query";
import { noop } from "lodash";

import { cleanConfig } from "src/components/destinations/utils";
import { Form, useHightouchForm } from "src/components/form";
import { FormBar } from "src/components/form/form-bar";
import { DestinationFormProvider } from "src/contexts/destination-form-context";
import { eventSourceDefinitions } from "src/events/types";
import {
  FormkitDestination,
  FormkitProvider,
} from "src/formkit/components/formkit-context";
import { ProcessFormNode } from "src/formkit/formkit";
import {
  DestinationDefinitionFragment,
  useFormkitSharedConfigValidationQuery,
} from "src/graphql";

export default function ConfigurationForm<Config>({
  formkitDefinition,
  destination,
  config,
  submit,
  submitTrigger,
  source,
  validation,
}: {
  formkitDefinition: any;
  destination: {
    type: string;
    name: string | null;
    id: any;
    definition: { name: string; icon: string };
  };
  source: {
    type: string;
    name: string;
  };
  config: Config;
  submit: (config: Config) => Promise<any>;
  // Allow parent to handle form submission
  submitTrigger?: React.MutableRefObject<
    (() => Promise<Config | undefined>) | undefined
  >;
  validation?: typeof useFormkitSharedConfigValidationQuery;
}) {
  const [errors, setErrors] = useState<string[]>();

  const validate = useValidate(
    destination?.type,
    validation ?? useFormkitSharedConfigValidationQuery,
  );

  const onSubmit = async (data) => {
    methods.clearErrors();
    const cleanedConfig = cleanConfig(data);
    const errors = await validate(cleanedConfig);
    if (typeof errors === "object" && Object.keys(errors).length) {
      Object.entries(errors).forEach(([key, message]) => {
        methods.setError(key, { message: String(message) });
      });

      setErrors(errors);
      throw new Error("Couldn't save the sync configuration");
    } else {
      setErrors(undefined);

      return await submit(data);
    }
  };

  const methods = useHightouchForm({ onSubmit, values: config });

  if (submitTrigger) {
    submitTrigger.current = () => onSubmit(methods.getValues());
  }

  const formkit = useMemo(() => {
    if (!formkitDefinition) {
      return null;
    }
    return <ProcessFormNode node={formkitDefinition} />;
  }, [formkitDefinition]);

  const sourceDefinition = useMemo(() => {
    return {
      ...eventSourceDefinitions[source.type],
      ...source,
    };
  }, [source]);

  return (
    <>
      <FormkitProvider
        validate={validate}
        destination={destination as FormkitDestination}
        destinationDefinition={
          destination.definition as DestinationDefinitionFragment
        }
        sourceDefinition={sourceDefinition}
        isEventForwardingForm={true}
      >
        <DestinationFormProvider
          errors={errors}
          setErrors={setErrors}
          config={{}}
          setConfig={noop}
          setCustomValidation={noop}
        >
          <Form form={methods}>
            <Column>
              <Column gap={8}>{formkit}</Column>
              {!submitTrigger && (
                <FormBar
                  permission={{
                    v1: { resource: "workspace", grant: "update" },
                    v2: {
                      resource: "workspace",
                      grant: "can_update",
                    },
                  }}
                />
              )}
            </Column>
          </Form>
        </DestinationFormProvider>
      </FormkitProvider>
    </>
  );
}

function useValidate(
  type: string | undefined,
  validationQuery: typeof useFormkitSharedConfigValidationQuery,
) {
  const client = useQueryClient();
  const formkitValidate = async (config) => {
    const response = await client.fetchQuery({
      queryFn: validationQuery.fetcher({
        type: type ?? "",
        config,
      }),
      queryKey: validationQuery.getKey(config),
    });

    return response.validation;
  };

  return async (config) => {
    const cleanedConfig = cleanConfig(config);
    const errors = await formkitValidate(cleanedConfig);
    if (typeof errors === "object" && Object.keys(errors).length) {
      return errors;
    }
  };
}
