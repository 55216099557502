import { MonitorStatus } from "@hightouch/lib/resource-monitoring/types";
import { isThisYear, isToday, isYesterday } from "date-fns";
import { isEnum } from "src/types/utils";
import {
  SyncRunStatus,
  SyncRunStatusOrUnknownStatus,
  UnknownSyncRunStatus,
} from "src/utils/syncs";
import * as time from "src/utils/time";

export const isMonitorStatus = isEnum(MonitorStatus);

const getCreatedAtPrefix = (createdAt: string): string | null => {
  const date = new Date(createdAt);
  if (isToday(date)) {
    return "Today";
  } else if (isYesterday(date)) {
    return "Yesterday";
  } else {
    const format = isThisYear(date) ? "MMM d" : "MMM d, yyyy";
    return time.formatDatetime(createdAt, format);
  }
};

export const formatRunCreatedAt = (createdAt: string): string => {
  const prefix = getCreatedAtPrefix(createdAt);
  const suffix = time.formatDatetime(createdAt, "p");

  // Date isn't parsable, just return the original string
  if (!prefix || !suffix) return createdAt;

  return `${prefix} · ${suffix}`;
};

export const syncRunStatusDurationText = (
  status: SyncRunStatusOrUnknownStatus,
  createdAt: string,
  finishedAt: string | null,
): string | null => {
  const duration = time.formatDuration(
    {
      start: createdAt,
      end: (finishedAt ? new Date(finishedAt) : new Date()).toISOString(),
    },
    {
      units: "short",
    },
  );

  if (!duration) return null;

  switch (status) {
    case SyncRunStatus.SUCCESS:
    case SyncRunStatus.WARNING:
      return `in ${duration}`;
    case SyncRunStatus.FAILED:
    case SyncRunStatus.CANCELLED:
    case SyncRunStatus.INTERRUPTED:
      return `after ${duration}`;
    case SyncRunStatus.ENQUEUED:
    case SyncRunStatus.QUERYING:
    case SyncRunStatus.PROCESSING:
    case SyncRunStatus.REPORTING:
    case SyncRunStatus.ACTIVE:
    case SyncRunStatus.IN_PROGRESS:
    case SyncRunStatus.ABORTED:
    case SyncRunStatus.PENDING:
    case SyncRunStatus.PREPARING:
    case SyncRunStatus.UNPROCESSABLE:
    case SyncRunStatus.DISABLED:
    case SyncRunStatus.INCOMPLETE:
    case UnknownSyncRunStatus:
      return `${duration} elapsed`;
  }
};

export const isSyncRunning = (status: SyncRunStatusOrUnknownStatus) => {
  switch (status) {
    case SyncRunStatus.ENQUEUED:
    case SyncRunStatus.QUERYING:
    case SyncRunStatus.PROCESSING:
    case SyncRunStatus.REPORTING:
    case SyncRunStatus.ACTIVE:
    case SyncRunStatus.IN_PROGRESS:
    case SyncRunStatus.PENDING:
    case SyncRunStatus.PREPARING:
      return true;
    case SyncRunStatus.SUCCESS:
    case SyncRunStatus.WARNING:
    case SyncRunStatus.FAILED:
    case SyncRunStatus.CANCELLED:
    case SyncRunStatus.INTERRUPTED:
    case SyncRunStatus.ABORTED:
    case SyncRunStatus.DISABLED:
    case SyncRunStatus.UNPROCESSABLE:
    case SyncRunStatus.INCOMPLETE:
    case UnknownSyncRunStatus:
    default:
      return false;
  }
};
