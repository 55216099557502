import {
  Checkbox,
  CheckboxGroup,
  Column,
  FilterIcon,
  Menu,
  MenuIconButton,
  MenuList,
  Row,
  Text,
} from "@hightouchio/ui";
import { useMemo, useState } from "react";

import {
  getGroupingColumnPath,
  MoveableBreakdownColumn,
} from "src/components/analytics/breakdowns/utils";
import { Card } from "src/components/card";
import {
  MinimalDecisionEngineFlowMessagesQuery,
  QueryEvaluateTimeSeriesMetricArgs,
  useDecisionEngineFlowChartsQuery,
} from "src/graphql";
import { flowSyntheticColumnValuesGetter } from "src/pages/analytics/decision-engine-utils";
import {
  GroupByColumn,
  SyntheticColumnValuesGetter,
} from "src/pages/analytics/types";
import { Pagination } from "src/ui/table/pagination";
import { useTableConfig } from "src/ui/table/use-table-config";
import { BreakdownChart } from "./components/breakdown-chart";
import { ChartTitle } from "./components/chart-title";
import { EmptyChartsPlaceholder } from "./components/empty-charts-placeholder";
import { GeneratedChartsBlurb } from "./components/generated-charts-blurb";
import { BreakdownType, InsightsChartType } from "./types";
import { useBreakdownMetricSeries } from "./use-breakdown-metric-series";
import { CHARTS_PER_PAGE, MAX_CHARTS, validateCharts } from "./utils";

type GlobalBreakdownFilters = {
  creatives: boolean;
  users: boolean;
};

export const GlobalBreakdowns = ({
  agentId,
  outcome,
  flowMessages,
}: {
  agentId: string;
  outcome: { id: string; name: string };
  flowMessages: MinimalDecisionEngineFlowMessagesQuery["decision_engine_flow_messages"];
}) => {
  const [filters, setFilters] = useState<GlobalBreakdownFilters>({
    creatives: true,
    users: true,
  });

  const { limit, offset, page, setPage } = useTableConfig({
    limit: CHARTS_PER_PAGE,
  });

  const breakdownByTypeFilter =
    !filters.creatives || !filters.users
      ? {
          breakdown_by_type: {
            _in: [
              filters.creatives && BreakdownType.ActionFeature,
              filters.users && BreakdownType.UserFeature,
            ].filter(Boolean) as BreakdownType[],
          },
        }
      : null;

  const { data: chartsData } = useDecisionEngineFlowChartsQuery(
    {
      flowId: agentId,
      filters: {
        _and: [
          { outcome_id: { _eq: outcome.id } },
          { chart_type: { _eq: InsightsChartType.GlobalBreakdown } },
          ...(breakdownByTypeFilter ? [breakdownByTypeFilter] : []),
        ],
      },
      orderBy: { top_chart: "desc_nulls_last", rank: "desc" },
      limit: MAX_CHARTS,
    },
    {
      select: (data) => ({
        charts: data.decision_engine_flow_charts,
      }),
      suspense: true,
    },
  );

  const validCharts = useMemo(() => {
    const charts = chartsData?.charts ?? [];
    return validateCharts(charts);
  }, [chartsData?.charts]);

  const paginatedCharts = validCharts.slice(offset, offset + limit);

  // Getter to return human friendly values, if any, given a column
  // (e.g. for message column, getter returns list of message IDs values -> message name)
  const columnValuesGetter = flowSyntheticColumnValuesGetter(flowMessages);

  return (
    <Column gap={6} p={6} flex={1}>
      <Row justify="space-between">
        <Column>
          <Text size="lg" fontWeight="medium">
            Top performers
          </Text>
          <Text size="sm" color="text.secondary">
            Overall best performing users & creative
          </Text>
        </Column>
        <Row gap={4}>
          {/* <Button
            variant="tertiary"
            onClick={() => {}}
            icon={EditIcon}
            aria-label="Build a chart"
          >
            Build charts from scratch
          </Button> */}
          <GlobalBreakdownsFilterMenu
            filters={filters}
            onChange={(filters) => {
              setFilters(filters);
              setPage(0);
            }}
          />
        </Row>
      </Row>
      {validCharts.length === 0 ? (
        <EmptyChartsPlaceholder />
      ) : (
        <>
          <GeneratedChartsBlurb />
          {paginatedCharts.map((chart) => (
            <GlobalBreakdownChart
              key={chart.id}
              breakdownByDimension={chart.breakdown_by_dimension!}
              timeSeriesRequest={chart.time_series_request}
              columnValuesGetter={columnValuesGetter}
              outcome={outcome}
            />
          ))}
          <Pagination
            page={page}
            setPage={setPage}
            count={validCharts.length}
            rowsPerPage={limit}
            label="charts"
          />
        </>
      )}
    </Column>
  );
};

const CHART_ROWS_LIMIT = 5;

const GlobalBreakdownChart = ({
  breakdownByDimension,
  timeSeriesRequest,
  columnValuesGetter,
  outcome,
}: {
  breakdownByDimension: string;
  timeSeriesRequest: QueryEvaluateTimeSeriesMetricArgs;
  columnValuesGetter: SyntheticColumnValuesGetter;
  outcome: { id: string; name: string };
}) => {
  const groupByColumns: GroupByColumn[] = (
    timeSeriesRequest.groupByColumns ?? []
  ).map((gb) => gb.column);

  const breakdownTableColumnsOrder: MoveableBreakdownColumn[] =
    groupByColumns.map((_, index) => getGroupingColumnPath(index));

  const { graph, isLoading, errorMessage } = useBreakdownMetricSeries({
    timeSeriesRequest,
    columnValuesGetter,
    groupByColumns,
  });

  const graphSeries = graph.series.map((series) => {
    return {
      ...series,
      metricName: outcome.name,
    };
  });

  return (
    <Card p={6} gap={6}>
      <ChartTitle breakdownByDimension={breakdownByDimension} />
      <BreakdownChart
        isLoading={isLoading}
        isError={!!errorMessage}
        data={graphSeries}
        groupByColumns={groupByColumns}
        rowsLimit={CHART_ROWS_LIMIT}
        columnsOrder={breakdownTableColumnsOrder}
      />
    </Card>
  );
};

const GlobalBreakdownsFilterMenu = ({
  filters,
  onChange,
}: {
  filters: GlobalBreakdownFilters;
  onChange: (filters: GlobalBreakdownFilters) => void;
}) => {
  return (
    <Menu>
      <MenuIconButton
        icon={FilterIcon}
        aria-label="Filter charts"
        variant="secondary"
      />
      <MenuList>
        <Column gap={4} p={4}>
          <Text
            size="sm"
            color="text.secondary"
            fontWeight="semibold"
            textTransform="uppercase"
          >
            Show me charts about...
          </Text>
          <CheckboxGroup>
            <Checkbox
              onChange={(e) =>
                onChange({
                  ...filters,
                  creatives: e.target.checked,
                })
              }
              label="Top performing creatives"
              isChecked={filters.creatives}
            />
            <Checkbox
              onChange={(e) =>
                onChange({
                  ...filters,
                  users: e.target.checked,
                })
              }
              label="Top performing users"
              isChecked={filters.users}
            />
          </CheckboxGroup>
        </Column>
      </MenuList>
    </Menu>
  );
};
