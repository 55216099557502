import { FC } from "react";
import {
  DECISION_ENGINE_SUPPORTED__DESTINATIONS,
  DecisionEngineChannelType,
} from "@hightouch/lib/customer-data/decision-engine/types";
import { Controller } from "react-hook-form";
import { Column, Row, Switch, Text } from "@hightouchio/ui";

import { useDestinationsQuery } from "src/graphql";
import { ChannelIcon } from "src/pages/decision-engines/components/channel-icon";
import { ChannelOptionsMap } from "src/pages/decision-engines/utils";
import { ChannelForms } from "./channel-forms";

export const Channel: FC<
  Readonly<{ type: DecisionEngineChannelType; index: number }>
> = ({ type, index }) => {
  const supportedDestinations = DECISION_ENGINE_SUPPORTED__DESTINATIONS[type];

  const { data: destinations } = useDestinationsQuery(
    supportedDestinations
      ? {
          filters: {
            type: {
              _in: supportedDestinations,
            },
          },
        }
      : undefined,
    { select: (data) => data.destinations, suspense: true },
  );

  const Form = ChannelForms[type];

  return (
    <Column
      gap={4}
      borderBottom="1px"
      borderColor="base.border"
      pb={6}
      _last={{ border: "none" }}
    >
      <Row gap={4} align="center" justify="space-between">
        <Row gap={2} align="center">
          <ChannelIcon type={type} />
          <Text fontWeight="medium">{ChannelOptionsMap[type].label}</Text>
        </Row>
        <Controller
          name={`channels.${index}.enabled`}
          render={({ field }) => (
            <Switch
              aria-label="Enable channel."
              onChange={field.onChange}
              isChecked={field.value}
            />
          )}
        />
      </Row>

      {type && (
        <Form
          destinations={destinations ?? []}
          prefix={`channels.${index}.`}
          type={type}
        />
      )}
    </Column>
  );
};
