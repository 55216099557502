import {
  IconButton,
  Button,
  Column,
  Row,
  PlusIcon,
  FormField,
  Box,
  DeleteIcon,
  BadgeInput,
  EditableText,
  Tooltip,
} from "@hightouchio/ui";
import { Controller, useFieldArray } from "react-hook-form";
import {
  VariableDB,
  extractVariableValues,
  VariableVariantDB,
} from "@hightouch/lib/customer-data/decision-engine/types";
import { Channel } from "./campaign";
import { getChannelDefinition } from "src/pages/decision-engines/definitions";

export const Variables = ({ channel }: { channel: Channel }) => {
  const { fields, append, remove } = useFieldArray<{ variables: VariableDB[] }>(
    {
      name: "variables",
    },
  );

  const channelDefinition = getChannelDefinition(channel.destination.type);
  const defaultVariables = channelDefinition.getDefaultVariables(channel);

  return (
    <Column align="flex-start" gap={4} maxW="2xl" w="100%">
      <FormField
        label="Variables"
        description="Define other variables to experiment with different dynamic content blocks, images, copy, and more."
        isOptional
      >
        <Column gap={4} width="100%">
          {fields.map(({ id }, index) => (
            <Column key={id} bg="gray.100" p={4} gap={4} borderRadius="md">
              <Controller
                name={`variables.${index}.name`}
                render={({ field }) => {
                  return (
                    <Row align="center" justify="space-between">
                      <EditableText
                        fontWeight="medium"
                        onChange={field.onChange}
                        value={field.value || "Variable name"}
                      />
                      {!defaultVariables.includes(field.value) && (
                        <Tooltip message="Delete variable">
                          <Box
                            as={IconButton}
                            icon={DeleteIcon}
                            variant="danger"
                            aria-label="Delete variable"
                            onClick={() => {
                              remove(index);
                            }}
                          />
                        </Tooltip>
                      )}
                    </Row>
                  );
                }}
              />
              <VariableValues index={index} />
            </Column>
          ))}
        </Column>
      </FormField>
      <Button
        icon={PlusIcon}
        variant="secondary"
        onClick={() => {
          append({ name: "", variants: [] });
        }}
      >
        Add variable
      </Button>
    </Column>
  );
};

const VariableValues = ({ index }: { index: number }) => {
  return (
    <FormField label="Possible values">
      <Controller
        name={`variables.${index}.variants`}
        render={({ field }) => {
          const variants = field.value || [];
          const displayValues = extractVariableValues({
            variants,
          } as VariableDB);

          const updateValues = (newValues: string[]) => {
            // Update the variants field with new values while preserving existing tags
            const updatedVariants = newValues.map((value) => {
              // Check if this value already exists in variants
              const existingVariant = variants.find(
                (v: VariableVariantDB) => v.value === value,
              );
              // If it exists, preserve its tags, otherwise create new variant with empty tags
              return (
                existingVariant || {
                  value,
                  tags: {},
                }
              );
            });
            field.onChange(updatedVariants);
          };

          return (
            <BadgeInput<string, string>
              supportsCreatableOptions
              width="100%"
              value={displayValues}
              onChange={updateValues}
              optionLabel={(value: string) => value}
              optionValue={(value: string) => value}
              onCreateOption={(createdValue: string) => {
                const newValues = [...displayValues, createdValue];
                updateValues(newValues);
              }}
              options={displayValues}
            />
          );
        }}
      />
    </FormField>
  );
};
