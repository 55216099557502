import { BoxProps, Column, Heading, Paragraph, Row } from "@hightouchio/ui";
import { ReactNode } from "react";
import { DefaultPageContainerPadding } from "./layout/page-container";

type Props = {
  variant: "table";
  title: string;
  actions?: ReactNode;
  description?: string;
  mb?: BoxProps["mb"];
};

const variantStyles: Record<Props["variant"], BoxProps> = {
  table: {
    px: DefaultPageContainerPadding.X,
  },
};

export const ContentHeader = ({
  title,
  actions,
  variant,
  description,
  mb,
}: Props) => {
  return (
    <Row
      justify="space-between"
      align="center"
      gap={4}
      mb={mb}
      {...(variant ? variantStyles[variant] : {})}
    >
      <Column>
        <Heading>{title}</Heading>
        {description && (
          <Paragraph color="text.secondary">{description}</Paragraph>
        )}
      </Column>
      {actions && (
        <Row gap={4} align="center">
          {actions}
        </Row>
      )}
    </Row>
  );
};
