import { FC } from "react";

import {
  Box,
  ChakraAccordion,
  ChakraAccordionButton,
  ChakraAccordionItem,
  ChakraAccordionPanel,
  ChevronRightIcon,
  Column,
  Row,
  Text,
} from "@hightouchio/ui";
import { Link } from "src/router";
import pluralize from "pluralize";
import { useFormContext } from "react-hook-form";

import { JourneyStatusCell, ScheduleText } from "src/pages/journeys/components";
import { JourneyGraph } from "src/pages/journeys/types";

import { Card } from "./card";

export const OverviewCard: FC = () => {
  const form = useFormContext<JourneyGraph>();

  const schedule = form.watch("journey.schedule");
  const exitCriteria = form.watch("journey.exitCriteria");

  const numberOfExitCriteriaConditions = exitCriteria.conditions.length;

  return (
    <Card>
      <ChakraAccordion allowToggle defaultIndex={0}>
        <ChakraAccordionItem border="none">
          {({ isExpanded }) => (
            <>
              <Row align="center" justify="space-between">
                <Text fontWeight="medium">Overview</Text>
                <ChakraAccordionButton
                  aria-label={`${isExpanded ? "Hide" : "View"} overview details`}
                  cursor="pointer"
                  outline="none !important"
                  borderRadius="md"
                  display="inline-flex"
                  width="min-content"
                  p={1}
                >
                  <Box
                    as={ChevronRightIcon}
                    fontSize="18px"
                    transform={isExpanded ? "rotate(90deg)" : ""}
                    transition="transform 150ms ease-in-out"
                  />
                </ChakraAccordionButton>
              </Row>
              <ChakraAccordionPanel p={0} pt={6}>
                <Column gap={4}>
                  <Column gap={2}>
                    <Text
                      color="text.secondary"
                      size="sm"
                      fontWeight="semibold"
                      textTransform="uppercase"
                    >
                      Last run
                    </Text>
                    <JourneyStatusCell />
                  </Column>
                  <Box borderTop="1px solid" borderColor="base.divider" />
                  <Row align="center" justify="space-between">
                    <Column gap={2}>
                      <Text
                        fontWeight="semibold"
                        size="sm"
                        color="text.secondary"
                        textTransform="uppercase"
                      >
                        Schedule
                      </Text>
                      <ScheduleText href="settings" schedule={schedule} />
                    </Column>
                    <Column gap={2}>
                      <Text
                        fontWeight="semibold"
                        size="sm"
                        color="text.secondary"
                        textTransform="uppercase"
                      >
                        Exit criteria
                      </Text>
                      <Link href="settings">
                        <Text color="inherit" fontWeight="medium">
                          {pluralize(
                            "filter",
                            numberOfExitCriteriaConditions,
                            true,
                          )}
                        </Text>
                      </Link>
                    </Column>
                  </Row>
                </Column>
              </ChakraAccordionPanel>
            </>
          )}
        </ChakraAccordionItem>
      </ChakraAccordion>
    </Card>
  );
};
