import * as Yup from "yup";

import {
  JourneyNodeSyncMode,
  JourneyNodeType,
  entryCohortConfigSchema,
  segmentBranchConfigSchema,
  splitsConfigSchema,
  entryEventConfigSchema,
  waitUntilEventConfigSchema as legacyWaitUntilEventConfigSchema,
  timeDelayConfigSchema,
} from "src/types/journeys";
import { IntervalUnit, exhaustiveCheck } from "src/types/visual";
import { isPlaceholderSyncConfig, SplitBranch } from "src/pages/journeys/types";

/**
 * The idea here is to use the backend config schemas. We only
 * specify other fields when they're relevant to the node.
 */

export const StartNodeSchema = Yup.lazy((schema: any) => {
  if (schema.config.type === JourneyNodeType.EntryCohort) {
    return EntryCohortSchema;
  }
  if (schema.config.type === JourneyNodeType.EntryEvent) {
    return EntryEventSchema;
  }

  return Yup.object().shape({
    config: Yup.object({
      type: Yup.string()
        .oneOf([JourneyNodeType.EntryCohort, JourneyNodeType.EntryEvent])
        .required("Entry method type must be set to event or audience"),
    }),
  });
});

export const EntryCohortSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  segment_id: Yup.string().nullable().required("Audience is required"),
  // TODO(samuel): Add types to schemas. These need to be cast due to mismatch between yup versions.
  config: entryCohortConfigSchema as any,
});

export const EntryEventSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  segment_id: Yup.string().nullable().required("Event is required"),
  // TODO(samuel): Add types to schemas. These need to be cast due to mismatch between yup versions.
  config: entryEventConfigSchema as any,
});

const minimumFiveMinuteTimeSchema = Yup.object().shape({
  unit: Yup.string().oneOf(Object.values(IntervalUnit)),
  quantity: Yup.number()
    .positive()
    .when("unit", {
      is: IntervalUnit.Minute, // Only apply this validation when 'unit' is 'minute'
      then: Yup.number()
        .min(5, "Interval must be at least 5 minutes") // Ensure it's at least 5 if 'unit' is "minute"
        .required("Quantity is required when the unit is 'minute'"), // Ensure quantity is required when 'unit' is "minute"
      otherwise: Yup.number()
        .min(0, "Quantity must be greater than or equal to 0")
        .required("Quantity is required")
        .integer(),
    }),
});

export const TimeDelayFormSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  config: Yup.object().shape({
    type: Yup.string().required().oneOf([JourneyNodeType.TimeDelay]),
    delay: minimumFiveMinuteTimeSchema,
  }),
});

export const LegacyTimeDelayFormSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  // TODO(samuel): Add types to schemas. These need to be cast due to mismatch between yup versions.
  config: timeDelayConfigSchema as any,
});

export const WaitUntilEventFormSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  segment_id: Yup.string().nullable().required("Filter is required"),
  event_relationship_id: Yup.string().nullable().required("Filter is required"),
  config: Yup.object().shape({
    type: Yup.string().required().oneOf([JourneyNodeType.WaitUntilEvent]),
    timeout_duration: minimumFiveMinuteTimeSchema,
  }),
});

export const LegacyWaitUntilEventFormSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  segment_id: Yup.string().nullable().required("Filter is required"),
  event_relationship_id: Yup.string().nullable().required("Filter is required"),
  // TODO(samuel): Add types to schemas. These need to be cast due to mismatch between yup versions.
  config: legacyWaitUntilEventConfigSchema as any,
});

export const SegmentBranchFormSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  // TODO(samuel): Add types to schemas. These need to be cast due to mismatch between yup versions.
  config: segmentBranchConfigSchema as any,
});

const SplitBranchFormSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  percentage: Yup.number()
    .min(1)
    .max(99)
    .integer()
    .required("Percentage must be between 1 and 99"),
});

export const SplitsSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  // TODO(samuel): Add types to schemas. These need to be cast due to mismatch between yup versions.
  config: splitsConfigSchema as any,
});

export const SplitsFormSchema = Yup.object().shape({
  node: SplitsSchema,
  splits: Yup.array()
    .of(SplitBranchFormSchema)
    .test("percentages-check", "Split groups must add to 100%", (splits) => {
      const sum =
        splits?.reduce((acc, split) => {
          return acc + ((split as SplitBranch)?.percentage || 0);
        }, 0) || 0;
      return sum === 100;
    })
    .test(
      "unique-name-check",
      "Split groups names must be unique",
      (splits) => {
        if (!splits) {
          return true;
        }
        return (
          splits.length ===
          new Set(splits?.map((split) => (split as SplitBranch)?.name)).size
        );
      },
    ),
});

export const SyncFormSchema = Yup.lazy((value: any) => {
  if (isPlaceholderSyncConfig(value)) {
    // 1 - Only placeholder_destination_id is provided (placeholder config)
    return Yup.object().shape({
      placeholder_destination_id: Yup.number().required(),
    });
  }

  // 2 | 3 - Fully configured sync node (with or without placeholder_destination_id)
  return Yup.object().shape({
    mode: Yup.string().oneOf(Object.values(JourneyNodeSyncMode)).required(),
    destination_instance_id: Yup.number().required(),
    // On the frontend the properties of `exit_config` are nullable.
    // This diverges from the schema on the backend.
    // Using `undefined` as a 'false-y' value does not work with the Radio components.
    exit_config: Yup.object().shape({
      remove_after: Yup.object()
        .nullable()
        .shape({
          unit: Yup.string().oneOf(Object.values(IntervalUnit)),
          quantity: Yup.number().positive().integer(),
        }),
      remove_on_journey_exit: Yup.boolean().nullable(),
    }),

    placeholder_destination_id: Yup.number().nullable(),
  });
});

const SyncNodeSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  sync_configs: Yup.array().of(SyncFormSchema).nullable(),
});

const NoOpSchema = Yup.object();

export const JourneyNodeSchema = Yup.array().of(
  Yup.object().shape({
    data: Yup.lazy((value: any): Yup.ObjectSchema => {
      const type = value.config.type as JourneyNodeType;
      switch (type) {
        case JourneyNodeType.EntryCohort:
          return EntryCohortSchema;
        case JourneyNodeType.EntryEvent:
          return EntryEventSchema;
        case JourneyNodeType.TimeDelay:
          // TODO(marcus): switch over to TimeDelayFormSchema once
          // we migrate all journeys/workspaces to 5min minimums.
          return LegacyTimeDelayFormSchema; // TimeDelayFormSchema;
        case JourneyNodeType.WaitUntilEvent:
          // TODO(marcus): switch over to TimeDelayFormSchema once
          // we migrate all journeys/workspaces to 5min minimums.
          return LegacyWaitUntilEventFormSchema; // WaitUntilEventFormSchema;
        case JourneyNodeType.Segments:
          // No fields to validate, all config is on the segment branches.
          return Yup.object();
        case JourneyNodeType.SegmentBranch:
          return SegmentBranchFormSchema;
        case JourneyNodeType.Sync:
          return SyncNodeSchema;
        case JourneyNodeType.Splits:
          return SplitsSchema;
        case JourneyNodeType.SplitBranch:
          return NoOpSchema;
        case JourneyNodeType.WaitUntilEventBranch:
          // Not updatable by the user
          return NoOpSchema;
        case JourneyNodeType.NoOp:
          // The user cannot create NoOp nodes.
          throw new Error(
            `JourneyNodeType ${type} does not have a valid schema defined`,
          );
        default:
          // Make the type checker enforce that we cover all of the possible
          // types here.
          exhaustiveCheck(type);
      }
    }),
  }),
);

export const JourneySchema = Yup.object().shape({
  journey: Yup.object().shape({
    id: Yup.string().required("id is required"),
  }),
  nodes: JourneyNodeSchema,
});

export const CreateJourneySchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  parentModelId: Yup.number().nullable().required("Parent model is required"), // nullable so it can begin as `null`
  description: Yup.string().nullable(),
});

export const JourneySettingSchema = Yup.object().shape({
  schedule: Yup.object().shape({
    type: Yup.string().oneOf(["interval", "visual_cron"]),
    // Validation is first handled by the ScheduleManager. The schema checks outlined
    // here are run afterwards and include additional checks that we want to enforce.
    schedule: Yup.object()
      .when("type", {
        is: "interval",
        then: Yup.object()
          .shape({
            interval: minimumFiveMinuteTimeSchema,
          })
          .required(),
        otherwise: Yup.object().notRequired(),
      })
      .notRequired(),
  }),
});
