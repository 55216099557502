import { FC } from "react";

import { Controller } from "react-hook-form";
import { Column, Heading } from "@hightouchio/ui";

import { useOutletContext } from "src/router";
import { ScheduleManager } from "src/components/schedule";
import { ScheduleType } from "src/components/schedule/types";
import { useUpdateEventWarehouseSyncMutation } from "src/graphql";
import { FormBar } from "src/components/form/form-bar";
import { useHightouchForm } from "src/components/form/hooks";
import { OutletContext } from ".";
import { Form } from "src/components/form";

export const WarehouseSyncSchedule: FC = () => {
  const { sync } = useOutletContext<OutletContext>();

  const form = useHightouchForm({
    values: { schedule: sync.schedule },
    onSubmit: async (data) => {
      await updateMutation.mutateAsync({
        id: sync.id,
        input: {
          schedule: data.schedule,
        },
      });
    },
  });

  const updateMutation = useUpdateEventWarehouseSyncMutation();

  return (
    <Form form={form}>
      <Column flex={1} gap={4}>
        <Heading>Schedule</Heading>
        <Controller
          name="schedule"
          render={({ field }) => (
            <ScheduleManager
              resource="event_sync"
              schedule={field.value}
              setSchedule={field.onChange}
              types={[
                ScheduleType.MANUAL,
                ScheduleType.INTERVAL,
                ScheduleType.CRON,
              ]}
              includeStartAndEnd={false}
            />
          )}
        />
      </Column>
      <FormBar
        permission={{
          v1: { resource: "workspace", grant: "update" },
          v2: {
            resource: "workspace",
            grant: "can_update",
          },
        }}
      />
    </Form>
  );
};
