import {
  FC,
  MouseEventHandler,
  MouseEvent as ReactMouseEvent,
  useEffect,
  useMemo,
  useState,
} from "react";

import {
  Box,
  Column,
  EmptyState,
  Portal,
  Row,
  Switch,
  Text,
  useToken,
} from "@hightouchio/ui";
import {
  Cell,
  LabelList,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
} from "recharts";

import { abbreviateNumber } from "src/utils/numbers";
import { GraphTooltip } from "src/components/analytics/graph-tooltip";
import {
  HexColors,
  NumericFontStyles,
} from "src/components/audiences/constants";
import { buildTooltipText } from "src/components/audiences/insights/chart-cards/utils";

import { AudiencePropertyBreakdownTable } from "./audience-property-breakdown-table";

const getVisibleValue = (value: unknown) => {
  if (value === null) {
    return "null";
  }

  if (value === "") {
    return "<empty string>";
  }

  // Cast to a string in case the value is a boolean
  // so we can display it as a label on the graph
  return String(value);
};

const MaxCharacterLength = 9;

const valueFormatter = (
  value: number | string,
  formatKey: "percentage" | "count",
): string => {
  if (formatKey === "percentage" && typeof value === "number") {
    return `${(value * 100).toFixed(2)}%`;
  } else if (typeof value === "number") {
    return abbreviateNumber(value);
  } else if (typeof value === "boolean") {
    return String(value);
  }
  // Abbreviate values so they don't overflow on the page
  if (value.length > MaxCharacterLength) {
    return value.slice(0, MaxCharacterLength) + "...";
  }

  return value;
};

export type ChartType = "table" | "pie";

type Props = {
  chartType: ChartType;
  data: { value: string; count: number; percentage?: number }[];
  onMouseEnterCell?: MouseEventHandler<HTMLDivElement>;
  onMouseLeaveCell?: MouseEventHandler<HTMLDivElement>;
};

export const BreakdownGraph: FC<Props> = ({
  chartType,
  data,
  onMouseEnterCell,
  onMouseLeaveCell,
}) => {
  const [animate, setAnimate] = useState(true);
  const [showTop10, setShowTop10] = useState(true);
  const [activeCell, setActiveCell] = useState<number | null>(null);

  const tokenizedColor = useToken(
    "colors",
    HexColors[(activeCell ?? 0) % HexColors.length]?.base ?? "",
  );
  const activeCellColor = activeCell ? tokenizedColor : undefined;

  const formattedData = useMemo(
    () =>
      data.map(({ value, ...rest }) => ({
        ...rest,
        value: getVisibleValue(value),
      })),
    [data],
  );

  const filteredData = formattedData.filter(
    ({ value }) => !showTop10 || value !== "Other",
  );
  const hasOtherColumn = useMemo(
    () => formattedData.some(({ value }) => value === "Other"),
    [data],
  );

  const enterCell = (
    event: ReactMouseEvent<HTMLDivElement>,
    cellIndex: number,
  ) => {
    setAnimate(false);
    setActiveCell(cellIndex);
    setAnimate(false);
    onMouseEnterCell?.(event);
  };

  const leaveCell = (event: ReactMouseEvent<HTMLDivElement>) => {
    setActiveCell(null);
    onMouseEnterCell?.(event);
  };

  if (formattedData.length === 0) {
    return (
      <Box sx={{ ">div": { border: "none" } }}>
        <EmptyState
          title="No data available"
          message="Audience has 0 rows. Try updating your query and re-running the breakdown"
        />
      </Box>
    );
  }

  return (
    <Column gap={4}>
      <Column
        width="100%"
        sx={{
          ":not(:last-child)": { mb: 8 },
        }}
        onMouseLeave={onMouseLeaveCell}
      >
        <Column gap={2} flex={1} minWidth={0}>
          {hasOtherColumn && (
            <Row align="center" justify="end" flex={1} minWidth={0}>
              <Text as="label" fontWeight="medium">
                <Row
                  alignItems="center"
                  gap={2}
                  cursor="pointer"
                  sx={{ ">div": { display: "flex" } }}
                >
                  Limit to top 10
                  <Switch
                    aria-label="Display only top 10 values."
                    size="sm"
                    isChecked={showTop10}
                    onChange={(value) => {
                      setAnimate(true);
                      setShowTop10(value);
                    }}
                  />
                </Row>
              </Text>
            </Row>
          )}
          {chartType === "pie" && (
            <ResponsiveContainer height={230}>
              <PieChart key="graphName-pie">
                <Pie
                  animationBegin={0}
                  animationDuration={600}
                  data={filteredData}
                  dataKey="count"
                  isAnimationActive={animate}
                  nameKey="value"
                  cursor="pointer"
                  onMouseEnter={enterCell}
                  onMouseLeave={leaveCell}
                  innerRadius={50}
                >
                  {filteredData.map((_, cellIndex) => {
                    const color = HexColors[cellIndex % HexColors.length];
                    const token = useToken("colors", color?.base ?? "");

                    return <Cell key={`cell-${cellIndex}`} fill={token} />;
                  })}
                  <LabelList
                    dataKey="value"
                    fontFamily="inter"
                    fontSize="12px"
                    fill="#252D36"
                    style={NumericFontStyles}
                    formatter={(value) => valueFormatter(value, "count")}
                    position="outside"
                    stroke="transparent"
                  />
                </Pie>
                <Tooltip
                  content={<TooltipContent color={activeCellColor} />}
                  isAnimationActive={false}
                />
              </PieChart>
            </ResponsiveContainer>
          )}
          {chartType === "table" && (
            <AudiencePropertyBreakdownTable data={filteredData} />
          )}
        </Column>
      </Column>
    </Column>
  );
};

const TooltipContent = ({
  payload,
  color,
}: TooltipProps<number, string> & { color: string | undefined }) => {
  const [position, setPosition] = useState<{
    x: number | null;
    y: number | null;
  }>({ x: null, y: null });

  useEffect(() => {
    const handleWindowMouseMove = (event: MouseEvent) => {
      if (!payload?.[0]) {
        return null;
      }

      return setPosition({
        x: event.pageX,
        y: event.pageY,
      });
    };
    window.addEventListener("mousemove", handleWindowMouseMove);

    return () => {
      window.removeEventListener("mousemove", handleWindowMouseMove);
    };
  }, [payload?.length]);

  if (!payload?.[0]) {
    return null;
  }

  const {
    payload: { count, value, percentage, fill },
  } = payload[0];

  return (
    <Portal>
      <Box
        position="absolute"
        top={`${position.y === null ? 0 : position.y + 20}px`}
        left={`${position.x === null ? 0 : position.x + 20}px`}
        mr={3}
        display={position.x === null ? "none" : "visible"}
        zIndex="popover"
        boxShadow="sm"
        bg="text.primary"
        p={3}
        borderRadius="md"
      >
        <GraphTooltip
          title={value}
          color={color || fill}
          value={[
            {
              value: buildTooltipText({ count, percentage }),
            },
          ]}
        />
      </Box>
    </Portal>
  );
};
