import {
  createContext,
  FC,
  ReactNode,
  RefObject,
  useCallback,
  useRef,
  useState,
} from "react";

import { UserGroupResourceType as V2ResourceToPermission } from "@hightouch/core/server/lib/auth/usergroup/types";
import { Row } from "@hightouchio/ui";
import noop from "lodash/noop";
import { OnConnectEnd, OnConnectStart } from "reactflow";

import { QueryBuilderProvider } from "src/components/explore/context/query-builder-context";
import { useFetchQueryBuilderParentModel } from "src/components/explore/hooks";
import { ResourcePermissionInput } from "src/components/permission/use-resource-permission";
import {
  AudiencesOfParentModelQuery,
  ResourceToPermission,
  useAudiencesOfParentModelQuery,
} from "src/graphql";
import { FullParentModel } from "src/components/audiences/types";
import { LIVE_STATES } from "src/pages/journeys/constants";
import {
  getUpdateJourneyPermission,
  PERMISSION_UNAUTHORIZED_TOOLTIP,
} from "src/pages/journeys/permission-utils";
import {
  JourneyNodeRun,
  JourneyRun,
  JourneyState,
} from "src/pages/journeys/types";

import { useJourneyGraph } from "./use-journey-graph";

type GraphContextState = {
  isDetailsDrawerOpen: boolean;
  reactFlowInstance: RefObject<HTMLDivElement>;
  sidebarRef: RefObject<HTMLDivElement>;
  audienceOptions: AudiencesOfParentModelQuery["segments"];
  audiencesLoading: boolean;
  parentModel: FullParentModel | null;
  connectionSourceNodeId: string | null;
  isJourneyLive: boolean;
  isDraft: boolean;
  onConnectStart: OnConnectStart;
  onConnectEnd: OnConnectEnd;
  unauthorizedTooltip: string;
  onCloseDrawer: () => void;
  onOpenDrawer: () => void;
};

type GraphContextProps = {
  versionId: string;
  latestJourneyRun: JourneyRun | undefined;
  lastCompletedJourneyRun: JourneyRun | undefined;
  isEditMode: boolean;
  nodeRunErrors: JourneyNodeRun[];
  nodeRunStats: JourneyNodeRun[];
  unauthorized?: boolean;
  updateJourneyPermission: ResourcePermissionInput<
    V2ResourceToPermission,
    ResourceToPermission
  >;
  numberOfPreviousRuns: number;
  numberOfUsersInJourney: number;
  numberOfUniqueUsersInJourney: number;

  onShowJourneyRunErrors: () => void;
  onToggleEditMode: () => void;
} & Omit<ReturnType<typeof useJourneyGraph>, "form">;

type GraphContextValues = GraphContextState & GraphContextProps;

interface GraphProviderProps
  extends Omit<GraphContextValues, keyof GraphContextState> {
  parentModelId: number;
  children: ReactNode;
  journeyState: JourneyState;
}

export const GraphContext = createContext<GraphContextValues>({
  nodeErrors: {},
  nodeWarnings: {},

  reactFlowInstance: { current: null },
  sidebarRef: { current: null },

  versionId: "",
  isEditMode: false,
  connectionSourceNodeId: null,
  isDraft: true,
  isJourneyLive: false,
  latestJourneyRun: undefined,
  lastCompletedJourneyRun: undefined,
  unauthorized: false,
  nodes: [],
  nodeRunErrors: [],
  nodeRunStats: [],
  numberOfPreviousRuns: 0,
  numberOfUsersInJourney: 0,
  numberOfUniqueUsersInJourney: 0,

  // Node selection
  onClearNodeSelection: noop,
  onSelectNodes: noop,

  parentModel: null,
  audienceOptions: [],

  audiencesLoading: false,
  isDetailsDrawerOpen: false,

  updateJourneyPermission: getUpdateJourneyPermission(undefined),
  unauthorizedTooltip: PERMISSION_UNAUTHORIZED_TOOLTIP,

  // actions
  getBranchNodes: () => [],
  hasOutgoers: () => true,
  isValidConnection: () => true,
  onAddNode: noop,
  onAddSegmentBranch: () => "",
  onAddSplitBranch: () => "",
  onAddPlaceholderDestination: noop,
  onAddSyncConfigToNode: noop,
  onCleanUp: () => Promise.resolve(),
  onCloneNodes: () => [],
  onConnect: noop,
  onConnectEnd: noop,
  onConnectStart: noop,
  onDeleteJourney: () => Promise.resolve(),
  onResetJourney: () => Promise.resolve(),
  onEdgesChange: noop,
  onFitView: () => false,
  onCloseDrawer: noop,
  onNodesChange: noop,
  onRefitView: noop,
  onRemoveNode: noop,
  onRunJourney: () => Promise.resolve(),
  onRemovePlaceholderDestinationSyncConfig: noop,
  onRemoveSyncConfigFromNode: noop,
  onSetCenter: noop,
  onShowJourneyRunErrors: noop,
  onOpenDrawer: noop,
  onToggleEditMode: noop,
  onUpdateJourneySettings: noop,
  onUpdateJourneyStatus: () => Promise.resolve(undefined),
  onUpdateNode: noop,
  onUpdateSegmentPriority: noop,
  onUpdateSyncConfig: noop,
  onUpdateSplitGroups: noop,
  onZoomIn: noop,
  onZoomOut: noop,
});

export const GraphProvider: FC<GraphProviderProps> = ({
  parentModelId,
  journeyState,
  children,
  ...props
}) => {
  // Wrapper around the graph so that the element may be measured when
  // placing new nodes from the sidebar
  const reactFlowWrapper = useRef<HTMLDivElement>(null);
  const sidebarRef = useRef<HTMLDivElement>(null);

  const [connectionSourceNodeId, setConnectionSourceNodeId] = useState<
    string | null
  >(null);
  // If journey is not in a draft state or in edit mode
  // this state will determine if the drawer is open
  const [isDetailsDrawerOpen, setIsDetailsDrawerOpen] = useState(false);

  const openDrawer = () => {
    setIsDetailsDrawerOpen(true);
  };

  const closeDrawer = useCallback(() => {
    setIsDetailsDrawerOpen(false);
  }, []);

  const connectStart: OnConnectStart = (_, { nodeId }) => {
    setConnectionSourceNodeId(nodeId);
  };

  const connectEnd: OnConnectEnd = () => {
    setConnectionSourceNodeId(null);
  };

  const { parentModel } = useFetchQueryBuilderParentModel({
    parentModelId: parentModelId?.toString(),
  });

  const audiencesOfParentModelQuery = useAudiencesOfParentModelQuery(
    { parentModelId: parentModelId?.toString() ?? "" },
    {
      enabled: Boolean(parentModelId),
      select: (data) => data.segments,
    },
  );

  const audienceOptions = audiencesOfParentModelQuery.data ?? [];
  const isDraft = journeyState === "draft";
  const isJourneyLive = LIVE_STATES.includes(journeyState);

  return (
    <GraphContext.Provider
      value={{
        ...props,
        isDetailsDrawerOpen: isDraft || props.isEditMode || isDetailsDrawerOpen,
        isDraft,
        isEditMode: props.unauthorized ? false : props.isEditMode,
        isJourneyLive,
        parentModel,
        audienceOptions,
        reactFlowInstance: reactFlowWrapper,
        sidebarRef,
        audiencesLoading: audiencesOfParentModelQuery.isLoading,
        connectionSourceNodeId,
        unauthorizedTooltip: PERMISSION_UNAUTHORIZED_TOOLTIP,
        onConnectStart: connectStart,
        onConnectEnd: connectEnd,
        onOpenDrawer: openDrawer,
        onCloseDrawer: closeDrawer,
      }}
    >
      <QueryBuilderProvider parentModel={parentModel}>
        <Row ref={reactFlowWrapper} flex={1} minWidth={0}>
          {children}
        </Row>
      </QueryBuilderProvider>
    </GraphContext.Provider>
  );
};
