import { Navigate, RouteObject } from "src/router";
import { CreateSync } from "./create/create-sync";
import Syncs from "./syncs";
import { PermissionedRoute } from "src/components/router/permissioned-route";
import { SyncWrapper } from "./sync";
import { Overview as SyncOverview } from "./sync/overview";
import { Configuration as SyncConfiguration } from "./sync/configuration";
import { Schedule as SyncSchedule } from "./sync/schedule";
import { Runs as SyncRuns } from "./sync/runs";
import { Alerts as SyncAlerts } from "./sync/alerts";
import { Alerting } from "./sync/alerting";
import { Logs as SyncLogs } from "./sync/logs";
import { Splits as SyncSplits } from "./sync/splits";
import { Activity as SyncActivity } from "./sync/activity";
import { SyncDraft } from "./sync/draft";
import { SyncRunWrapper } from "./sync/run";
import {
  RejectedRows as SyncRunRejectedRows,
  SuccessfulRows as SyncRunSuccessfulRows,
} from "./sync/run/rows";
import { PendingRows as SyncRunPendingRows } from "./sync/run/rows/pending";
import { ConfigurationPage as SyncRunConfiguration } from "./sync/run/configuration";
import { RunDebug as SyncRunDebug } from "./sync/run/debug";
import { SyncRunSummary } from "./sync/run/summary";
import { useFlags } from "launchdarkly-react-client-sdk";

// Temporary until the run summary page is enabled for all users
const SyncRunRedirect = () => {
  const { appSyncRunSummaryPageEnabled } = useFlags();

  const pathname = appSyncRunSummaryPageEnabled ? "summary" : "successful";
  return <Navigate to={{ pathname, search: location?.search }} replace />;
};

const SyncDebugPermissionedRoute = ({ children }) => {
  const { appShowDebugSuggestionOnly } = useFlags();
  if (appShowDebugSuggestionOnly) return children;
  return (
    <PermissionedRoute
      permission={{
        v2: { resource: "sync", grant: "can_debug", id: "sync_id" },
      }}
      redirect="/syncs/:sync_id"
    >
      {children}
    </PermissionedRoute>
  );
};
export const routes: Array<RouteObject> = [
  { path: "syncs", element: <Syncs /> },
  {
    path: "syncs/:sync_id/*",
    element: <SyncWrapper />,
    children: [
      {
        index: true,
        element: (
          <Navigate
            to={{ pathname: "overview", search: location?.search }}
            replace
          />
        ),
      },
      { path: "overview", element: <SyncOverview /> },
      { path: "configuration", element: <SyncConfiguration /> },
      { path: "schedule", element: <SyncSchedule /> },
      { path: "runs", element: <SyncRuns /> },
      { path: "alerts", element: <SyncAlerts /> },
      { path: "alerting", element: <Alerting /> },
      { path: "logs", element: <SyncLogs /> },
      { path: "splits", element: <SyncSplits /> },
      { path: "activity", element: <SyncActivity /> },
    ],
  },
  { path: "syncs/:sync_id/draft", element: <SyncDraft /> },
  {
    path: "syncs/:sync_id/runs/:run_id/*",
    element: <SyncRunWrapper />,
    children: [
      {
        index: true,
        element: <SyncRunRedirect />,
      },
      { path: "summary", element: <SyncRunSummary /> },
      {
        path: "successful",
        element: (
          <SyncDebugPermissionedRoute>
            <SyncRunSuccessfulRows />
          </SyncDebugPermissionedRoute>
        ),
        children: [{ path: "debug/:row_id", element: <SyncRunDebug /> }],
      },
      {
        path: "rejected",
        element: (
          <SyncDebugPermissionedRoute>
            <SyncRunRejectedRows />
          </SyncDebugPermissionedRoute>
        ),
        children: [{ path: "debug/:row_id", element: <SyncRunDebug /> }],
      },
      {
        path: "pending",
        element: (
          <SyncDebugPermissionedRoute>
            <SyncRunPendingRows />
          </SyncDebugPermissionedRoute>
        ),
      },
      { path: "configuration", element: <SyncRunConfiguration /> },
    ],
  },
  {
    path: "syncs/new",
    element: (
      <PermissionedRoute
        permission={{
          v1: { resource: "sync", grant: "create" },
        }}
        redirect="/syncs"
      >
        <CreateSync />
      </PermissionedRoute>
    ),
  },
];
