import { SyncQuery, SyncRequestsBoolExp, SyncRunsQuery } from "src/graphql";

import { FC, useEffect, useMemo, useState } from "react";
import {
  ArrowRightIcon,
  Badge,
  Box,
  ChakraPopover,
  ChakraPopoverBody,
  ChakraPopoverContent,
  ChakraPopoverTrigger,
  ChevronDownIcon,
  Column,
  FilterMenu,
  FilterMenuButton,
  FilterMenuGroup,
  FilterMenuList,
  FilterMenuOption,
  PlayIcon,
  Row,
  Text,
  Tooltip,
} from "@hightouchio/ui";
import { useNavigate, useOutletContext } from "src/router";
import { SyncRequestsOrderBy, useSyncRunsQuery } from "src/graphql";
import { SyncRequestErrorInfo } from "src/types/sync-errors";
import {
  BasicPagination,
  Table,
  TableColumn,
  useTableConfig,
} from "src/ui/table";
import { commaNumber } from "src/utils/numbers";
import {
  SyncRunStatus,
  UnknownSyncRunStatus,
  getSyncRunOperations,
  isDeprecatedSyncRunError,
  isSyncRunStatus,
} from "src/utils/syncs";
import { openUrl } from "src/utils/urls";
import { QueryType } from "src/types/models";

import { SyncRequestErrorModal } from "./components/error-modals";
import { isSyncMatchBoosted } from "./matchbooster";
import { SyncRunStatusIndicator } from "src/components/syncs/sync-run-status-indicator";
import { ElementOf } from "ts-essentials";
import { DiffModeOverride } from "@hightouch/lib/sync/diff-mode/types";
import { isEnum } from "src/types/utils";
import { useDraft } from "src/contexts/draft-context";
import { PermissionedButton } from "src/components/permission";
import { RunOperationsColumn } from "./components/run-operations-column";
import {
  formatRunCreatedAt,
  syncRunStatusDurationText,
} from "./components/utils";
import { TextWithTooltip } from "src/components/text-with-tooltip";
import { useCurrentRun } from "./utils/use-current-run";
import { Context } from ".";
import { Link } from "src/router";
import { useFlags } from "launchdarkly-react-client-sdk";

enum SyncRunsFilter {
  ALL_RUNS = "all_runs",
  NO_OPERATIONS = "no_operations",
  NO_SUCCESSFUL_OPERATIONS = "no_successful_operations",
}

enum SortKeys {
  RowsQueried = "query_run.size",
  CreatedAt = "created_at",
}

type Props = {
  onRun: () => void;
  sync: SyncQuery["syncs"][0];
  redirectPrefix?: string;
};

const PAGE_SIZE = 10;

const getHasuraFilter = (type: SyncRunsFilter): SyncRequestsBoolExp => {
  switch (type) {
    case SyncRunsFilter.NO_OPERATIONS:
      return {
        sync_attempts: {
          _not: {
            add_checkpoint: { _eq: 0 },
            change_checkpoint: { _eq: 0 },
            remove_checkpoint: { _eq: 0 },
          },
        },
      };
    case SyncRunsFilter.NO_SUCCESSFUL_OPERATIONS:
      return {
        sync_attempts: { successful_operations_count: { _gt: 0 } },
      };
    default:
      // Get all attempts if they exist or not
      return {};
  }
};

export const Runs: FC = () => {
  const { sync } = useOutletContext<Context>();
  const { startRun } = useCurrentRun(sync.id);

  return (
    <SyncRuns
      sync={sync}
      onRun={() => {
        startRun();
      }}
    />
  );
};

export const SyncRuns: FC<Readonly<Props>> = ({
  sync,
  onRun,
  redirectPrefix = "",
}) => {
  const { editingDraft } = useDraft();
  const { appSyncRunSummaryPageEnabled, appShowDebugSuggestionOnly } =
    useFlags();

  const navigate = useNavigate();
  const [runError, setError] = useState<
    SyncRequestErrorInfo & { syncStatus: SyncRunStatus }
  >();
  const [runsFilter, setRunsFilter] = useState<SyncRunsFilter>(
    SyncRunsFilter.ALL_RUNS,
  );

  const isJourneyTriggered = sync.segment?.query_type === QueryType.JourneyNode;

  const { limit, offset, orderBy, page, setPage, onSort } =
    useTableConfig<SyncRequestsOrderBy>({
      defaultSortKey: "created_at",
      sortOptions: Object.values(SortKeys),
      limit: PAGE_SIZE,
    });

  const hasuraFilter = useMemo(
    () => ({
      destination_instance_id: { _eq: sync.id },
      ...getHasuraFilter(runsFilter),
    }),
    [runsFilter],
  );

  const {
    data,
    error,
    isLoading: isLoading,
    isPreviousData,
  } = useSyncRunsQuery(
    {
      filter: hasuraFilter,
      offset,
      limit: limit + 1, // use limit + 1 to look ahead if another page is available
      orderBy,
    },
    {
      refetchInterval: 5000,
      notifyOnChangeProps: "tracked",
      keepPreviousData: true,
    },
  );

  const runs = useMemo(() => {
    const runs = data?.sync_requests;
    if (runs?.length === PAGE_SIZE + 1) {
      return runs.slice(0, PAGE_SIZE);
    }
    return runs;
  }, [data?.sync_requests]);

  useEffect(() => {
    // reset pagination when changing filter
    setPage(0);
  }, [runsFilter]);

  const showPendingRows =
    isSyncMatchBoosted(sync?.config) ||
    runs?.some(
      (run) => run?.query_run?.pending_rows && run.query_run.pending_rows > 0,
    );

  const columns = useMemo(
    (): TableColumn<ElementOf<SyncRunsQuery["sync_requests"]>>[] => [
      {
        name: "Started",
        min: "min-content",
        sortDirection: orderBy?.created_at,
        onClick: () => onSort(SortKeys.CreatedAt),
        cell: ({ created_at }) => {
          const date = formatRunCreatedAt(created_at);
          return (
            <TextWithTooltip message={date ?? ""}>
              {date ?? "--"}
            </TextWithTooltip>
          );
        },
      },
      {
        name: "Run status",
        min: "min-content",
        cell: ({
          status_computed,
          completion_ratio,
          created_at: createdAt,
          sync_attempts,
          error: syncRequestError,
          error_code_detail,
          diff_mode_override,
          finished_at,
        }) => {
          if (!isSyncRunStatus(status_computed)) {
            return (
              <SyncRunStatusIndicator
                status={UnknownSyncRunStatus}
                completionRatio={null}
              />
            );
          }
          const attempt = sync_attempts?.[0];

          // Use `sync_requests.error` if present, then fall back to `sync_attempts.error`.
          const error: SyncRequestErrorInfo =
            syncRequestError ||
            (attempt?.error && !isDeprecatedSyncRunError(attempt?.error)
              ? { message: attempt?.error }
              : undefined);

          if (error && error_code_detail)
            error.errorCodeDetail = error_code_detail;

          const syncRunFailed = status_computed === SyncRunStatus.FAILED;

          let diffModeOverrideLabel;
          if (isEnum(DiffModeOverride)(diff_mode_override)) {
            switch (diff_mode_override) {
              case "full_resync":
              case "clear_and_fill":
                diffModeOverrideLabel = "Full resync";
                break;
              case "reset_cdc":
                diffModeOverrideLabel = "Reset CDC";
                break;
              default:
                break;
            }
          }

          return (
            <Column fontWeight="medium">
              <Row gap={1} alignItems="center">
                <SyncRunStatusIndicator
                  status={status_computed}
                  completionRatio={completion_ratio}
                />
                <Text color="text.secondary" fontWeight="normal">
                  {syncRunStatusDurationText(
                    status_computed,
                    createdAt,
                    finished_at,
                  )}
                </Text>
                {diffModeOverrideLabel && (
                  <Badge size="sm">{diffModeOverrideLabel}</Badge>
                )}
              </Row>
              <Row>
                {syncRunFailed && (
                  <Link
                    href=""
                    ml={6}
                    onClick={(event) => {
                      event.preventDefault();
                      event.stopPropagation();

                      setError({
                        ...(error ?? {}),
                        syncStatus: status_computed,
                      });
                    }}
                  >
                    <Text size="sm" color="danger.base">
                      See error message
                      <ArrowRightIcon ml={1} />
                    </Text>
                  </Link>
                )}
              </Row>
            </Column>
          );
        },
      },
      {
        sortDirection: orderBy?.query_run?.size,
        onClick: () => onSort(SortKeys.RowsQueried),
        header: () => (
          <Row
            align="center"
            justify="flex-end"
            w="100%"
            pos="relative"
            right="-6px"
          >
            <Tooltip message="Number of rows in your full model query results">
              <Text whiteSpace="nowrap" size="sm" color="text.secondary">
                Rows queried
              </Text>
            </Tooltip>
          </Row>
        ),
        cell: ({ query_run }) => {
          return (
            <Column align="flex-end" flex={1}>
              <Text>
                {query_run?.size !== undefined
                  ? commaNumber(query_run.size)
                  : "--"}
              </Text>
            </Column>
          );
        },
      },
      {
        header: () => (
          <Row align="center" justify="flex-end" w="100%" pos="relative">
            <Tooltip message="Number of operations performed during each sync run">
              <Text size="sm" color="text.secondary">
                Operations
              </Text>
            </Tooltip>
          </Row>
        ),
        cell: ({
          planner_type,
          add_executed,
          change_executed,
          remove_executed,
          query_run,
          sync_attempts,
          status_computed,
        }) => {
          const { successful, rejected } = getSyncRunOperations({
            attempt: sync_attempts?.[0],
            syncRequest: {
              status_computed,
              error,
              planner_type,
              add_executed,
              change_executed,
              remove_executed,
            },
            queryRun: query_run,
          });

          const added = successful.added + rejected.added;
          const changed = successful.changed + rejected.changed;
          const removed = successful.removed + rejected.removed;

          const total = added + changed + removed;

          if (total === 0) {
            return (
              <Column align="flex-end" flex={1}>
                <Text>--</Text>
              </Column>
            );
          }

          return (
            <Column align="flex-end" flex={1}>
              <ChakraPopover placement="bottom-end" offset={[0, -14]}>
                <ChakraPopoverContent>
                  <ChakraPopoverBody
                    p={0}
                    onClick={(event) => event.stopPropagation()}
                  >
                    <Row
                      justify="space-between"
                      w="100%"
                      p={3}
                      borderBottom="1px"
                      borderColor="base.border"
                    >
                      <Text color="text.secondary" mr={6} fontWeight="medium">
                        Add row
                      </Text>
                      <Text>{commaNumber(added)}</Text>
                    </Row>
                    <Row
                      justify="space-between"
                      w="100%"
                      p={3}
                      borderBottom="1px"
                      borderColor="base.border"
                    >
                      <Text color="text.secondary" mr={6} fontWeight="medium">
                        Change row
                      </Text>
                      <Text>{commaNumber(changed)}</Text>
                    </Row>
                    <Row justify="space-between" w="100%" p={3}>
                      <Text color="text.secondary" mr={6} fontWeight="medium">
                        Remove row
                      </Text>
                      <Text>{commaNumber(removed)}</Text>
                    </Row>
                  </ChakraPopoverBody>
                </ChakraPopoverContent>
                <ChakraPopoverTrigger>
                  <Column onClick={(event) => event.stopPropagation()}>
                    <Row justify="flex-end" align="center">
                      <Text>{commaNumber(total)}</Text>
                      <Row fontSize="20px">
                        <ChevronDownIcon />
                      </Row>
                    </Row>
                  </Column>
                </ChakraPopoverTrigger>
              </ChakraPopover>
            </Column>
          );
        },
      },
      {
        header: () => (
          <Row align="center" justifyContent="center" w="100%" pos="relative">
            <Tooltip message="Breakdown of operations that were successful or rejected during each sync run">
              <Text size="sm" color="text.secondary">
                Results
              </Text>
            </Tooltip>
          </Row>
        ),
        cellSx: {
          justifyContent: "right",
        },
        cell: ({
          error,
          planner_type,
          query_run,
          sync_attempts,
          add_executed,
          change_executed,
          remove_executed,
          status_computed,
        }) => (
          <RunOperationsColumn
            showPendingRows={Boolean(showPendingRows)}
            queryRun={query_run}
            syncAttempts={sync_attempts}
            syncRequest={{
              status_computed,
              error,
              planner_type,
              add_executed,
              change_executed,
              remove_executed,
            }}
          />
        ),
      },
      {
        max: "100px",
        cell: ({ id }) => {
          return (
            <Column align="flex-end" flex={1}>
              <PermissionedButton
                permission={
                  appSyncRunSummaryPageEnabled || appShowDebugSuggestionOnly
                    ? undefined
                    : {
                        v2: {
                          resource: "sync",
                          grant: "can_debug",
                          id: sync.id,
                        },
                      }
                }
                size="sm"
                variant="secondary"
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();

                  const url = `${redirectPrefix}/syncs/${sync.id}/runs/${id}${
                    editingDraft ? "?editing=true" : ""
                  }`;

                  openUrl(url, navigate, event);
                }}
              >
                View run
              </PermissionedButton>
            </Column>
          );
        },
      },
    ],
    [orderBy],
  );

  return (
    <>
      <Column align="start">
        {runs?.[0]?.planner_type === "all" ||
        (runsFilter === SyncRunsFilter.ALL_RUNS && !runs?.length) ? null : (
          <Box mb={4}>
            <FilterMenu>
              <FilterMenuButton>Filter</FilterMenuButton>

              <FilterMenuList>
                <FilterMenuGroup
                  title="View"
                  type="radio"
                  value={runsFilter}
                  onChange={(val) => setRunsFilter(val as SyncRunsFilter)}
                >
                  <FilterMenuOption value={SyncRunsFilter.ALL_RUNS}>
                    Show all runs
                  </FilterMenuOption>
                  <FilterMenuOption value={SyncRunsFilter.NO_OPERATIONS}>
                    Hide runs with 0 operations
                  </FilterMenuOption>
                  <FilterMenuOption
                    value={SyncRunsFilter.NO_SUCCESSFUL_OPERATIONS}
                  >
                    Hide runs with 0 successful operations
                  </FilterMenuOption>
                </FilterMenuGroup>
              </FilterMenuList>
            </FilterMenu>
          </Box>
        )}

        <Table
          isPrivate
          columns={columns}
          data={runs}
          error={Boolean(error)}
          loading={isLoading || isPreviousData}
          placeholder={{
            title: "No sync runs",
            body: isJourneyTriggered
              ? "This sync will be triggered by your Journey"
              : "Set a schedule or manually run the sync.",
            error: "Runs failed to load, please try again.",
            button: !isJourneyTriggered ? (
              <PermissionedButton
                permission={{
                  v2: {
                    resource: "sync",
                    grant: "can_run",
                    id: sync?.id,
                  },
                }}
                onClick={onRun}
                variant="primary"
                icon={PlayIcon}
              >
                Run sync
              </PermissionedButton>
            ) : undefined,
          }}
        />
        <BasicPagination
          disableNextPage={data?.sync_requests?.length !== PAGE_SIZE + 1}
          page={page}
          setPage={setPage}
        />
      </Column>
      <SyncRequestErrorModal
        isOpen={Boolean(runError)}
        onClose={() => setError(undefined)}
        syncRequestError={runError}
        sync={sync}
        syncStatus={runError?.syncStatus}
      />
    </>
  );
};
