import * as yup from "yup";

export enum InsightsChartType {
  GlobalBreakdown = "global",
  CorrelationsBreakdown = "correlation",
}

export enum InsightsType {
  TopPerformers = "Top performers",
  PersonalizationInsights = "Personalization insights",
}

export enum BreakdownType {
  UserFeature = "user_feature",
  ActionFeature = "action_feature",
}

export type FilterColumnType =
  | "decision_engine_interaction_action_features"
  | "decision_engine_interaction_user_features";

/**
 * Since the charts are generated by the backend but the main request object is
 * stored as a JSON blob, we should validate the request schema here to ensure
 * the data is coming in as expected. This schema can also be used to catch any
 * missing links between the frontend and backend.
 */
export const chartSchema = yup.object().shape({
  id: yup.string().required(),
  outcome_id: yup.string().required(),
  chart_type: yup
    .string()
    .oneOf([
      InsightsChartType.GlobalBreakdown,
      InsightsChartType.CorrelationsBreakdown,
    ])
    .required(),
  breakdown_by_type: yup
    .string()
    .oneOf([BreakdownType.UserFeature, BreakdownType.ActionFeature])
    .required(),
  breakdown_by_dimension: yup.string().required(),

  // Filter is for specifying the subsegment for comparing against the baseline/total population
  filter_by: yup.string().when("chart_type", {
    is: InsightsChartType.CorrelationsBreakdown,
    then: yup.string().required(),
    otherwise: yup.string().nullable(),
  }),
  filter_value: yup.string().when("chart_type", {
    is: InsightsChartType.CorrelationsBreakdown,
    then: yup.string().required(),
    otherwise: yup.string().nullable(),
  }),

  rank: yup.number().required(),
  top_chart: yup.boolean().optional(),
  time_series_request: yup
    .object()
    .shape({
      cohortIds: yup.array().of(yup.string()).required(),
      frequency: yup.string().required(),
      groupByColumns: yup.array().of(yup.object()).required(),
      metricDefinitions: yup.array().of(yup.object()).required(),
      timeWindow: yup.object().required(),
    })
    .required(),
});
