import { Badge, BadgeProps } from "@hightouchio/ui";
import { SyncRunStatus, SyncStatusToText } from "src/utils/syncs";

/**
 * TODO: Soon, run status and sync health will be completely decoupled. When that happens,
 * we can convert this component to only take monitor statuses and cut a separate component
 * for showing run statuses with an indicator.
 * @deprecated Use SyncRunStatusIndicator instead.
 */
export const SyncRunStatusBadge = ({
  status: statusProp,
  request,
  size,
}: {
  status?: any;
  request?: any;
  size?: BadgeProps["size"];
}) => {
  const status = statusProp || request?.status_computed;

  if (!status) {
    return null;
  }

  switch (status) {
    case SyncRunStatus.WARNING:
      return (
        <Badge variant="warning" size={size}>
          {SyncStatusToText[status]}
        </Badge>
      );
    case SyncRunStatus.FAILED:
      return (
        <Badge variant="error" size={size}>
          {SyncStatusToText[status]}
        </Badge>
      );
    case SyncRunStatus.ACTIVE:
      return (
        <Badge variant="info" size={size}>
          {SyncStatusToText[SyncRunStatus.ACTIVE]}
        </Badge>
      );
    case SyncRunStatus.PROCESSING:
      return (
        <Badge variant="info" size={size}>
          {SyncStatusToText[SyncRunStatus.PROCESSING]}
        </Badge>
      );
    case SyncRunStatus.SUCCESS:
      return (
        <Badge variant="success" size={size}>
          {SyncStatusToText[status]}
        </Badge>
      );
    case SyncRunStatus.QUERYING:
      return (
        <Badge variant="info" size={size}>
          {SyncStatusToText[status]}
        </Badge>
      );
    case SyncRunStatus.REPORTING:
      if (request?.phase_to_status?.report?.error?.message) {
        return (
          <Badge variant="warning" size={size}>
            {SyncStatusToText[status]}
          </Badge>
        );
      }
      return (
        <Badge variant="info" size={size}>
          {SyncStatusToText[status]}
        </Badge>
      );
    case SyncRunStatus.PENDING:
    case SyncRunStatus.INTERRUPTED:
    case SyncRunStatus.ENQUEUED:
    case SyncRunStatus.CANCELLED:
    case SyncRunStatus.DISABLED:
      return <Badge size={size}>{SyncStatusToText[status]}</Badge>;

    default:
      return <Badge size={size}>Unknown</Badge>;
  }
};
