import { HTImage } from "src/components/image";
import { Column, SectionHeading, Text, useDisclosure } from "@hightouchio/ui";
import { ParentResourceTypes } from "@hightouch/lib/resource-monitoring/types";
import { isEqual } from "lodash";

import notificationsPlaceholder from "src/assets/placeholders/notifications.svg";
import {
  useCreateNotificationTemplateChannelsMutation,
  useCreateWorkspaceNotificationChannelMutation,
  useDeleteNotificationChannelTemplatesByDestinationMutation,
  useOverrideNotificationChannelTemplatesMutation,
  useOverrideNotificationChannelTemplatesWithNewChannelMutation,
  useWorkspaceNotificationChannelsQuery,
} from "src/graphql";
import { useRecipients } from "src/pages/alerting/utils";
import { Card } from "src/components/card";
import { AddRecipientButton } from "./add-recipient-button";
import { Channel } from "src/components/notification-channels/channel-definitions";
import { Recipients } from "./recipients";
import { ChannelData, CreateRecipientModal } from "./create-recipient-modal";

export const BulkParentRecipients = ({
  destinationIds,
}: {
  destinationIds: string[];
}) => {
  const createRecipientModal = useDisclosure();
  const { getRecipientsByParentResourceId, getRecipientsForParentResourceIds } =
    useRecipients();

  const { data: workspaceChannels } = useWorkspaceNotificationChannelsQuery(
    undefined,
    {
      suspense: true,
      select: (data) => data.workspace_notification_channels,
    },
  );

  const { mutateAsync: deleteParentRecipients } =
    useDeleteNotificationChannelTemplatesByDestinationMutation();

  const recipients = getRecipientsForParentResourceIds(destinationIds);
  const recipientsByDestination =
    getRecipientsByParentResourceId(destinationIds);

  // Conflict if either destinations have mismatched recipients or if a destination has none
  const isConflicting = Object.values(recipientsByDestination).some(
    (destinationRecipients: any) =>
      !isEqual(
        recipients.map((r) => r.channel.id),
        destinationRecipients.map((r) => r.channel.id),
      ),
  );

  const { mutateAsync: createNotificationTemplateChannels } =
    useCreateNotificationTemplateChannelsMutation();
  const { mutateAsync: overrideNotificationChannelTemplates } =
    useOverrideNotificationChannelTemplatesMutation();

  const { mutateAsync: overrideNotificationChannelTemplatesWithNewChannel } =
    useOverrideNotificationChannelTemplatesWithNewChannelMutation();

  const { mutateAsync: createWorkspaceNotificationChannel } =
    useCreateWorkspaceNotificationChannelMutation();

  const overrideRecipient = async (channel: Channel) => {
    await overrideNotificationChannelTemplates({
      resourceIds: destinationIds,
      objects: destinationIds.map((id) => ({
        channel_id: channel.id,
        parent_resource_id: id,
        parent_resource_type: ParentResourceTypes.Destination,
      })),
    });
  };

  const overrideRecipientWithNewChannel = async (channelData: ChannelData) => {
    await overrideNotificationChannelTemplatesWithNewChannel({
      resourceIds: destinationIds,
      object: {
        channel_type: channelData.channelType,
        config: channelData.config,
        notification_channel_templates: {
          data: destinationIds.map((id) => ({
            parent_resource_id: id,
            parent_resource_type: ParentResourceTypes.Destination,
          })),
        },
      },
    });
  };

  const addRecipient = async (channel: Channel) => {
    await createNotificationTemplateChannels({
      objects: destinationIds.map((id) => ({
        channel_id: channel.id,
        parent_resource_id: id,
        parent_resource_type: ParentResourceTypes.Destination,
      })),
    });
  };

  const onRemove = async (channel: Channel) => {
    await deleteParentRecipients({
      channelId: channel.id,
      destinationIds,
    });
  };

  const onCreate = async ({ config, channelType }: ChannelData) => {
    await createWorkspaceNotificationChannel({
      object: {
        channel_type: channelType,
        config,
        notification_channel_templates: {
          data: destinationIds.map((id) => ({
            parent_resource_id: id,
            parent_resource_type: ParentResourceTypes.Destination,
          })),
        },
      },
    });
  };

  return isConflicting ? (
    <Column gap={2}>
      <SectionHeading>Recipients</SectionHeading>
      <Card>
        <Column gap={4} align="center">
          <HTImage src={notificationsPlaceholder} boxSize={24} decorative />
          <Column align="center">
            <Text size="lg" color="text.secondary" fontWeight="medium">
              These destinations have conflicting recipients
            </Text>
            <Text color="text.secondary">
              Adding a recipient will override the existing recipients
            </Text>
          </Column>
          <AddRecipientButton
            onCreate={createRecipientModal.onOpen}
            onAdd={overrideRecipient}
            channels={workspaceChannels ?? []}
          />
        </Column>
      </Card>
      <CreateRecipientModal
        isOpen={createRecipientModal.isOpen}
        onClose={createRecipientModal.onClose}
        onCreate={overrideRecipientWithNewChannel}
      />
    </Column>
  ) : (
    <Recipients
      channels={recipients.map((r) => r.channel)}
      onAdd={addRecipient}
      onRemove={onRemove}
      onCreate={onCreate}
    />
  );
};
