import {
  AnalyticsMetricDatapoint,
  AnalyticsSummaryStats,
  isDecisionEngineAnalyticsMetricsDefinition,
  isDecisionEngineInteractionsMetricDefinition,
  isPredefinedAnalyticsMetricDefinition,
  isSyntheticColumn,
  NormalizationType,
  NormalizedToRateMetadata,
  SyntheticColumn,
} from "@hightouch/lib/query/visual/types";
import {
  AudienceAggregationType,
  isEventMetricConfig,
  isModelMetricConfig,
  PerUserAggregationType,
  PredefinedMetric,
  predefinedMetricNames,
} from "@hightouch/lib/query/visual/types/goals";
import { toZonedTime } from "date-fns-tz";
import { isEqualWith, uniqBy } from "lodash";
import set from "lodash/set";
import { format as formatNumber } from "numerable";
import { isPresent } from "ts-extras";

import {
  getModelIdFromColumn,
  getPropertyNameFromColumn,
} from "src/components/explore/visual/utils";
import {
  AnalyticsCohortDefinitionOutput,
  MetricIdentifier,
  MetricJobIdentifier,
} from "src/graphql";
import {
  EventOrMetricOption,
  GroupByColumn,
  ParentModel,
  SelectedAudience,
} from "src/pages/analytics/types";
import { AggregationOption } from "src/pages/metrics/constants";
import {
  mapAggregationConfigurationToConfigurationOption,
  mapNormalizationConfigurationToDescriptionLabel,
} from "src/pages/metrics/utils";
import {
  AnalyticsMetricDefinition,
  exhaustiveCheck,
  FilterableColumn,
  RawColumn,
  RelatedColumn,
  Relationship,
  SumMetricConfig,
} from "src/types/visual";
import { accurateCommaNumber } from "src/utils/numbers";

import { graphColors } from "src/pages/analytics/constants";
import { DECISION_ENGINE_INTERACTIONS_METRIC_LABEL } from "src/pages/analytics/decision-engine-utils";
import { MetricResultMaybeFromCache } from "src/pages/analytics/hooks/use-metric-series";
import {
  findAudienceFromCohort,
  isGroupByColumnRelatedToEvent,
  isGroupByColumnRelatedToParent,
  numberAndStringValidator,
} from "src/pages/analytics/utils";
import { GraphScale } from "./constants";
import { DataPoint, Graph, GroupColumn, SummaryData } from "./types";

export const transformMetricDataForGraph = ({
  audiences = [],
  cumulative = false,
  events = [],
  groupByColumns = [],
  metricResults = [],
  metrics = [],
  parent,
  transformForPerformance = false,
  availableMetricsOptions,
}: {
  audiences: SelectedAudience[];
  cumulative?: boolean;
  events: Relationship[];
  groupByColumns: GroupByColumn[];
  metricResults: MetricResultMaybeFromCache[];
  metrics: { id: string; name: string }[];
  parent: ParentModel | null;
  transformForPerformance?: boolean;
  availableMetricsOptions?: EventOrMetricOption[];
}) => {
  const graph: Graph = { series: [], summary: [] };

  if (!metricResults.length) return graph;

  const metricNamesById: Record<string, string> = {};
  if (availableMetricsOptions) {
    availableMetricsOptions.forEach((option) => {
      metricNamesById[option.id] = option.name;
    });
  } else {
    metrics.forEach((metric) => {
      metricNamesById[metric.id] = metric.name;
    });
    events.forEach((event) => {
      if (event.id !== null) {
        metricNamesById[event.id] = event.to_model.name ?? "";
      }
    });
  }

  // Use a different color for each series
  let colorIndex = 0;

  let dateRange: number[] = [];

  const ids = metricResults.map(({ ids }) => ids);

  const hasMultipleAudiences = audiences.length > 1;
  const hasMultipleCohorts = uniqueCohorts(ids).size > 1;

  metricResults.forEach(({ ids, result }, index) => {
    const audienceId = Number(
      ids.cohortDefinition?.parentModelId ?? ids.cohortId,
    );

    const foundAudience = findAudienceFromCohort(
      audiences,
      ids.cohortDefinition,
      audienceId,
    );

    const audienceName = foundAudience?.name ?? "";

    if ("data" in result) {
      const metricAndCohortCombinationKey = getMetricAndCohortCombinationKey(
        ids.cohortId,
        ids.metricId,
        ids.metricDefinition,
      );

      const metricName = getMetricName(ids, events, metricNamesById, {
        includeDescription: true,
      });
      const rawMetricName = getMetricName(ids, events, metricNamesById);

      const metricDefinition = ids.metricId
        ? metrics.find(({ id }) => id === ids.metricId)
        : ids.metricDefinition;

      if (!metricDefinition) {
        // No metric definition found, so don't add metric to graph.
        return;
      }

      const { data, summaryStats } = result;

      // We'll only show summary stats if one audience is selected.
      // At the moment, it's difficult to compare audiences because of the variation between
      // audiences in split names, split %, presence of splits, etc.
      // The UI needs to be tweaked if we want to do that in the future.
      if (!hasMultipleCohorts && summaryStats && summaryStats.data.length) {
        graph.summary.push({
          metricName,
          isSavedMetric: Boolean(ids.metricId),
          timeWindow: summaryStats.timeWindow,
          data: transformSummaryStatsData(summaryStats.data, audienceName),
        });
      }

      const eventsByModelId = {};
      for (const e of events) {
        eventsByModelId[e.to_model.id] = e;
      }

      data.forEach((metricSeries) => {
        const relevantGroupByColumns = findGroupByColumnsForMetric(
          groupByColumns,
          eventsByModelId[metricDefinition?.config?.eventModelId?.toString()],
          parent,
        );

        const grouping = relevantGroupByColumns
          .map((groupByColumn) => {
            const modelId = getModelIdFromColumn(groupByColumn);
            const isParentModelId = parent && parent.id.toString() === modelId;

            const model = isParentModelId
              ? parent
              : events.find(
                  ({ to_model }) => modelId === to_model.id.toString(),
                )?.to_model;

            const columnNameOrAlias = getColumnName(groupByColumn, model);

            const groupBy = metricSeries.groupBy?.find((gb) =>
              isEqualWith(groupByColumn, gb.column, numberAndStringValidator),
            );

            // Returned match
            if (groupBy) {
              return { ...groupBy, alias: columnNameOrAlias };
            }

            return {
              column: groupByColumn,
              alias: columnNameOrAlias,
              value: null,
            };
          })
          .filter(isPresent);

        const aggregationConfiguration = getAggregationConfiguration(
          metricDefinition as AnalyticsMetricDefinition,
        );

        const aggregation =
          mapAggregationConfigurationToConfigurationOption(
            aggregationConfiguration,
          ) ?? AggregationOption.Count;

        const clientSideCumulative =
          aggregationConfiguration.audienceAggregation !==
            AudienceAggregationType.Cumulative && cumulative;

        const normalization = metricDefinition?.config?.normalization;

        const seriesKey = getSeriesKey({
          metricAndCohortCombinationKey,
          audienceId,
          audienceName,
          metricName,
          splitId: metricSeries.splitId,
          groupByColumns: grouping,
          index,
        });

        const seriesDescription = getSeriesDescription({
          audienceName: hasMultipleAudiences ? audienceName : undefined,
          ...(transformForPerformance
            ? {
                metricName,
                splitName: metricSeries.splitId,
                groupByColumns: grouping,
              }
            : {}),
        });

        const legendName = getLegendName({
          audienceName,
          metricName,
          splitName: metricSeries.splitId,
          groupByColumns: grouping,
          hasMultipleAudiences,
        });

        const data: DataPoint[] = [];
        let currentSum = 0;

        metricSeries.data.forEach(({ timestamp, value, meta }) => {
          // We know the date values coming back from the backend are "in UTC"
          // (meaning they have no time zone, and represent the date we care
          // about in UTC). So, we convert them so they represent the same
          // timestamp, but in the browser's local time zone instead. That way,
          // when we call any date format methods, they'll show the date
          // correctly (since the browser renders dates in the local time zone).
          const calculatedAt = toZonedTime(timestamp, "UTC").getTime();
          dateRange.push(calculatedAt);

          const metadata = isNormalizedToRateMeta(meta) ? meta : undefined;
          const errorBounds = metadata
            ? {
                lowerBound: metadata.lowerConfidenceBound,
                upperBound: metadata.upperConfidenceBound,
              }
            : undefined;

          data.push({
            calculatedAt,
            metricValue: clientSideCumulative ? (currentSum += value) : value,
            seriesKey,

            // TODO(samuel): pass in custom cohort id in version 2.0
            audienceId: ids.cohortId?.toString() ?? "",
            aggregation,
            metricName,
            splitId: metricSeries.splitId,
            grouping,
            normalization,
            metadata,
            errorBounds,
          });
        });

        graph.series.push({
          key: seriesKey,
          metricId: ids.metricId ?? ids.metricDefinition?.id ?? "",
          metricName,
          rawMetricName,
          description: seriesDescription,
          legendName,
          audienceName,
          splitName: metricSeries.splitId ?? undefined,
          grouping,
          aggregation,
          color: graphColors[colorIndex++ % graphColors.length]!.color,
          normalization,
          data,
          metricFilterConditions:
            metricDefinition.config?.filter?.subconditions ?? [],
        });
      });
    }
  });

  dateRange = dateRange.filter((date, index, self) => {
    return self.findIndex((d) => d === date) === index;
  });
  dateRange.sort((dateA, dateB) => (dateA > dateB ? 1 : -1));

  // fill in missing values for performance graph
  if (transformForPerformance) {
    graph.series = graph.series.map((series) => {
      return {
        ...series,
        data: fillInMissingDateValues(series.key, series.data, dateRange),
      };
    });
  }

  return graph;
};

const isNormalizedToRateMeta = (
  meta: AnalyticsMetricDatapoint["meta"],
): meta is NormalizedToRateMetadata => {
  return (
    meta !== undefined &&
    "lowerConfidenceBound" in meta &&
    "upperConfidenceBound" in meta &&
    "numerator" in meta &&
    "denominator" in meta
  );
};

const getMetricAndCohortCombinationKey = (
  cohortId: string | null,
  metricId: string | null,
  metricDefinition: AnalyticsMetricDefinition,
) => {
  let metricAndCohortCombinationKey: string = cohortId?.toString() ?? "";
  // There are three kinds of metrics
  // 1) "saved" metrics -- in which case we have the ID
  // 2) "predefined" metrics -- in which case we use the predefinedMetric ID
  // 3) "live" metric -- an event model that we use to query, so we use the event model ID
  // They are all different types, so we have to build the unique key conditionally
  if (metricId) {
    metricAndCohortCombinationKey += metricId.toString();
  } else if (isPredefinedAnalyticsMetricDefinition(metricDefinition)) {
    metricAndCohortCombinationKey +=
      metricDefinition.predefinedMetric.toString();
  } else if (isDecisionEngineAnalyticsMetricsDefinition(metricDefinition)) {
    metricAndCohortCombinationKey += metricDefinition.flowId;
  } else if (
    metricDefinition?.config &&
    isEventMetricConfig(metricDefinition.config)
  ) {
    metricAndCohortCombinationKey +=
      metricDefinition.config.eventModelId.toString();
  } else if (
    metricDefinition?.config &&
    isModelMetricConfig(metricDefinition.config)
  ) {
    metricAndCohortCombinationKey += metricDefinition.config.modelId.toString();
  }

  return metricAndCohortCombinationKey;
};

export const valueFormatter = (
  value: number,
  format: GraphScale = GraphScale.Linear,
): string => {
  if (format === GraphScale.Linear) {
    // If value is less than 1 but not 0, show more decimal places
    return value < 1 && value > -1 && value !== 0
      ? formatNumber(value, "0.0000")
      : formatNumber(value, "0,0[.]0a");
  } else {
    return formatNumber(value, "0.##%");
  }
};

const getColumnName = (
  column: RawColumn | RelatedColumn | SyntheticColumn,
  model?: { filterable_audience_columns: FilterableColumn[] } | undefined,
) => {
  const columnName = getPropertyNameFromColumn(column) ?? "";

  if (!model) {
    return columnName;
  }

  const columnDefinition = model.filterable_audience_columns.find(
    ({ name }) => name === columnName,
  );

  return columnDefinition?.alias ?? columnDefinition?.name ?? columnName;
};

const getSeriesKey = ({
  audienceId,
  audienceName,
  groupByColumns = [],
  index,
  metricAndCohortCombinationKey,
  metricName,
  splitId,
}: {
  // We can have multiple cohort definitions with the same ID but different
  // filters (i.e. parent model vs ad hod audience)
  audienceId: number;
  audienceName: string;
  groupByColumns?: GroupColumn[] | null;
  index?: number;
  metricAndCohortCombinationKey: string;
  metricName: string;
  splitId?: string | null;
}): string => {
  return [
    index,
    metricAndCohortCombinationKey,
    audienceId,
    audienceName,
    metricName,
    splitId,
    ...(groupByColumns ?? []).map(
      ({ column, alias, value }) =>
        `${alias ?? getPropertyNameFromColumn(column)} ${value}`,
    ),
  ]
    .filter(isPresent)
    .join(":");
};

export const getSeriesDescription = ({
  audienceName,
  splitName,
  groupByColumns = [],
}: {
  audienceName?: string;
  splitName?: string | null;
  groupByColumns?: GroupColumn[] | null;
} = {}): string => {
  return [
    audienceName !== "" ? audienceName : undefined,
    splitName,
    ...(groupByColumns ?? []).map(
      ({ column, alias, value }) =>
        `${alias ?? getPropertyNameFromColumn(column)} = ${value}`,
    ),
  ]
    .filter(isPresent)
    .join(" / ");
};

/**
 * This function is used to get the legend name for the graph
 *
 * The first section (the metric name) is always  there, but the other sections
 *  only appear when needed to disambiguate series
 * -> If there's only 1 segment, don't include that
 * -> If there's only 1 split group, don't include that
 * -> if there's no normalization, no need to include that (should already be
 *  included in metric name)
 */
export const getLegendName = ({
  audienceName,
  metricName,
  splitName,
  groupByColumns = [],
  hasMultipleAudiences,
}: {
  audienceName?: string;
  metricName: string;
  splitName?: string | null;
  groupByColumns?: GroupColumn[];
  hasMultipleAudiences: boolean;
}) => {
  const description = getSeriesDescription({
    audienceName: hasMultipleAudiences ? audienceName : undefined,
    splitName,
    groupByColumns,
  });

  return [metricName, description].filter(Boolean).join(" / ");
};

export const getMetricName = (
  ids: Omit<MetricIdentifier, "__typename">,
  events: Relationship[],
  metricNamesById: Record<string, string>,
  options?: {
    includeDescription: boolean;
  },
): string => {
  // Saved metric references `metricId`
  if (ids.metricId) {
    return metricNamesById[ids.metricId] ?? "Metric";
  }

  // Live metric - predefined or ad-hoc
  const metricDefinition = ids.metricDefinition as AnalyticsMetricDefinition;

  // Predefined metric
  if (isPredefinedAnalyticsMetricDefinition(metricDefinition)) {
    return getPredefinedMetricName(
      metricDefinition.predefinedMetric as PredefinedMetric,
    );
  }

  const metricDefinitionName = isDecisionEngineInteractionsMetricDefinition(
    metricDefinition,
  )
    ? DECISION_ENGINE_INTERACTIONS_METRIC_LABEL
    : (metricNamesById[metricDefinition.id ?? ""] ??
      // Fallback to relationshipId if no id
      (metricDefinition.config.relationshipId &&
        metricNamesById[metricDefinition.config.relationshipId]));

  // Ad-hoc metric
  const metricName = metricDefinitionName ?? "Event";

  if (!options?.includeDescription) {
    return metricName;
  }

  const aggregation =
    mapAggregationConfigurationToConfigurationOption(metricDefinition);

  const normalization = isDecisionEngineAnalyticsMetricsDefinition(
    metricDefinition,
  )
    ? mapNormalizationConfigurationToDescriptionLabel(metricDefinition)
    : null;

  if (!aggregation && !normalization) {
    return metricName;
  }

  const config = metricDefinition.config as SumMetricConfig;
  const columns = events.find(
    ({ id }) => ids.metricDefinition?.config?.relationshipId === id.toString(),
  )?.to_model.filterable_audience_columns;
  const column = columns?.find(
    ({ name }) => name === getPropertyNameFromColumn(config.column),
  );

  const metricDescription = aggregation
    ? `[${getMetricDescription({
        aggregationMethod: aggregation,
        column,
      })}]`
    : "";

  const normalizationDescription = normalization ? `[${normalization}]` : "";

  return [metricName, metricDescription, normalizationDescription]
    .filter(Boolean)
    .join(" ");
};

export const getMetricDescription = ({
  aggregationMethod,
  column,
}: {
  aggregationMethod: AggregationOption;
  column: { alias: string | null; name: string | null } | undefined;
}) => {
  const aggregation = aggregationMethod;
  const columnName = column?.alias ?? column?.name;

  switch (aggregation) {
    case AggregationOption.Count:
      return "Total events";
    case AggregationOption.UniqueUsers:
      return "Unique users";
    case AggregationOption.PercentOfAudience:
      return "% of audience";
    case AggregationOption.CountDistinctProperty:
      return `Distinct count of ${columnName}`;
    case AggregationOption.AverageOfProperty:
      return `Average of ${columnName}`;
    case AggregationOption.AverageOfPropertyPerUser:
      return `Average of ${columnName} per user`;
    case AggregationOption.SumOfProperty:
      return `Sum of ${columnName}`;
    case AggregationOption.SumOfPropertyPerUser:
      return `Sum of ${columnName} per user - Averaged`;
    default:
      exhaustiveCheck(aggregation);
  }
};

const getPredefinedMetricName = (metricEnum: PredefinedMetric) => {
  return predefinedMetricNames[metricEnum];
};

const fillInMissingDateValues = (
  seriesKey: string,
  data: DataPoint[],
  dateArray: number[],
): DataPoint[] => {
  const result: DataPoint[] = [];

  const dataByDate = data.reduce((all, dataPoint) => {
    all[dataPoint.calculatedAt] = dataPoint;

    return all;
  }, {});

  dateArray.map((date) => {
    const entry = dataByDate[date];
    if (entry) {
      result.push(entry);
    } else {
      const { metricName, audienceId, splitId, grouping, aggregation } =
        entry ?? {};

      result.push({
        calculatedAt: date,
        metricValue: 0,
        seriesKey,

        aggregation,
        metricName,
        audienceId,
        splitId,
        grouping,
      });
    }
  });

  return result;
};

export const getTooltipSuffixTextFromMetric = (
  aggregation: AggregationOption,
  normalization: NormalizationType | undefined,
) => {
  if (normalization === NormalizationType.LiftPercent) {
    return "";
  }

  let aggregationSuffix = "";

  switch (aggregation) {
    case AggregationOption.Count:
      aggregationSuffix = "events";
      break;
    case AggregationOption.UniqueUsers:
      aggregationSuffix = "users";
      break;
    case AggregationOption.AverageOfPropertyPerUser:
    case AggregationOption.SumOfPropertyPerUser:
      aggregationSuffix = "per user";
      break;
  }

  let normalizationSuffix = "";
  switch (normalization) {
    case NormalizationType.RatePerInteraction:
      normalizationSuffix = "per interaction";
      break;
    case NormalizationType.RatePerUser: {
      if (aggregation !== AggregationOption.UniqueUsers) {
        normalizationSuffix = "per user";
      }
      break;
    }
  }

  return [aggregationSuffix, normalizationSuffix].filter(Boolean).join(" ");
};

export const getSummaryFromAggregation = (
  aggregation: AggregationOption,
  eventName: string,
  columnName?: string,
) => {
  switch (aggregation) {
    case AggregationOption.Count:
      return `Total number of "${eventName}" events performed`;
    case AggregationOption.CountDistinctProperty:
      return `Total number of distinct "${columnName}" across all events`;
    case AggregationOption.UniqueUsers:
      return `Total number of users who performed "${eventName}"`;
    case AggregationOption.PercentOfAudience:
      return `% of users who performed "${eventName}"`;
    case AggregationOption.SumOfProperty:
      return `SUM of "${columnName}" of "${eventName}" events`;
    case AggregationOption.SumOfPropertyPerUser:
      return `SUM of "${columnName}" per user of "${eventName}" events`;
    case AggregationOption.AverageOfProperty:
      return `Average of "${columnName}" across all "${eventName}" events`;
    case AggregationOption.AverageOfPropertyPerUser:
      return `Average of "${columnName}" per user of "${eventName}" events`;
    default:
      exhaustiveCheck(aggregation);
  }
};

export const formatMetricValue = (
  metricValue: number,
  isPercentage = false,
) => {
  return isPercentage
    ? `${(metricValue * 100).toFixed(2)}%`
    : accurateCommaNumber(
        metricValue < 1 ? metricValue.toFixed(4) : metricValue.toFixed(2),
      );
};

const getAggregationConfiguration = (
  metricDefinition: AnalyticsMetricDefinition,
): {
  aggregation: PerUserAggregationType;
  audienceAggregation: AudienceAggregationType;
} => {
  if (isPredefinedAnalyticsMetricDefinition(metricDefinition)) {
    const predefinedMetric =
      metricDefinition.predefinedMetric as PredefinedMetric;

    if (predefinedMetric === PredefinedMetric.AudienceSize) {
      return {
        aggregation: PerUserAggregationType.UniqueUsers,
        audienceAggregation: AudienceAggregationType.Sum,
      };
    }

    throw new Error(`Unrecognized predefined metric: ${predefinedMetric}`);
  } else {
    return {
      aggregation: metricDefinition.aggregation,
      audienceAggregation: metricDefinition.audienceAggregation,
    };
  }
};

const transformSummaryStatsData = (
  data: AnalyticsSummaryStats[],
  audienceName: string | undefined,
): SummaryData[] => {
  const hasSplits = data.length > 1;

  const baselineValue =
    (hasSplits ? data.find((s) => s.isBaseline)?.value : data[0]?.value) ?? 0;

  // Compute the percent difference for each split
  const transformedSummaryStats = data.map((summary) => ({
    ...summary,
    percentDifference: (summary.value - baselineValue) / baselineValue,
    audienceName: audienceName,
  }));

  // If there are splits, find the winning split group so we can highlight it
  if (hasSplits) {
    let largestIndex = -1;
    let largestValue = Number.NEGATIVE_INFINITY;
    for (let i = 0; i < data.length; i++) {
      const current = data[i];
      if (current == null) {
        // This shouldn't be null, but do this to appease typescript
        continue;
      }

      if (current.value > largestValue) {
        largestValue = current.value;
        largestIndex = i;
      }
    }
    set(transformedSummaryStats, [largestIndex, "isWinner"], true);
  }

  return transformedSummaryStats;
};

const uniqueCohorts = (
  metrics: (MetricIdentifier | MetricJobIdentifier)[],
): Set<string | AnalyticsCohortDefinitionOutput> => {
  const cohorts = new Set<string | AnalyticsCohortDefinitionOutput>();

  for (const { cohortId, cohortDefinition } of metrics) {
    // Each metric is assigned to a cohort consisting of either a cohortId or cohortDefinition
    // so we check for both

    if (cohortId && !cohorts.has(cohortId)) {
      cohorts.add(cohortId);
    }

    if (cohortDefinition && !cohorts.has(cohortDefinition)) {
      cohorts.add(cohortDefinition);
    }
  }

  return cohorts;
};

/**
 * Determine which groupByColumns belongs to the specific metric result's data
 * We need to do this because we may have multiple groupByColumns across different
 * event models since we allow groupBys of the same column names when viewing
 * multiple metrics in the charts. When mapping the data, we need to make sure
 * we map the groupByColumn that belongs to that specific metric so we don't show
 * duplicates. Example:
 * selectedMetrics: [Metric1, Metric2]
 * groupByColumns: [Metric1.source, Metric2.source]
 * -> want to map Metric1.source groupByColumn to Metric1 and etc...
 */
const findGroupByColumnsForMetric = (
  groupByColumns: GroupByColumn[],
  metricEvent: Relationship | undefined,
  parent: ParentModel | null,
): GroupByColumn[] => {
  // For metric definitions without an event model, return all parent groupByColumns
  // and unique event groupByColumns by name since we are only going to display
  // one column in the graph for an event column name
  const metricEventModelId = metricEvent?.to_model?.id;
  if (!metricEventModelId) {
    const parentGroupByColumns = groupByColumns.filter((gb) =>
      isGroupByColumnRelatedToParent(parent, gb),
    );

    const uniqueEventGroupByColumns = uniqBy(
      groupByColumns.filter((gb) => {
        return !isGroupByColumnRelatedToParent(parent, gb);
      }),
      (gb) => getPropertyNameFromColumn(gb),
    );

    return parentGroupByColumns.concat(uniqueEventGroupByColumns);
  }

  return groupByColumns.filter((gb) => {
    return Boolean(
      isGroupByColumnRelatedToParent(parent, gb) ||
        isGroupByColumnRelatedToEvent(metricEvent, gb) ||
        // Synthetic columns are not directly tied to a specific event model
        // so always show these
        isSyntheticColumn(gb),
    );
  });
};

export const getDataPointConfidenceBounds = (
  metadata: NormalizedToRateMetadata,
): [number, number] | null => {
  if (
    !metadata ||
    !metadata.lowerConfidenceBound ||
    !metadata.upperConfidenceBound
  ) {
    return null;
  }

  return [metadata.lowerConfidenceBound, metadata.upperConfidenceBound];
};
