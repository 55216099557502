import {
  ChannelConfig,
  ParentResourceTypes,
} from "@hightouch/lib/resource-monitoring/types";
import {
  Alert,
  Box,
  CodeSnippet,
  Column,
  Heading,
  Menu,
  MenuActionsButton,
  MenuList,
  Row,
  Text,
} from "@hightouchio/ui";
import { FC, useMemo } from "react";

import { useNavigate, useSearchParams } from "src/router";
import {
  useDeleteWorkspaceChannelMutation,
  useUpdateNotificationTemplatesForChannelMutation,
  useWorkspaceNotificationChannelLatestNotificationQuery,
  useWorkspaceNotificationChannelQuery,
} from "src/graphql";
import { Form, FormActions, useHightouchForm } from "src/components/form";
import { Resources } from "src/pages/alerting/recipients/components/resources";
import { useSubscribedResources } from "src/components/notification-channels/use-subscribed-resources";
import { ActionBar } from "src/components/action-bar";
import { DeleteButton } from "src/components/delete-button";
import {
  ChannelDefinition,
  channelName,
} from "src/components/notification-channels/channel-definitions";
import { TestConnection } from "./test-connection";
import { latestChannelNotificationError } from "src/pages/alerting/utils";

export interface ChannelFormData {
  channelType: string | null;
  channelId: string | null;
  config: ChannelConfig[keyof ChannelConfig] | null;
  resourceIdsToSubscribe: {
    // We treat syncs differently here as in the
    // subscription form table you can only set sync
    // subscriptions at the default destination level
    destinations: Record<
      string,
      { subscribed: boolean; currentChannelSubscriptionId?: string }
    >;
  };
}

export const Configuration: FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const channelId = searchParams.get("channel");
  const requestedResourceSubscriptionType = searchParams.get("resourceType");

  const { mutateAsync: update } =
    useUpdateNotificationTemplatesForChannelMutation();
  const deleteRecipient = useDeleteWorkspaceChannelMutation();

  const { availableResources, defaultSubscriptions } = useSubscribedResources(
    {},
  );

  const { data: channel } = useWorkspaceNotificationChannelQuery(
    {
      channelId: channelId || "",
    },
    {
      enabled: Boolean(channelId),
      suspense: true,
      select(data) {
        return data.workspace_notification_channels_by_pk;
      },
    },
  );
  const { data: channelError } =
    useWorkspaceNotificationChannelLatestNotificationQuery(
      {
        channelId: channelId || "",
      },
      {
        enabled: Boolean(channelId),
        suspense: true,
        refetchOnMount: true,
        select: (data) =>
          data.workspace_notification_channels_by_pk &&
          latestChannelNotificationError(
            data.workspace_notification_channels_by_pk,
          ),
      },
    );

  // List of destinations that are subscribed to the current channel
  const destinationsSubscribedToChannelId =
    defaultSubscriptions?.filter(
      (subscription) => subscription.channel.id === channelId,
    ) ?? [];

  const destinationToChannelMapping: Record<
    string,
    { subscribed: boolean; currentChannelSubscriptionId?: string }
  > = useMemo(() => {
    const mapping: Record<
      string,
      { subscribed: boolean; currentChannelSubscriptionId?: string }
    > = {};
    for (const destination of availableResources?.destinations ?? []) {
      // Get the subscriptions for this destination
      const destinationSubscription = destinationsSubscribedToChannelId.find(
        (subscription) =>
          subscription.parent_resource_id === destination.id.toString(),
      );
      const subscribed =
        Boolean(destinationSubscription) ||
        Boolean(
          requestedResourceSubscriptionType === ParentResourceTypes.Destination,
        );

      mapping[destination.id] = {
        subscribed,
        currentChannelSubscriptionId: destinationSubscription?.id,
      };
    }
    return mapping;
  }, [
    availableResources?.destinations,
    destinationsSubscribedToChannelId,
    requestedResourceSubscriptionType,
  ]);

  const channelType = channel?.channel_type;

  const form = useHightouchForm<ChannelFormData>({
    onSubmit: async ({ channelId, config, resourceIdsToSubscribe }) => {
      const templates = Object.entries(resourceIdsToSubscribe.destinations)
        ?.filter(([, d]) => d.subscribed)
        .map(([destinationId]) => ({
          channel_id: channelId!,
          parent_resource_id: destinationId.toString(),
          parent_resource_type: ParentResourceTypes.Destination,
        }));
      await update({
        channelId: channelId!,
        channelConfig: config,
        objects: templates,
        destinationIdsToKeep: templates.map((t) => t.parent_resource_id),
      });
    },
    values: {
      channelType: channel?.channel_type,
      channelId: channel?.id,
      config: channel?.config,
      resourceIdsToSubscribe: {
        destinations: destinationToChannelMapping,
      },
    },
  });

  if (!channelType || !channel) {
    return null;
  }

  const definition = ChannelDefinition[channelType];

  return (
    <>
      <Form form={form}>
        <Column overflowX="auto" flex={1}>
          <Column flex={1}>
            <Column p={6} flex={1} gap={6} overflow="auto">
              <Row justify="space-between" align="center">
                <Row align="center" gap={2}>
                  <Box as={definition.icon} boxSize={6} />
                  <Heading>{channelName(channel)}</Heading>
                </Row>
                <Menu>
                  <MenuActionsButton variant="secondary" />
                  <MenuList>
                    <DeleteButton
                      variant="menu"
                      label="recipient"
                      onDelete={() =>
                        deleteRecipient.mutateAsync({ channelId: channel.id })
                      }
                      onSuccess={() => {
                        navigate("/alerting/recipients");
                      }}
                    />
                  </MenuList>
                </Menu>
              </Row>
              {channelError && (
                <Alert
                  type="error"
                  message={
                    <>
                      The most recent attempt to send an alert to{" "}
                      <Text fontWeight="semibold">{channelName(channel)}</Text>{" "}
                      failed.
                      <CodeSnippet code={channelError} mt={2} />
                    </>
                  }
                  title="Connection Error"
                />
              )}
              <definition.component />
              <TestConnection />
              <Resources />
            </Column>
            <ActionBar fit>
              <FormActions />
            </ActionBar>
          </Column>
        </Column>
      </Form>
    </>
  );
};
