import pluralize from "pluralize";

import { accurateCommaNumber } from "src/utils/numbers";

export const buildTooltipText = ({
  count,
  percentage,
  modelName = "user",
}: {
  count: number;
  percentage?: number;
  modelName?: string;
}) => {
  if (percentage) {
    return `${accurateCommaNumber(count)} ${pluralize(modelName, count)} or ${(percentage * 100).toFixed(2)}%`;
  }
  return `${accurateCommaNumber(count)} ${pluralize(modelName, count)}`;
};
