import { isNil, mapValues } from "lodash";
import {
  useCreateNotificationTemplateChannelsMutation,
  useUpsertMonitorConditionTemplatesMutation,
} from "src/graphql";
import { CreateDestinationWizardFormData } from "./types";
import { MonitorDefinitions } from "src/components/resource-alert-triggers/definitions";
import {
  MonitorConditionEvaluationProperties,
  ParentResourceTypes,
} from "@hightouch/lib/resource-monitoring/types";

export const DEFAULT_MONITOR_CONDITIONS: CreateDestinationWizardFormData["alerts"]["conditions"] =
  mapValues(MonitorDefinitions, (definition, type) =>
    definition.canDisable
      ? null
      : {
          type,
          enabled: true,
          error_value: definition.defaultValue(),
          warning_value: null,
        },
  );

export const useCreateDestinationAlertsAndChannels = () => {
  const upsertMonitorConditions = useUpsertMonitorConditionTemplatesMutation();
  const createRecipients = useCreateNotificationTemplateChannelsMutation();

  const mutateAsync = async (
    destinationId: string,
    alerts: CreateDestinationWizardFormData["alerts"],
  ) => {
    const conditions = Object.values(alerts.conditions).filter(
      (x) => !isNil(x),
    );

    const upsertMonitorConditionsPromise = upsertMonitorConditions.mutateAsync({
      objects: conditions.map((condition) => ({
        evaluation_trigger:
          MonitorConditionEvaluationProperties[condition.type]
            .EvaluationTrigger,
        evaluation_type:
          MonitorConditionEvaluationProperties[condition.type].EvaluationType,
        type: condition.type,
        enabled: condition.enabled,
        error_value: condition.error_value,
        warning_value: condition.warning_value,
        parent_resource_id: String(destinationId),
        parent_resource_type: ParentResourceTypes.Destination,
      })),
    });

    const createRecipientsPromise = createRecipients.mutateAsync({
      objects: alerts.channels.map((channel) => ({
        channel_id: channel.id,
        parent_resource_id: String(destinationId),
        parent_resource_type: ParentResourceTypes.Destination,
      })),
    });

    await Promise.all([
      upsertMonitorConditionsPromise,
      createRecipientsPromise,
    ]);
  };

  return { mutateAsync };
};
