import { DecisionEngineChannelConfig } from "@hightouch/lib/customer-data/decision-engine/types";
import { DecisionEngineDestinationResource } from "src/graphql";

/*
  freeform: Completely up to the customer to create the resource in the destination, they can reference it by supplying an id.
  template: Customer selects a template from a list of existing templates and we create the resource based on that template.
  event-triggered: We create an event in the destination and then the customer creates a resource that is triggered by that event.
  resource: Customer selects a resource that we will trigger from a list of existing resources.
*/

export type CampaignSetupType =
  | "freeform"
  | "template"
  | "event-triggered"
  | "resource";

type ChannelDefinition = {
  campaignSetup: (
    channelConfig: DecisionEngineChannelConfig,
  ) => CampaignSetupType;
  getChannelConfig: (
    channelConfig: DecisionEngineChannelConfig,
  ) => DecisionEngineChannelConfig;
  getResourceUrl: (
    campaign: {
      id: string;
      name: string;
      status: string | null;
    },
    destinationConfig: Record<string, unknown>,
    channel: {
      type: string;
      config: DecisionEngineChannelConfig;
    },
  ) => string;
  isInactive: (resource: DecisionEngineDestinationResource) => boolean;
  isPreviewSupported: (channelConfig: DecisionEngineChannelConfig) => boolean;
  getReferenceString: (reference: string) => string;
  getResourceName: (channelConfig: DecisionEngineChannelConfig) => string;
  getChannelDescription: (channelConfig: DecisionEngineChannelConfig) => string;
  getDefaultVariables: (channel: {
    type: string;
    config: DecisionEngineChannelConfig;
  }) => string[];
  getTemplateResourceType: (channel: {
    type: string;
    config: DecisionEngineChannelConfig;
  }) => string;
  getResourceType: (channel: {
    type: string;
    config: DecisionEngineChannelConfig;
  }) => string | null;
};

export const CHANNEL_DEFINITION: {
  [key: string]: ChannelDefinition;
} = {
  iterable: {
    campaignSetup: () => "template",
    getChannelConfig: (config) => ({
      ...config,
      recipientType: config?.recipientType ?? "email",
    }),
    getResourceUrl: (campaign) =>
      `https://app.iterable.com/campaigns/${campaign.id}`,
    isInactive: (campaign) =>
      campaign.status === "Ready" || campaign.status === "Finished",
    isPreviewSupported: () => true,
    getReferenceString: (s) => `{{ ${s} }}`,
    getResourceName: () => "campaign",
    getChannelDescription: () => "Iterable",
    getTemplateResourceType: ({ type }) => `${type}-template`,
    getResourceType: () => "campaign",
    getDefaultVariables: () => [],
  },
  sfmc: {
    campaignSetup: (channelConfig) =>
      channelConfig.type === "dataExtensions" ? "freeform" : "template",
    getChannelConfig: (config) => ({
      ...config,
      type: config?.type ?? "trigger",
    }),
    getResourceUrl: (resource, _destinationConfig, channel) => {
      if (channel.config.type === "trigger") {
        if (channel.type === "email") {
          return "https://mc.exacttarget.com/cloud/#app/Email/C12/Default.aspx?entityType=none&entityID=0&ks=ks%23Interactions";
        } else {
          return `https://mc.exacttarget.com/cloud/#app/MobilePush/mobilepush/%23!/message/view/${resource.id}`;
        }
      } else {
        return "";
      }
    },
    isInactive: (resource) => resource.status !== "active",
    isPreviewSupported: (channelConfig) =>
      channelConfig.type !== "dataExtensions",
    getReferenceString: (s) => `%%=v(AttributeValue("${s}"))=%%`,
    getResourceName: (channelConfig) =>
      channelConfig.type === "dataExtensions" ? "automation" : "template",
    getChannelDescription: (channelConfig) =>
      channelConfig.type === "dataExtensions"
        ? "SFMC (Automation)"
        : "SFMC (Trigger)",
    getTemplateResourceType: ({ type, config }) =>
      config.type === "trigger" ? `${type}-template` : "",
    getResourceType: ({ type, config }) => {
      if (config.type === "trigger") {
        return type === "email" ? "triggered-send-definition" : "push-template";
      }
      return "";
    },
    getDefaultVariables: ({ type, config }) => {
      if (config.type === "trigger" && type === "push") {
        return ["title", "subtitle", "body"];
      }
      return [];
    },
  },
  braze: {
    campaignSetup: () => "resource",
    getChannelConfig: (config) => config,
    getResourceUrl: (
      resource,
      destinationConfig,
      { config: channelConfig },
    ) => {
      // https://www.braze.com/docs/api/basics/#endpoints
      const restRegionToDashboard = {
        "iad-01.braze.com": "dashboard-01.braze.com",
        "iad-02.braze.com": "dashboard-02.braze.com",
        "iad-03.braze.com": "dashboard-03.braze.com",
        "iad-04.braze.com": "dashboard-04.braze.com",
        "iad-05.braze.com": "dashboard-05.braze.com",
        "iad-06.braze.com": "dashboard-06.braze.com",
        "iad-07.braze.com": "dashboard-07.braze.com",
        "iad-08.braze.com": "dashboard-08.braze.com",
        "fra-01.braze.eu": "dashboard-01.braze.eu",
        "fra-02.braze.eu": "dashboard-02.braze.eu",
      };
      if (channelConfig.type === "canvas") {
        return `https://${
          restRegionToDashboard[destinationConfig.region as string]
        }/engagement/canvas?name="${encodeURIComponent(
          resource.name?.replace(" Canvas", ""),
        )}"`;
      }
      return `https://${
        restRegionToDashboard[destinationConfig.region as string]
      }/engagement/campaigns?start=0&limit=10&globalFilter="${encodeURIComponent(
        resource.name,
      )}"&columnFilters%5Bstatus%5D=all&sortby=last_edited&sortdir=-1`;
    },
    // Braze list API does not allow us to return the status of a campaign
    // We need to add a new resolver to get the status of a campaign
    isInactive: () => false,
    isPreviewSupported: (channelConfig) => channelConfig.type === "trigger",
    getReferenceString: (s) => {
      // Remove hightouch prefix
      const withoutPrefix = s.slice(10);
      return (
        "{{ api_trigger_properties.${hightouch}" + "." + withoutPrefix + " }}"
      );
    },
    getResourceName: (channelConfig) =>
      channelConfig.type === "canvas" ? "canvas" : "campaign",
    getChannelDescription: (channelConfig) =>
      channelConfig.type === "canvas" ? "Braze (Canvas)" : "Braze (Campaign)",
    getTemplateResourceType: ({ config }) =>
      config.type === "canvas" ? "canvas" : "campaign",
    getResourceType: ({ config }) =>
      config.type === "canvas" ? "canvas" : "campaign",
    getDefaultVariables: () => [],
  },
  klaviyo: {
    campaignSetup: () => "event-triggered",
    getChannelConfig: (config) => config,
    getResourceUrl: (flow) => `https://www.klaviyo.com/flow/${flow.id}/edit`,
    isInactive: (flow) => flow.status !== "live",
    isPreviewSupported: () => false,
    getReferenceString: (s) => `{{ event.${s} }}`,
    getResourceName: () => "flow",
    getChannelDescription: () => "Klaviyo",
    getTemplateResourceType: () => "",
    getResourceType: () => "flow",
    getDefaultVariables: () => [],
  },
  attentive: {
    campaignSetup: () => "event-triggered",
    getChannelConfig: (config) => config,
    getResourceUrl: (_journey) => ``,
    isInactive: (_journey) => true,
    isPreviewSupported: () => false,
    getReferenceString: (s) => `{{ triggerEvent.custom.${s} }}`,
    getResourceName: () => "journey",
    getChannelDescription: () => "Attentive",
    getTemplateResourceType: () => "",
    getResourceType: () => null,
    getDefaultVariables: () => [],
  },
};

export const getChannelDefinition = (
  destinationType: string,
): ChannelDefinition => {
  const definition = CHANNEL_DEFINITION[destinationType];

  if (definition) {
    return definition;
  }

  return {
    campaignSetup: () => "freeform",
    getChannelConfig: (config) => config,
    getResourceUrl: () => "",
    isInactive: () => false,
    isPreviewSupported: () => false,
    getReferenceString: (s) => `{{ ${s} }}`,
    getResourceName: () => "resource",
    getChannelDescription: () => "",
    getTemplateResourceType: () => "",
    getResourceType: () => "",
    getDefaultVariables: () => [],
  };
};
