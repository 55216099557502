import { Box, Combobox, ComboboxProps } from "@hightouchio/ui";

export const JourneyTileCombobox = <
  Option,
  OptionValue extends Option[keyof Option],
>(
  props: ComboboxProps<Option, OptionValue>,
) => {
  return (
    <Box
      sx={{
        button: {
          bg: "base.background",
          "&[aria-expanded=true]": {
            bg: "base.background",
          },
          _disabled: {
            bg: "base.background",
            span: {
              color: "text.tertiary",
            },
            svg: {
              display: "none",
            },
          },
        },
      }}
    >
      <Combobox {...props} />
    </Box>
  );
};
