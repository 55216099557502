import { isEmpty, sortBy, uniq } from "lodash";
import {
  ArrowUpIcon,
  Badge,
  Box,
  BoxProps,
  Column,
  ProgressCircle,
  Row,
  SuccessIcon,
  Text,
  Tooltip,
} from "@hightouchio/ui";

import {
  NormalizationType,
  SplitGroupId,
} from "@hightouch/lib/query/visual/types/analytics";
import { ENGAGEMENT_OUTCOME_EXPERIMENT_FILTER } from "@hightouch/lib/query/visual/analytics/decision-engine-utils";
import { outcomeConversionRateCacheKey } from "@hightouch/lib/query/visual/analytics/custom-cache-keys";

import {
  TimeOptions,
  MeasurementScope,
  MeasuringMode,
} from "src/pages/analytics/types";
import { Card } from "src/components/card";
import { Table } from "src/ui/table";
import { AnalyticsFrequency, SyntheticMetricType } from "src/types/visual";
import { useMetricSeries } from "src/pages/analytics/hooks/use-metric-series";
import { AggregationOption } from "src/pages/metrics/constants";
import { TextWithTooltip } from "src/components/text-with-tooltip";
import { sortOutcomes } from "src/pages/decision-engines/utils";
import analyticsPlaceholder from "src/assets/placeholders/analytics.svg";
import {
  metricDataToOutcomeConversionRates,
  OutcomeConversionRates,
  SPLIT_GROUP_LABEL,
  SPLIT_GROUP_SORT_ORDER,
} from "src/pages/decision-engines/flows/flow/utils";
import { OutcomeClassification } from "src/pages/decision-engines/flows/flow/outcomes/components/outcome-classification";
import { getDateRangeForMetricSeries } from "src/pages/decision-engines/flows/utils";

import { Flow } from "..";
import { OutcomeConversionChart } from "./outcome-conversion-chart";

type PerformanceTableProps = {
  flow: Flow;
  lastFlowRunStartedAt: string;
  flowStartDate: string;
};

export const OutcomeOverviewTable = ({
  flow,
  lastFlowRunStartedAt,
  flowStartDate,
}: PerformanceTableProps) => {
  const { outcomes: wrappedOutcomes, messages: flowMessages, audience } = flow;
  const parentModelId = audience?.parent?.id;
  const outcomes = wrappedOutcomes.map(({ outcome }) => outcome);

  const outcomesWithRelationshipId = outcomes
    .map((outcome) => {
      const relationshipId = audience?.parent?.relationships.find(
        ({ to_model }) =>
          to_model?.event && to_model?.id === outcome.segment.id,
      )?.id;

      if (!relationshipId) return null;

      return {
        outcome,
        relationshipId: String(relationshipId),
      };
    })
    .filter((o) => o !== null);

  const { data, isPolling, pollingError, errors } = useMetricSeries({
    enabled: true,
    measuringSelection: {
      scope: MeasurementScope.DecisionEngineFlow,
      id: flow.id,
    },
    parentModelId,
    measuringMode: MeasuringMode.Incrementality,
    frequency: AnalyticsFrequency.All,
    cumulative: true,
    metricSelection: outcomesWithRelationshipId.map(
      ({ outcome, relationshipId }) => ({
        id: outcome.id,
        resourceId: outcome.id,
        eventModelId: outcome.segment.id,
        relationshipId,
        name: "AI Decisioning Interactions",
        source: SyntheticMetricType.DecisionEngineInteractions,
        aggregationMethod: AggregationOption.UniqueUsers,
        normalization: NormalizationType.LiftPercent,
        conditions: outcome.attribution?.campaign_id_column
          ? ENGAGEMENT_OUTCOME_EXPERIMENT_FILTER
          : [],
        description: null,
        attributionWindow: undefined,
        cacheKey: outcomeConversionRateCacheKey({
          workspaceId: flow.workspace_id,
          flowId: flow.id,
          outcomeId: outcome.id,
        }),
      }),
    ),
    timeValue: TimeOptions.Custom,
    customDateRange: getDateRangeForMetricSeries(lastFlowRunStartedAt, {
      type: "custom",
      startDate: flowStartDate,
    }),
    measuringMetricResources: { outcomes, flowMessages },
    audiences: [],
    groupByColumns: [],
    metrics: [],
    useSampledModels: false,
    errorOnNoData: false,
  });

  const outcomeConversionRates = metricDataToOutcomeConversionRates(
    data,
    outcomesWithRelationshipId,
  ).sort((a, b) => sortOutcomes(a.outcome.weight, b.outcome.weight));

  const splitGroups = sortBy(
    uniq(
      outcomeConversionRates.flatMap(({ conversionRates }) =>
        conversionRates.map(({ splitGroup }) => splitGroup),
      ),
    ),
    (s) => SPLIT_GROUP_SORT_ORDER.indexOf(s as SplitGroupId),
  );

  const errorMessage =
    pollingError || !isEmpty(errors) ? "Query failed" : undefined;

  return (
    <Card heading="Experiment overview" minHeight="xs">
      <Text size="sm" color="text.secondary" mb={2}>
        Cumulative conversion rate by outcome
      </Text>
      <Table
        loading={isPolling}
        error={Boolean(pollingError || !isEmpty(errors))}
        data={outcomeConversionRates}
        placeholder={{
          title: "No experiments have been run yet",
          body: "First create an outcome and enable this agent, then check back here to see the results.",
          image: analyticsPlaceholder,
          error: errorMessage,
        }}
        renderExpandedRow={({ outcome, relationshipId }) => (
          <Column flex={1}>
            <OutcomeConversionChart
              flow={flow}
              outcome={outcome}
              relationshipId={relationshipId}
              lastFlowRunStartedAt={lastFlowRunStartedAt}
              allTimeStartDate={flowStartDate}
            />
          </Column>
        )}
        columns={[
          {
            name: "Outcome",
            min: "30%",
            cell: ({ outcome }) => (
              <Row align="center" gap={2} overflow="hidden">
                <OutcomeClassification weight={outcome.weight} />
                <TextWithTooltip size="md">{outcome.name}</TextWithTooltip>
              </Row>
            ),
          },
          {
            header: () => (
              <Row flex={1} overflow="hidden" gap={2} align="center">
                <TextWithTooltip color="text.secondary">
                  Winning group
                </TextWithTooltip>
                <Row flexShrink={0}>
                  <Badge size="sm" variant="upsell">
                    Beta
                  </Badge>
                </Row>
              </Row>
            ),
            breakpoint: "sm",
            min: "25%",
            cell: ({ bestPerformingGroup }) => {
              if (bestPerformingGroup.type === "no-data") {
                return <EmptyItem />;
              }

              if (bestPerformingGroup.type === "not-statsig") {
                return (
                  <Tooltip message="No statistically significant difference between groups">
                    <Text color="text.secondary" isTruncated>
                      No clear winner
                    </Text>
                  </Tooltip>
                );
              }

              const { splitGroup, percentConfidence, isHighConfidence } =
                bestPerformingGroup;
              const tooltipMessage = `Confidence that ${SPLIT_GROUP_LABEL[splitGroup] ?? splitGroup} is winning against all other groups.`;

              return (
                <Column overflow="hidden">
                  <TextWithTooltip>
                    {SPLIT_GROUP_LABEL[splitGroup] ?? splitGroup}
                  </TextWithTooltip>
                  <Tooltip message={tooltipMessage}>
                    <Row gap={1} align="center">
                      {isHighConfidence ? (
                        <Icon icon={SuccessIcon} color="success.base" />
                      ) : (
                        <Row boxSize={4} align="center" justify="center">
                          <ProgressCircle
                            value={percentConfidence * 100}
                            size="xs"
                            color="text.secondary"
                          />
                        </Row>
                      )}
                      <Text
                        color={
                          isHighConfidence ? "success.base" : "text.secondary"
                        }
                        size="sm"
                        isTruncated
                      >
                        {`${(percentConfidence * 100).toFixed(0)}% confidence`}
                      </Text>
                    </Row>
                  </Tooltip>
                </Column>
              );
            },
          },
          ...splitGroups.map((splitGroup) => ({
            // Kind of hacky, but helps keep the groups from taking up too much space when > 2 groups
            max:
              splitGroups.length > 2
                ? `${45 / splitGroups.length}%`
                : undefined,
            header: () => (
              <Row justify="flex-end" flex={1} overflow="hidden">
                <TextWithTooltip color="text.secondary">
                  {SPLIT_GROUP_LABEL[splitGroup] ?? splitGroup}
                </TextWithTooltip>
              </Row>
            ),
            headerSx: { pr: "4 !important" },
            cell: ({ outcome, conversionRates }: OutcomeConversionRates) => {
              const res = conversionRates.find(
                (r) => r.splitGroup === splitGroup,
              );

              // Sometimes expected, it means this group is not in the result set for this outcome
              const noDataExpected =
                Boolean(outcome.attribution?.campaign_id_column) &&
                ["holdout", "customer_managed"].includes(splitGroup);

              if (!res) {
                const splitGroupLabel =
                  SPLIT_GROUP_LABEL[splitGroup] ?? splitGroup;
                const tooltipMessage = noDataExpected
                  ? `${outcome.name} data is not tracked for the ${splitGroupLabel.toLowerCase()} group`
                  : "";

                return (
                  <Column align="flex-end" w="100%">
                    <EmptyItem tooltipMessage={tooltipMessage} />
                  </Column>
                );
              }

              const formattedRate = `${(res.rate * 100).toPrecision(3)}%`;

              return (
                <Column gap={1} align="flex-end" w="100%">
                  {formattedRate}
                  {res.isBaseline ? (
                    <Text color="text.secondary" size="sm">
                      Baseline
                    </Text>
                  ) : (
                    res.liftFromBaseline !== null && (
                      <LiftFromBaseline
                        liftFromBaseline={res.liftFromBaseline}
                        weightType={outcome.weight.type}
                      />
                    )
                  )}
                </Column>
              );
            },
          })),
        ]}
      />
    </Card>
  );
};

const EmptyItem = ({ tooltipMessage }: { tooltipMessage?: string }) => (
  <Tooltip message={tooltipMessage} isDisabled={!tooltipMessage}>
    <Text color="text.secondary" size="sm">
      --
    </Text>
  </Tooltip>
);

const Icon = ({
  icon,
  ...props
}: Omit<BoxProps, "as"> & {
  icon: BoxProps["as"];
}) => <Box as={icon} boxSize={4} flexShrink={0} {...props} />;

const LiftFromBaseline = ({
  liftFromBaseline,
  weightType,
}: {
  liftFromBaseline: number;
  weightType: "positive" | "negative";
}) => {
  const isPositiveLift =
    (weightType === "positive" && liftFromBaseline > 0) ||
    (weightType === "negative" && liftFromBaseline < 0);

  const color =
    liftFromBaseline === 0
      ? "text.secondary"
      : isPositiveLift
        ? "success.base"
        : "danger.base";

  return (
    <Row gap={1} align="center" justify="end">
      {liftFromBaseline !== 0 && (
        <Icon
          icon={ArrowUpIcon}
          color={color}
          transform={liftFromBaseline > 0 ? "" : "rotate(180deg)"}
        />
      )}
      <Text color={color} size="sm">
        {`${(Math.abs(liftFromBaseline) * 100).toFixed(1)}%`}
      </Text>
    </Row>
  );
};
