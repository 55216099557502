import {
  Column,
  Menu,
  MenuIconButton,
  MenuList,
  Row,
  SettingsIcon,
  Switch,
  Text,
} from "@hightouchio/ui";
import { useMemo, useState } from "react";

import {
  getGroupingColumnPath,
  metricNameKey,
  MoveableBreakdownColumn,
} from "src/components/analytics/breakdowns/utils";
import { Card } from "src/components/card";
import {
  MinimalDecisionEngineFlowMessagesQuery,
  QueryEvaluateTimeSeriesMetricArgs,
  useDecisionEngineFlowChartsQuery,
} from "src/graphql";
import { flowSyntheticColumnValuesGetter } from "src/pages/analytics/decision-engine-utils";
import {
  GroupByColumn,
  SyntheticColumnValuesGetter,
} from "src/pages/analytics/types";
import { TAG_FEATURE_PREFIX } from "src/types/visual";
import { Pagination, useTableConfig } from "src/ui/table";
import { BreakdownChart } from "./components/breakdown-chart";
import { ChartTitle } from "./components/chart-title";
import { EmptyChartsPlaceholder } from "./components/empty-charts-placeholder";
import { GeneratedChartsBlurb } from "./components/generated-charts-blurb";
import { BreakdownType, InsightsChartType } from "./types";
import { useBreakdownMetricSeries } from "./use-breakdown-metric-series";
import {
  CHARTS_PER_PAGE,
  getFilterColumnType,
  getFilterValueLabel,
  MAX_CHARTS,
  transformGraphForCorrelationsBreakdown,
  validateCharts,
} from "./utils";

type CorrelationsBreakdownSettings = {
  showErrorBars: boolean;
  showStatSigAnomalies: boolean;
};

export const CorrelationsBreakdowns = ({
  agentId,
  outcomeId,
  flowMessages,
}: {
  agentId: string;
  outcomeId: string;
  flowMessages: MinimalDecisionEngineFlowMessagesQuery["decision_engine_flow_messages"];
}) => {
  const [settings, setSettings] = useState<CorrelationsBreakdownSettings>({
    showErrorBars: false,
    showStatSigAnomalies: false,
  });
  const { limit, offset, page, setPage } = useTableConfig({
    limit: CHARTS_PER_PAGE,
  });

  const { data: chartsData } = useDecisionEngineFlowChartsQuery(
    {
      flowId: agentId,
      filters: {
        _and: [
          { outcome_id: { _eq: outcomeId } },
          { chart_type: { _eq: InsightsChartType.CorrelationsBreakdown } },
        ],
      },
      orderBy: { top_chart: "desc_nulls_last", rank: "desc" },
      limit: MAX_CHARTS,
    },
    {
      select: (data) => ({
        charts: data.decision_engine_flow_charts,
      }),
      suspense: true,
    },
  );

  const validCharts = useMemo(() => {
    // @jenn-chan TODO: remove filter once we resolve it in the generated charts
    const charts = (chartsData?.charts ?? []).filter(
      (chart) =>
        !chart.filter_by?.startsWith(TAG_FEATURE_PREFIX) &&
        !chart.breakdown_by_dimension?.startsWith(TAG_FEATURE_PREFIX),
    );
    return validateCharts(charts);
  }, [chartsData?.charts]);

  const paginatedCharts = validCharts.slice(offset, offset + limit);

  // Getter to return human friendly values, if any, given a column
  // (e.g. for message column, getter returns list of message IDs values -> message name)
  const columnValuesGetter = flowSyntheticColumnValuesGetter(flowMessages);

  return (
    <Column gap={6} p={6} flex={1}>
      <Row justify="space-between">
        <Column>
          <Text size="lg" fontWeight="medium">
            Personalization insights
          </Text>
          <Text size="sm" color="text.secondary">
            Best performing creative for specific user segments
          </Text>
        </Column>
        <Row gap={4}>
          <CorrelationsBreakdownSettingsMenu
            settings={settings}
            onChange={setSettings}
          />
        </Row>
      </Row>
      {validCharts.length === 0 ? (
        <EmptyChartsPlaceholder />
      ) : (
        <>
          <GeneratedChartsBlurb />
          {paginatedCharts.map((chart) => (
            <CorrelationsBreakdownChart
              key={chart.id}
              filterBy={chart.filter_by!}
              filterValue={chart.filter_value!}
              breakdownByDimension={chart.breakdown_by_dimension!}
              timeSeriesRequest={chart.time_series_request}
              columnValuesGetter={columnValuesGetter}
              breakdownType={chart.breakdown_by_type as BreakdownType}
              chartSettings={settings}
            />
          ))}
          <Pagination
            page={page}
            setPage={setPage}
            count={validCharts.length}
            rowsPerPage={limit}
            label="charts"
          />
        </>
      )}
    </Column>
  );
};

// Correlations breakdowns have 2 series for each group so we want 10 rows since each
// row is a series
const CHART_ROWS_LIMIT = 10;

const CorrelationsBreakdownChart = ({
  breakdownByDimension,
  breakdownType,
  timeSeriesRequest,
  filterBy,
  filterValue,
  columnValuesGetter,
  chartSettings,
}: {
  breakdownByDimension: string;
  breakdownType: BreakdownType;
  timeSeriesRequest: QueryEvaluateTimeSeriesMetricArgs;
  columnValuesGetter: SyntheticColumnValuesGetter;
  filterBy: string;
  filterValue: string;
  chartSettings: CorrelationsBreakdownSettings;
}) => {
  const groupByColumns: GroupByColumn[] = (
    timeSeriesRequest.groupByColumns ?? []
  ).map((gb) => gb.column);

  const breakdownTableColumnsOrder: MoveableBreakdownColumn[] = [
    ...groupByColumns.map((_, index) => getGroupingColumnPath(index)),
    metricNameKey,
  ];

  const { graph, isLoading, errorMessage } = useBreakdownMetricSeries({
    timeSeriesRequest,
    columnValuesGetter,
    groupByColumns,
  });

  const filterColumnType = getFilterColumnType(breakdownType);

  const filterValueLabel = getFilterValueLabel({
    filterBy,
    filterValue,
    filterColumnType,
    valuesGetter: columnValuesGetter,
  });

  const correlationsGraphSeries = useMemo(() => {
    return transformGraphForCorrelationsBreakdown({
      series: graph.series,
      filterProps: {
        filterBy,
        filterValue,
        filterValueLabel,
        filterColumnType,
      },
    });
  }, [graph.series, filterBy, filterValue, filterValueLabel, filterColumnType]);

  return (
    <Card p={6} gap={6}>
      <ChartTitle
        breakdownByDimension={breakdownByDimension}
        filterProps={{
          filterBy,
          filterValue: filterValueLabel ?? filterValue,
          filterColumnType,
        }}
      />
      <BreakdownChart
        isLoading={isLoading}
        isError={!!errorMessage}
        data={correlationsGraphSeries}
        groupByColumns={groupByColumns}
        rowsLimit={CHART_ROWS_LIMIT}
        columnsOrder={breakdownTableColumnsOrder}
        showErrorBars={chartSettings.showErrorBars}
      />
    </Card>
  );
};

const CorrelationsBreakdownSettingsMenu = ({
  settings,
  onChange,
}: {
  settings: CorrelationsBreakdownSettings;
  onChange: (settings: CorrelationsBreakdownSettings) => void;
}) => {
  return (
    <Menu>
      <MenuIconButton
        icon={SettingsIcon}
        aria-label="Chart settings"
        variant="tertiary"
      />
      <MenuList>
        <Column gap={4} p={4}>
          <Text
            size="sm"
            color="text.secondary"
            fontWeight="semibold"
            textTransform="uppercase"
          >
            Options
          </Text>
          <Row gap={2}>
            <Switch
              aria-label="Show error bars"
              isChecked={settings.showErrorBars}
              onChange={() =>
                onChange({
                  ...settings,
                  showErrorBars: !settings.showErrorBars,
                })
              }
            />
            <Text>Show error bars</Text>
          </Row>
          {/* <Row gap={2} align="center">
            <Switch
              aria-label="Show statistical significance anomalies"
              isChecked={settings.showStatSigAnomalies}
              onChange={() =>
                onChange({
                  ...settings,
                  showStatSigAnomalies: !settings.showStatSigAnomalies,
                })
              }
            />
            <Text>Only show stat sig anomalies</Text>
          </Row> */}
        </Column>
      </MenuList>
    </Menu>
  );
};
