import { Edge, Edge as ReactFlowEdge } from "reactflow";

import {
  EdgeInput,
  JourneyEdges,
  JourneyGraphInput,
  JourneyQuery,
  NodeInput,
} from "src/graphql";
import {
  ConfiguredSyncConfigDetails,
  isPlaceholderSyncConfig,
  JourneyGraph,
  JourneyNode,
  PlaceholderSyncConfigDetails,
} from "src/pages/journeys/types";
import { JourneyNodeConfig, JourneyNodeSyncMode } from "src/types/journeys";

export const transformNodeToReactFlowNode = (
  node: NonNullable<JourneyQuery["journeys"][number]["nodes"][number]>,
): JourneyNode => {
  return {
    id: node.id,
    type: (node.config as JourneyNodeConfig).type,
    position: { x: 0, y: 0 },
    data: {
      ...node,
      sync_configs: node.sync_configs?.map((syncConfig) => {
        if (isPlaceholderSyncConfig(syncConfig)) {
          return {
            ...syncConfig,
            // This is a NUMBER but the type says string
            placeholder_destination_id: Number(
              syncConfig.placeholder_destination_id,
            ),
          } satisfies PlaceholderSyncConfigDetails;
        }

        return {
          ...syncConfig,
          mode: syncConfig.mode as JourneyNodeSyncMode,
          // This is a NUMBER but the type says string
          destination_instance_id: Number(syncConfig.destination_instance_id),
          exit_config: {
            // UI needs these values to be `null`. `undefined` does not work with radio buttons.
            remove_after: syncConfig.exit_config.remove_after ?? null,
            // UI needs these values to be `null`. `undefined` does not work with radio buttons.
            remove_on_journey_exit:
              syncConfig.exit_config.remove_on_journey_exit ?? null,
          },
          // This is a NUMBER but the type says string
          placeholder_destination_id: syncConfig.placeholder_destination_id
            ? Number(syncConfig.placeholder_destination_id)
            : null,
        } satisfies ConfiguredSyncConfigDetails;
      }),
    },
  };
};

export const transformEdgeToReactFlowEdge = (
  edge: Pick<JourneyEdges, "from_node_id" | "to_node_id">,
): ReactFlowEdge => {
  return {
    id: `${edge.from_node_id}-${edge.to_node_id}`,
    source: edge.from_node_id,
    target: edge.to_node_id,
    sourceHandle: null,
    targetHandle: null,
  };
};

const transformJourneyNodesToNodeInput = (node: JourneyNode): NodeInput => {
  return {
    id: node.data.id,
    name: node.data.name,
    // Make sure to cast as strings here.
    segment_id: node.data.segment_id?.toString() ?? null,
    // Make sure to cast as strings here.
    event_relationship_id: node.data.event_relationship_id?.toString() ?? null,
    type: node.data.config.type,
    sync_configs: node.data.sync_configs?.map((syncConfig) => {
      if (isPlaceholderSyncConfig(syncConfig)) {
        return {
          ...syncConfig,
          placeholder_destination_id:
            syncConfig.placeholder_destination_id.toString(),
        };
      }

      return {
        ...syncConfig,
        destination_instance_id: syncConfig.destination_instance_id.toString(),
        exit_config: {
          // Backend does not allow `null` values.
          remove_after: syncConfig.exit_config.remove_after ?? undefined,
          // Backend does not allow `null` values.
          remove_on_journey_exit:
            syncConfig.exit_config.remove_on_journey_exit ?? undefined,
        },
        placeholder_destination_id:
          syncConfig.placeholder_destination_id?.toString(),
      };
    }),
    config: node.data.config,
  };
};

const transformJourneyEdgesToEdgeInput = (node: Edge): EdgeInput => {
  return {
    from_node_id: node.source,
    to_node_id: node.target,
  };
};

export const transformJourneyGraphToPayload = (
  data: JourneyGraph,
): JourneyGraphInput => {
  return {
    journey: {
      id: data.journey.id,
      name: data.journey.name,
      archived: false, // archival was removed, so always set this to false for now.
      // TODO(samuel): Graphql type is incorrect. null should be allowed but default to in string for now
      description: data.journey.description ?? "",
      status: data.journey.status,
      schedule: data.journey.schedule,
      exit_criteria:
        // IMPORANT: { type: ConditionType.And/Or, conditions: [] } exits _everybody_. Value must be `null` to clear them out.
        data.journey.exitCriteria.conditions.length === 0
          ? null
          : data.journey.exitCriteria,
    },
    nodes: data.nodes.map(transformJourneyNodesToNodeInput),
    edges: data.edges.map(transformJourneyEdgesToEdgeInput),
  };
};
