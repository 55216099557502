import {
  getModelIdFromColumn,
  getPropertyNameFromColumn,
} from "src/components/explore/visual/utils";
import { GroupByColumn, ParentModel } from "src/pages/analytics/types";
import { isGroupByColumnRelatedToParent } from "src/pages/analytics/utils";
import { ColumnReference } from "src/types/visual";
import { GraphSeries } from "../cross-audience-graph/types";

export const metricNameKey = "metricName";
export const audienceNameKey = "audienceName";
export const splitNameKey = "splitName";
export const valueKey = "value";

export const STATIC_COLUMN_NAMES = [
  metricNameKey,
  audienceNameKey,
  splitNameKey,
  valueKey,
] as const;

export type GroupByColumnPath = `grouping.${number}.value`;

export type BreakdownColumn =
  | (typeof STATIC_COLUMN_NAMES)[number]
  | GroupByColumnPath;

export type MoveableBreakdownColumn = Omit<BreakdownColumn, typeof valueKey>;

export const getTdStyles = ({
  isLastColumn,
  isFirstOfGroup,
  isLastOfGroup,
}: {
  isLastColumn: boolean;
  isFirstOfGroup: boolean;
  isLastOfGroup: boolean;
}) => {
  if (isLastColumn) {
    return { display: "flex", alignItems: "center", px: 4 };
  }

  return {
    bg: "base.background",
    px: 4,
    mx: 1,
    ":first-of-type": {
      ml: 0,
    },
    display: "flex",
    alignItems: "center",
    minHeight: "36px",

    span: {
      visibility: "hidden",
    },
    ...(isFirstOfGroup && {
      borderTopRadius: "sm",
      mt: 1,
      span: { visibility: "visible" },
    }),
    ...(isLastOfGroup && { borderBottomRadius: "sm" }),
  };
};

export const move = (
  array: MoveableBreakdownColumn[],
  columnName: MoveableBreakdownColumn,
  direction: "left" | "right",
): MoveableBreakdownColumn[] => {
  const index = array.findIndex((name) => name === columnName);
  const newArray = [...array];
  const item = newArray.splice(index, 1)[0];

  if (item) {
    if (direction === "left") {
      newArray.splice(index - 1, 0, item);
    } else if (direction === "right") {
      newArray.splice(index + 1, 0, item);
    }
    return newArray;
  }

  return array;
};

// Include model id to avoid name collisions between parent and event groupBy columns
export const getGroupingKey = (
  column: ColumnReference,
  parent: ParentModel | null,
) => {
  const columnModelId = getModelIdFromColumn(column);
  const columnName = getPropertyNameFromColumn(column) ?? "--";

  return isGroupByColumnRelatedToParent(parent, column as GroupByColumn)
    ? `${columnModelId}-${columnName}`
    : columnName;
};

/**
 * The max breakdown value will serve as a reference for the error bar's length
 * We want to account for the upperBound of the error bar metadata when showing error bars
 */
export const getMaxBreakdownValue = (
  data: GraphSeries[],
  showErrorBars: boolean,
) => {
  let maxValue = 0;

  data.forEach(({ data }) => {
    // If showing error bars, use the upper confidence bound as reference
    const value = showErrorBars
      ? (data?.[0]?.errorBounds?.upperBound ?? 0)
      : (data?.[0]?.metricValue ?? 0);

    maxValue = Math.max(value, maxValue);
  });

  return maxValue;
};

export const getMaxDecimalPlaces = (data: GraphSeries[]) => {
  let maxDecimalPlaces = 0;

  data.forEach(({ data }) => {
    const value = data?.[0]?.metricValue ?? 0;
    if (!Number.isFinite(value) || Number.isInteger(value)) {
      return;
    }

    const valueString = value.toString();
    const decimalIndex = valueString.indexOf(".");
    maxDecimalPlaces = Math.max(
      decimalIndex === -1 ? 0 : valueString.length - decimalIndex - 1,
      maxDecimalPlaces,
    );
  });

  return maxDecimalPlaces;
};

// Value column is always appended to the end of the array in the table so
// we don't need to include it in the columnsOrder array
export const getColumnKeys = (
  groupByColumnPaths: GroupByColumnPath[],
  hasSplits: boolean,
  numberOfUniqueAudiences: number,
  columnsOrder?: MoveableBreakdownColumn[],
): MoveableBreakdownColumn[] => {
  if (columnsOrder?.length) {
    return columnsOrder;
  }

  const keys: MoveableBreakdownColumn[] = [];

  keys.push(metricNameKey);

  if (numberOfUniqueAudiences > 1) {
    keys.push(audienceNameKey);
  }

  if (hasSplits) {
    keys.push(splitNameKey);
  }

  if (groupByColumnPaths.length > 0) {
    keys.push(...groupByColumnPaths);
  }

  return keys;
};

export const getGroupingColumnPath = (index: number): GroupByColumnPath => {
  return `grouping.${index}.value`;
};
