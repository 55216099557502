import { FC, useMemo } from "react";

import {
  Alert,
  Box,
  CloseIcon,
  Column,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  Heading,
  IconButton,
  InformationIcon,
  Row,
  Spinner,
  Text,
} from "@hightouchio/ui";
import { LinkButton } from "src/router";
import { yupResolver } from "@hookform/resolvers/yup";
import { Navigate, useNavigate, useSearchParams } from "src/router";

import { Form, SaveButton, useHightouchForm } from "src/components/form";
import { useGraphContext } from "src/pages/journeys/graph/use-graph-context";
import {
  isConfiguredSyncConfig,
  JourneyGraph,
  NodeDetailFormProps,
} from "src/pages/journeys/types";
import { JourneySyncConfigForm } from "src/pages/syncs/create/journey-sync-config-form";
import { SyncConfig } from "src/types/journeys";

import { SyncFormSchema } from "./validation-schemas";
import { useFormContext } from "react-hook-form";
import { hasWaitNodeDownstream } from "./utils";
import { JourneySyncQuery, useJourneySyncQuery } from "src/graphql";
import { useValidatedSyncs } from "src/pages/journeys/utils/use-validated-syncs";

export const SyncConfigForm: FC<NodeDetailFormProps<SyncConfig>> = ({
  id, // id techincally lives in data too, redundant to have both :/
  data,
  onClose,
}) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { isEditMode, nodes, updateJourneyPermission, onUpdateSyncConfig } =
    useGraphContext();

  const syncIdString = searchParams.get("sync");
  const syncId = syncIdString ? Number(syncIdString) : null;

  const syncConfig = data.sync_configs
    ?.filter(isConfiguredSyncConfig)
    .find((config) => config.destination_instance_id === syncId);

  // If `is_clone` is present on the sync_config of the node, it's a clone.
  const isEphemeralClonedNode = (data.sync_configs ?? []).some(
    (config) => config?.is_clone,
  );

  const parentForm = useFormContext<JourneyGraph>();
  const currentNode = nodes.find((node) => node.id === id);
  const edges = parentForm.watch("edges");

  const hasDownstream = useMemo(() => {
    return currentNode && hasWaitNodeDownstream(currentNode, nodes, edges);
  }, [nodes, edges]);

  const journeySyncQuery = useJourneySyncQuery(
    {
      id: syncIdString ?? "",
    },
    {
      enabled: Boolean(syncIdString),
      select: (data) => data.syncs?.[0],
    },
  );

  const destination = journeySyncQuery.data?.destination;
  const destinationName = destination?.name;

  const { syncs: validatedSyncs, loading: syncConfigValidationLoading } =
    useValidatedSyncs<JourneySyncQuery["syncs"][0]>(
      journeySyncQuery.data ? [journeySyncQuery.data] : [],
    );
  const showMisconfiguredWarning =
    validatedSyncs?.length && validatedSyncs[0]?.isSyncMisconfigured;

  const form = useHightouchForm({
    onSubmit: (journeySyncConfig) => {
      if (syncId) {
        onUpdateSyncConfig(id, syncId, journeySyncConfig);
        navigate(".");
      }

      return Promise.resolve();
    },
    success: "Tile was saved",
    values: syncConfig,
    resolver: yupResolver(SyncFormSchema),
  });

  if (!syncConfig) {
    return <Navigate to="." />;
  }

  return (
    <Form form={form}>
      <DrawerHeader>
        <Row align="center" justify="space-between" flex={1} minWidth={0}>
          <Heading size="lg">{data.name}</Heading>
          <IconButton
            aria-label="Close drawer."
            icon={CloseIcon}
            onClick={onClose}
          />
        </Row>
      </DrawerHeader>

      <DrawerBody>
        <Column minHeight={0} flex={1} gap={6} pb={4}>
          <Row align="center" gap={2}>
            <Box
              as={LinkButton}
              href={data.id}
              px={2}
              mx={-2}
              fontWeight="normal"
              variant="tertiary"
            >
              <Text color="text.secondary">Sync</Text>
            </Box>
            <Text color="text.secondary" size="lg">
              /
            </Text>
            <Text color="text.secondary" fontWeight="medium">
              {destinationName ?? "Destination"}
            </Text>
          </Row>
          {journeySyncQuery.isLoading || syncConfigValidationLoading ? (
            <Row align="center" gap={2}>
              <Spinner size="md" />
            </Row>
          ) : (
            <>
              {isEphemeralClonedNode && (
                <Row
                  width="100%"
                  bg="base.background"
                  px={2}
                  py={4}
                  borderRadius="md"
                >
                  <Box
                    as={InformationIcon}
                    fontSize="22px"
                    mx={2}
                    color="text.secondary"
                  />
                  <Text color="text.secondary" fontWeight="semibold">
                    This sync will not be created until the journey is saved.
                  </Text>
                </Row>
              )}
              {showMisconfiguredWarning &&
                isConfiguredSyncConfig(syncConfig) && (
                  <Alert
                    type="error"
                    variant="inline"
                    title="Sync is not fully configured. Please go to the sync details page to finish setting up this sync."
                    message={null}
                    actions={
                      <LinkButton
                        target="_blank"
                        rel="noreferrer noopener"
                        href={`/syncs/${syncConfig.destination_instance_id}`}
                      >
                        Go to sync details
                      </LinkButton>
                    }
                  />
                )}
              <Row as={Text} gap={1} fontWeight="medium" size="lg">
                Settings for sync to
                <Row gap={1}>
                  {destination?.definition.icon && (
                    <img
                      src={destination?.definition.icon}
                      alt={destination?.definition.name}
                      height="24px"
                      width="24px"
                    />
                  )}
                  {destinationName}
                </Row>
              </Row>

              <JourneySyncConfigForm
                isDisabled={!isEditMode}
                isJourneyExitWarningEligible={!hasDownstream}
              />

              {!isEphemeralClonedNode && isConfiguredSyncConfig(syncConfig) && (
                <Alert
                  type="subtle"
                  variant="inline"
                  title="Further sync configuration is managed on the sync details page"
                  message={null}
                  actions={
                    <LinkButton
                      target="_blank"
                      rel="noreferrer noopener"
                      href={`/syncs/${syncConfig.destination_instance_id}`}
                    >
                      Go to sync details
                    </LinkButton>
                  }
                />
              )}
            </>
          )}
        </Column>
      </DrawerBody>

      {isEditMode && (
        <DrawerFooter>
          <SaveButton permission={updateJourneyPermission}>Update</SaveButton>
        </DrawerFooter>
      )}
    </Form>
  );
};
